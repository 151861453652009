<script lang="ts">
    import { getWeek as apiGetWeek } from "../../api/Weeks";

    import { authenticatedUser, selectedModule, selectedMenuTab, selectedWeek, selectedYear } from "../../stores/AppStatus";

    import { roles, modules } from "../../stores/AppConfig";
    import { weeks, years } from "../../stores/Weeks";

    import Tab, { Label, Icon } from "@smui/tab";
    import TabBar from "@smui/tab-bar";
    import MenuSurface from "@smui/menu-surface";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import Dashboard from "./Dashboard.svelte";
    import QuoteSheetDetail from "./QuoteSheetDetail.svelte";
    import DeliverySchedule from "./DeliverySchedule.svelte";
    import BuyingScreen from "./BuyingScreen.svelte";
    import PurchaseScreen from "./PurchaseScreen.svelte";
    import ForecastDetail from "./ForecastDetail.svelte";
    import Amendments from "./Amendments.svelte";
    import Reports from "./Reports.svelte";
    import Management from "./Management.svelte";
    import UnknownModule from "./UnknownModule.svelte";

    let activeTab = null;
    let tabs = ["dashboard", "quote_sheets", "forecasts", "buying", "delivery_schedule", "amendments", "supp_amendments", "reports", "manage"];
    let userTabs = [];
    let userTabsEmelements = {};
    let unknownRole = false;
    let showUploadDialog = false;
    let autoLoadData = false;
    let refreshDashboard = false;
    let productData = {};
    let manageMenu: MenuSurface;
    let amendmentsMenu: MenuSurface;
    let reportsMenu: MenuSurface;

    let pageEmpty = false;

    // Edit/View Forecast button in FC detail
    let selectedDC = [];

    let amendmentsPositionLeft = 0;
    let amSize = 10;

    let reportsPositionLeft = 0;
    let mngSize = 15;

    let managementModules = [
        {
            id: "products",
            label: $_("products"),
            icon: "inventory",
        },
        {
            id: "suppliers",
            label: $_("suppliers"),
            icon: "local_shipping",
        },
        /*         {
            id: "delegateProduct",
            label: $_('delegate_product'),
            icon: "category"
        },
        {
            id: "delegateUser",
            label: $_('delegate_user'),
            icon: "folder_shared"
        }, */
        {
            id: "dcl",
            label: $_("dc_list"),
            icon: "warehouse",
        },
        {
            id: "exchRate",
            label: $_("exchange_rates"),
            icon: "currency_exchange",
        },
        {
            id: "lov",
            label: $_("list_of_values"),
            icon: "list",
        },
    ];

    // Filter managementModules modules conditionally
    const filteredManagementModules = $authenticatedUser.isBuyer ? managementModules.filter((module) => module.id !== "lov" && module.id !== "dcl") : managementModules;

    let amendmentsModules = [
        {
            id: "country",
            label: $_("country"),
            icon: "public",
        },
        {
            id: "suppliers",
            label: $_("supplier"),
            icon: "local_shipping",
        },
    ];

    // Filter managementModules modules conditionally
    const filteredAMModules = $authenticatedUser.isSc ? amendmentsModules.filter((module) => module.id !== "suppliers") : amendmentsModules;

    let reports = [
        /*     {
            id: "purchase_vs_demand",
            label: $_('purchase_vs_demand')
        },
        {
            id: "what_was_not_bought",
            label: $_('what_was_not_bought')
        },
    */ {
            id: "price_comparison",
            label: $_("price_comparison"),
        },
        /*    {
            id: "moving_volumes",
            label: $_('moving_volumes')
        },
    */ {
            id: "logistic_change",
            label: $_("logistic_change"),
        },
    ];

    $: if (authenticatedUser && userTabs.length == 0) {
        // Get list of user Tabs
        if ($roles.filter((x) => x.id == $authenticatedUser.role).length > 0) {
            let tmp = [];
            $roles
                .filter((x) => x.id == $authenticatedUser.role)
                .at(0)
                .modules.forEach((m) => {
                    let module = $modules.filter((x) => x.id == m).at(0);
                    if (module) {
                        module.tabs.forEach((tab) => tmp.push(tab));
                    }
                });

            // Remove duplicities
            tmp = [...new Set(tmp)];

            userTabs = [...tabs.filter((t) => tmp.includes(t))];

            if (!activeTab) {
                activeTab = $selectedMenuTab && userTabs.includes($selectedMenuTab) ? $selectedMenuTab : userTabs.at(0);
            }
            console.log("update");
        } else {
            unknownRole = true;
        }
    }

    // allow change active tab from outside
    $: activeTab = $selectedMenuTab ? $selectedMenuTab : activeTab;

    $: {
        console.log({ activeTab });
        console.log({ $selectedMenuTab });
        console.log({ userTabsEmelements });
    }

    const handleEvents = (event) => {
        console.log("================= handleEvents ==============");
        console.log({ event });

        switch (event.type) {
            case "editQSWeek":
                console.log("Edit QuoteSheets");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                selectedDC = [];
                showUploadDialog = false;
                autoLoadData = true;
                activeTab = "quote_sheets";
                setActiveTab();
                $selectedModule = "qts";
                break;
            case "uploadQSData":
                console.log("Upload QuoteSheets");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                showUploadDialog = true;
                autoLoadData = false;
                activeTab = "quote_sheets";
                setActiveTab();
                $selectedModule = "qts";
                break;
            case "editASWeek":
                console.log("Edit Supplier Amendments");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                selectedDC = [];
                showUploadDialog = false;
                autoLoadData = true;
                activeTab = "supp_amendments";
                setActiveTab();
                $selectedModule = "suppliers";
                break;
            case "uploadASData":
                console.log("Upload Supplier Amendments");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                showUploadDialog = true;
                autoLoadData = false;
                activeTab = "supp_amendments";
                setActiveTab();
                $selectedModule = "suppliers";
                break;
            case "editFCWeek":
                console.log("Edit Forecasts Week");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                selectedDC = [];
                showUploadDialog = false;
                autoLoadData = true;
                activeTab = "forecasts";
                setActiveTab();
                $selectedModule = "fc";
                break;
            case "uploadFCData":
                console.log("Upload DC Forecasts");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                showUploadDialog = true;
                autoLoadData = false;
                activeTab = "forecasts";
                setActiveTab();
                $selectedModule = "fc";
                break;
            case "buyingFCWeek":
                console.log("Buying FC Week");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                }
                selectedDC = [];
                showUploadDialog = false;
                autoLoadData = true;
                activeTab = "buying";
                setActiveTab();
                $selectedModule = "bs";
                break;
            case "editForecastsDC":
                console.log("Edit/View DC Forecasts");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                    selectedDC = [{ id: event.detail.dcId, label: event.detail.dcName }];
                }
                console.log({ selectedDC });
                showUploadDialog = false;
                autoLoadData = true;
                activeTab = "forecasts";
                setActiveTab();
                $selectedModule = "fc";
                break;
            case "buyingDC":
                console.log("BUY From DC");
                if (event.detail) {
                    $selectedWeek = $weeks.filter((w) => w.id == event.detail.id).at(0);
                    $selectedYear = $years.filter((w) => w.year == event.detail.year).at(0);
                    selectedDC = [{ id: event.detail.dcId, label: event.detail.dcName }];
                }
                console.log({ selectedDC });
                showUploadDialog = false;
                autoLoadData = true;
                activeTab = "buying";
                setActiveTab();
                $selectedModule = "";
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    const setActiveTab = () => {
        if ($selectedMenuTab != activeTab) {
            $selectedModule = "";
        }
        if ($selectedModule == "ps") {
            $selectedModule = "bs";
        }
        $selectedMenuTab = activeTab;
    };

    const checkPurchaseTab = () => {
        if ($selectedModule == "ps") {
            $selectedModule = "bs";
        }
        $selectedMenuTab = activeTab;
    };

    const tabClicked = () => {
        checkPurchaseTab();
        selectedDC = [];
        if (activeTab == "manage") {
            manageMenu.setOpen(true);
        } else if (activeTab == "amendments") {
            if (userTabsEmelements?.amendments)
                amendmentsPositionLeft = userTabsEmelements?.amendments.getElement().offsetLeft + userTabsEmelements?.amendments.getElement().offsetWidth;
            console.log({ amendmentsPositionLeft });
            amendmentsMenu.setOpen(true);
        } else if (activeTab == "reports") {
            if (userTabsEmelements?.reports) reportsPositionLeft = userTabsEmelements?.reports.getElement().offsetLeft + userTabsEmelements?.reports.getElement().offsetWidth;
            console.log({ reportsPositionLeft });
            reportsMenu.setOpen(true);
        } else if (activeTab == "forecasts") {
            selectedDC = [];
        } else if (activeTab == "dashboard") {
            refreshCurrentWeekStatus();
            refreshDashboard = true;
            if (pageEmpty) {
                pageEmpty = false;
            }
        }
    };

    const chooseManagementModule = (module) => {
        $selectedModule = "mng_" + module;
        if (activeTab == "manage") {
            manageMenu.setOpen(false);
        }
    };

    const chooseAmendmentsModule = (module) => {
        console.log("================= chooseAmendmentsModule ==============");
        $selectedModule = module;
        console.log({ $authenticatedUser });
        console.log({ $selectedModule });
        if (activeTab == "amendments") {
            //           if ($authenticatedUser.isSupplier){
            //               chooseAmendmentsModule('suppliers');
            //           } else {
            amendmentsMenu.setOpen(false);
            //            }
        }
    };

    const chooseReport = (report) => {
        $selectedModule = report;
        if (activeTab == "reports") {
            reportsMenu.setOpen(false);
        }
    };

    const getModuleIcon = (tab) => {
        let module = $modules.filter((module) => module.tabs.includes(tab)).at(0);
        if (module.icon) {
            return module.icon;
        }
        return "";
    };

    const getModuleLabelType = (tab) => {
        return $modules.filter((module) => module.tabs.includes(tab)).at(0).labelType;
    };

    async function refreshCurrentWeekStatus() {
        try {
            const ret = await apiGetWeek($selectedWeek.id);
            console.log({ ret });

            if (ret && ret.length > 0) {
                let updated = false;
                $weeks.forEach((w) => {
                    if (w.id == ret.at(0).id && w.status != ret.at(0).status) {
                        w.status = ret.at(0).status;
                        updated = true;
                    }
                });

                if (updated) {
                    console.log("Week status changed!", ret.at(0).status);
                    $selectedWeek = $weeks.filter((x) => x.id == ret.at(0).id).at(0);
                    $weeks = $weeks;
                }
                console.log("…done");
            }
        } catch (e) {
            console.log("Error: ", { e });
        }
    }
</script>

{#if userTabs && userTabs.length > 0}
    <section id="tab-bar">
        <TabBar tabs={userTabs} let:tab bind:active={activeTab} class="main-menu-tab-bar white tescoblue-text" on:SMUITabBar:activated={setActiveTab} on:click={tabClicked}>
            <!-- Note: the `tab` property is required! -->
            <Tab data-qa={"tab-" + tab} {tab} minWidth class="white-text {tab == 'manage' ? 'management-tab' : ''}" bind:this={userTabsEmelements[tab]}>
                <Label>
                    <Flex direction="row" justify="center" align="center" class="gap-05">
                        {#if getModuleIcon(tab)}
                            <Icon class="material-icons {activeTab == tab ? 'tescoblue-text' : 'blue-text text-darken-2'}  pa-0">
                                {getModuleIcon(tab)}
                            </Icon>
                        {/if}
                        {#if getModuleLabelType(tab) != "icon_only"}
                            {$_(tab)}
                            {#if tab == "amendments" && ["country", "suppliers"].includes($selectedModule)}
                                - {$_($selectedModule)}
                            {/if}
                            {#if tab == "reports" && reports.map((x) => x.id).includes($selectedModule)}
                                - {$_($selectedModule)}
                            {/if}
                        {/if}
                        {#if ["amendments", "reports", "manage"].includes(tab)}
                            <Icon class="material-icons {activeTab == tab ? 'tescoblue-text' : 'blue-text text-darken-2 '}  pa-0">arrow_drop_down</Icon>
                        {/if}
                    </Flex>
                </Label>
            </Tab>
        </TabBar>
    </section>

    <section id="tab-content">
        {#if activeTab === "dashboard"}
            <Dashboard
                {pageEmpty}
                bind:refreshFlag={refreshDashboard}
                on:editQSWeek={handleEvents}
                on:uploadQSData={handleEvents}
                on:editASWeek={handleEvents}
                on:uploadASData={handleEvents}
                on:editFCWeek={handleEvents}
                on:uploadFCData={handleEvents}
                on:buyingFCWeek={handleEvents}
                on:editForecastsDC={handleEvents}
                on:buyingDC={handleEvents}
            />
        {:else if activeTab === "quote_sheets"}
            <QuoteSheetDetail bind:showUploadDialog {autoLoadData} on:getDataRequest={refreshCurrentWeekStatus} />
        {:else if activeTab === "forecasts"}
            <ForecastDetail bind:showUploadDialog {autoLoadData} {selectedDC} on:getDataRequest={refreshCurrentWeekStatus} />
        {:else if activeTab === "buying"}
            {#if $selectedModule == "ps"}
                <PurchaseScreen selectedProduct={productData} on:getDataRequest={refreshCurrentWeekStatus} />
            {:else}
                <BuyingScreen {selectedDC} {autoLoadData} on:purchaseItem={handleEvents} on:getDataRequest={refreshCurrentWeekStatus} />
            {/if}
        {:else if activeTab === "delivery_schedule"}
            <DeliverySchedule on:getDataRequest={refreshCurrentWeekStatus} />
        {:else if activeTab === "amendments"}
            <Amendments module={$selectedModule} on:getDataRequest={refreshCurrentWeekStatus} />
        {:else if activeTab === "supp_amendments"}
            <Amendments module={"suppliers"} {autoLoadData} on:getDataRequest={refreshCurrentWeekStatus} />
        {:else if activeTab === "reports"}
            <Reports />
        {:else if activeTab === "manage"}
            <Management />
        {/if}

        <MenuSurface
            bind:this={amendmentsMenu}
            class="tescoblue-border-thin amendments-menu-surface mt-1"
            style="min-width: {amSize}rem; left: calc( {amendmentsPositionLeft}px  -  {amSize}rem) !important"
        >
            <Flex direction="column" align="start" class="gap-05 pa-2 w-100 bs-bb">
                {#each filteredAMModules as module (module.id)}
                    <div
                        data-qa={"dropdown-am-" + module.id}
                        on:click={() => chooseAmendmentsModule(module.id)}
                        on:keydown={() => chooseAmendmentsModule(module.id)}
                        class="clickable menu-item"
                        role="button"
                        tabindex="0"
                    >
                        {#if module.icon}
                            <Icon class="material-icons">{module.icon}</Icon>
                        {/if}
                        <div class="pl-1">{module.label}</div>
                    </div>
                {/each}
            </Flex>
        </MenuSurface>

        <MenuSurface
            bind:this={reportsMenu}
            class="tescoblue-border-thin reports-menu-surface mt-1"
            style="min-width: {amSize}rem; left: calc( {reportsPositionLeft}px  -  {amSize}rem) !important"
        >
            <Flex direction="column" align="start" class="gap-05 pa-2 w-100 bs-bb">
                {#each reports as report (report.id)}
                    <div
                        data-qa={"dropdown-rp-" + report.id}
                        on:click={() => chooseReport(report.id)}
                        on:keydown={() => chooseReport(report.id)}
                        class="clickable menu-item"
                        role="button"
                        tabindex="0"
                    >
                        {#if report.icon}
                            <Icon class="material-icons">{report.icon}</Icon>
                        {/if}
                        <div class="pl-1">{report.label}</div>
                    </div>
                {/each}
            </Flex>
        </MenuSurface>

        <MenuSurface bind:this={manageMenu} class="tescoblue-border-thin manage-menu-surface mt-1" style="min-width: {mngSize}rem">
            <Flex direction="column" align="start" class="gap-05 pa-0 ma-0">
                <div class="tescoblue white-text pa-2 ma-0 w-100 bs-bb">{$_("management")}</div>
                <Flex direction="column" align="start" class="gap-05 pa-2 w-100 bs-bb">
                    {#each filteredManagementModules as module (module.id)}
                        <div
                            data-qa={"dropdown-mng-" + module.id}
                            on:click={() => chooseManagementModule(module.id)}
                            on:keydown={() => chooseManagementModule(module.id)}
                            class="clickable menu-item"
                            role="button"
                            tabindex="0"
                        >
                            {#if module.icon}
                                <Icon class="material-icons">{module.icon}</Icon>
                            {/if}
                            <div class="pl-1">{module.label}</div>
                        </div>
                    {/each}
                </Flex>
            </Flex>
        </MenuSurface>
    </section>
{:else}
    <section>
        {#if unknownRole}
            <h1>{$_("unknown_role")}</h1>
        {/if}
    </section>
{/if}

<style>
    section#tab-bar {
        border-bottom: 2px solid #ccc !important;
        box-shadow: 0px 2px 2px 2px #eee !important;
        padding-bottom: 1px;
    }
    section#tab-content {
        height: calc(100% - 3rem);
        overflow: unset;
    }

    .clickable {
        cursor: pointer;
    }

    .clickable div:hover {
        text-decoration: underline;
    }

    .menu-item {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }
</style>
