<script>
    import { createEventDispatcher } from "svelte";

    import Button from "@smui/button";

    import { Icon } from "@smui/icon-button";
    import { mdiFileExcel } from "@mdi/js";

    import { _ } from "svelte-i18n";

    export let disabled = false;

    const dispatch = createEventDispatcher();
</script>

<Button class="{disabled ? 'grey' : 'tescoblue'} white-text pr-4" title={$_("export_to_excel")} on:click={() => dispatch("export")} data-qa="export-to-excel-button" {disabled}>
    <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
        <path fill="currentColor" d={mdiFileExcel} />
    </Icon>
    {$_("export")}
</Button>

<style>
</style>
