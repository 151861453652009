<script lang="ts">
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";
  import Tooltip, { Wrapper, Content } from "@smui/tooltip";
  import CircularProgress from "@smui/circular-progress";

  import DataTable, { Head, Body, Row, Cell, Pagination, Label, SortValue } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton, { Icon } from "@smui/icon-button";
  import Checkbox from "@smui/checkbox";

  import { nvl, getFieldErrMsg, existsError, existErrors } from "../Upload/Upload";
  import { mdiPencilBoxOutline, mdiAlert, mdiAlertCircle, mdiDelete, mdiMicrosoftExcel, mdiHistory } from "@mdi/js";

  import { createEventDispatcher } from "svelte";

  import { dcList } from "../../../stores/AppConfig";
  import { authenticatedUser } from "../../../stores/AppStatus";

  // Type
  import type { SupplierAM } from "../../../types/Amendments";

  // Items
  export let items: SupplierAM[] = [];
  export let screenMode = "edit";

  export let isSupplier: boolean = false;

  const dispatch = createEventDispatcher();

  // Filters
  export let countrySearchArray = [];
  export let selectedDC = [];
  export let ceTpnSearch = "";
  export let suppSearch = "";
  export let ceDescSearch = "";
  export let deliveryDateSearch = "";
  export let statusSearchArray = [];
  export let applyStatusSearchArray = [];
  export let statusSearchErrorsArray = [];
  // checkbox
  let checked = false;
  let indeterminate = false;
  export let checkboxItems = [];

  // Flags
  export let dataValid = false;
  export let loadingData = false;
  export let canUpdate = false;

  let filteredItems: SupplierAM[] = [];
  const isAdmin = $authenticatedUser.isAdmin;

  let rowsPerPage = 10;
  let currentPage = 0;

  // Tooltip position fix
  let tooltipXpos;
  let tooltipYpos;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  //Errors existing
  $: errorsWarningsInItems = filteredItems.some((item) => existErrors(item, "E") || existErrors(item, "W"));

  // Filter
  $: filteredItems = items?.filter((item) => {
    const ceTpnMatch = (item.productId ?? "").toString().includes(ceTpnSearch);
    const productNameEnMatch = (item.productDescription ?? "").toLowerCase().includes(ceDescSearch?.toLowerCase() ?? "");
    // Search for a match in supplierId OR supplierDescription (name)
    const supplierMatch =
      (item.supplierId ?? "")
        .toString()
        .toLowerCase()
        .includes(suppSearch.toLowerCase() ?? "") || (item.supplierDescription ?? "").toLowerCase().includes(suppSearch.toLowerCase() ?? "");
    const deliveryDateMatch = (item.deliveryDate ?? "").includes(deliveryDateSearch ?? "");

    // arrays
    const statusMatch = statusSearchArray.length > 0 ? statusSearchArray.some((unitId) => item.status?.includes(unitId)) : true;
    const applyStatusMatch = applyStatusSearchArray.length > 0 ? applyStatusSearchArray.some((unitId) => item.applyStatus?.includes(unitId)) : true;
    const countryMatch = countrySearchArray.length > 0 ? countrySearchArray.some((unitId) => item.country?.includes(unitId)) : true;

    // Convert item.warehouseId to a DC code for comparison
    const itemDcCode = findDcCodeById(item.warehouseId);
    const dcMatch = selectedDC.length > 0 ? selectedDC.some((dc) => dc.id === itemDcCode) : true;

    // Check for any errors or warnings
    let errorsMatch = true;
    if (statusSearchErrorsArray.length > 0) {
      const errorsExist = item.errors?.some((errorItem) => statusSearchErrorsArray.some((searchError) => searchError.message === errorItem.message));
      const warningsExist = item.warnings?.some((warningItem) => statusSearchErrorsArray.some((searchError) => searchError.message === warningItem.message));
      errorsMatch = errorsExist || warningsExist;
    }

    // Check for valid type
    const includesValidType = statusSearchErrorsArray.some(({ type }) => type === "V");

    // Include items with no errors or warnings
    if (includesValidType) {
      errorsMatch = errorsMatch || ((!item.errors || item.errors.length === 0) && (!item.warnings || item.warnings.length === 0));
    }

    return countryMatch && ceTpnMatch && productNameEnMatch && supplierMatch && statusMatch && dcMatch && applyStatusMatch && deliveryDateMatch && errorsMatch;
  });

  //checkbox select all (without error)
  function toggleSelectAll() {
    if (checked) {
      console.log("Deselect all");
      checkboxItems.length = 0;
    } else {
      checkboxItems = [...filteredItems];
    }
    console.log("Updated checkboxItems:", checkboxItems);
  }

  $: {
    if (filteredItems.length === 0) {
      checked = false;
      indeterminate = false;
    } else {
      checked = checkboxItems.length === filteredItems.length;
      indeterminate = checkboxItems.length > 0 && checkboxItems.length < filteredItems.length;
    }
  }

  // Find warehouse name
  function findWarehouseName(warehouseId) {
    const warehouse = $dcList.find((dc) => dc.id === warehouseId);
    return warehouse ? warehouse.code : "";
  }

  // Function to find DC code by ID
  function findDcCodeById(dcId) {
    const dc = $dcList.find((d) => d.id === dcId);
    return dc ? dc.code : "Unknown";
  }

  // Define the default table head with
  let defaultTableHead = [
    { columnId: "actions", label: "", numeric: false, extraClasses: "right-splitter" },
    { columnId: "error", label: "error", numeric: false, extraClasses: "right-splitter" },
    { columnId: "roundNo", label: "round_no", numeric: false, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "dc", label: "dc", numeric: false, extraClasses: "right-splitter" },
    { columnId: "tpnEn", label: "tpn_en", numeric: false, extraClasses: "" },
    { columnId: "productDescription", label: "product_en", numeric: false, extraClasses: "right-splitter" },
    { columnId: "deliveryDate", label: "delivery_date", numeric: false, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "supplierNo", label: "supplier_no", numeric: false, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "amendVol", label: "amend_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "requestedVol", label: "req_volume_cases", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "supplierDeadline", label: "supp_deadline", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "supplierConfirmedVolume", label: "supp_conf_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "supplierComment", label: "supplier_comment", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "countryComment", label: "country_comment", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "userComment", label: "user_comment", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "status", label: "status", numeric: false, extraClasses: "right-splitter" },
    { columnId: "applyStatus", label: "applyStatus", numeric: false, extraClasses: "wrap-spaces  right-splitter" },
    { columnId: "downloadTemplate", label: "downloadTemplate", numeric: false, extraClasses: "wrap-spaces" },
  ];

  // List of columns to remove
  const supplierColumnsToRemove = ["roundNo", "amendVol", "countryComment"];
  const uploadScreenColumnsToRemove = ["downloadTemplate"];

  // Start with efaultTableHead
  let tableHead = [...defaultTableHead];

  $: {
    tableHead = defaultTableHead.map((col) => {
      if (col.columnId === "tpnEn") {
        return {
          ...col,
          extraClasses: `ams ${isSupplier ? "left-1x" : screenMode === "upload" ? "left-2x" : "left-3x"} right-splitter-sub`,
        };
      }
      return col;
    });

    // Remove specific columns
    if (isSupplier) {
      tableHead = tableHead.filter((col) => !supplierColumnsToRemove.includes(col.columnId));
    }

    if (screenMode === "upload") {
      tableHead = tableHead.filter((col) => !uploadScreenColumnsToRemove.includes(col.columnId));
    }
  }
</script>

<DataTable stickyHeader table$aria-label="SA Table" class="report" style="width: 100%; max-height: calc(100vh - 280px);">
  <Head>
    <Row>
      {#each tableHead as head (head.columnId)}
        {#if head.columnId === "actions" && slice.length != 0}
          <Cell class="datatable-header {head.extraClasses}" checkbox>
            <!-- Delete Button with Checkbox -->
            {#if isAdmin}
              <Checkbox bind:checked bind:indeterminate on:click={toggleSelectAll} />
            {/if}
            {#if screenMode === "upload"}
              <div class="spacer"></div>
              <!-- Spacer instead of edit -->
              <IconButton
                title={$_("delete_all")}
                size="button"
                on:click={() =>
                  checkboxItems.length > 0 &&
                  dispatch(
                    "deleteSelectedRows",
                    checkboxItems.map((item) => item.id),
                  )}
                disabled={checkboxItems.length === 0 || screenMode !== "upload"}
              >
                <Icon tag="svg" viewBox="0 0 24 24">
                  <path fill="currentColor" d={mdiDelete} />
                </Icon>
              </IconButton>
            {/if}
          </Cell>
        {:else if head.columnId !== "error" || errorsWarningsInItems}
          <Cell numeric={head.numeric} columnId={head.columnId} class={`${slice.length > 0 ? `${head.extraClasses} datatable-header` : "datatable-header"}`}>
            <Label>{$_(head.label)}</Label>
          </Cell>
        {/if}
      {/each}
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice.length == 0}
      {#if !dataValid}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("load_week_data_or_import")}</td>
        </Row>
      {:else}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_for_week")}</td>
        </Row>
      {/if}
    {:else}
      {#each slice as item (item.id)}
        <Row>
          <Cell class="right-splitter-sub" checkbox>
            <Flex direction="row">
              <!-- Checkbox -->
              {#if isAdmin || (isSupplier && errorsWarningsInItems)}
                <Checkbox bind:group={checkboxItems} value={item} valueKey={item.id} />
              {/if}
              <!-- Edit -->
              <IconButton
                title={$_("edit")}
                size="button"
                on:click={() => dispatch("editRow", item)}
                disabled={!canUpdate ||
                  item.applyStatus === "applied" ||
                  (screenMode !== "uploaded" &&
                    (existsError(item, "tpnb", "E") ||
                      existsError(item, "status", "E") ||
                      existsError(item, "productId", "E") ||
                      existsError(item, "requestedVol", "E") ||
                      existsError(item, "new", "E")))}
              >
                <Icon
                  tag="svg"
                  viewBox="0 0 24 24"
                  class={!canUpdate ||
                  (screenMode !== "uploaded" && item.applyStatus === "applied") ||
                  existsError(item, "tpnb", "E") ||
                  existsError(item, "status", "E") ||
                  existsError(item, "productId", "E") ||
                  existsError(item, "requestedVol", "E") ||
                  existsError(item, "new", "E")
                    ? "grey lighten-2"
                    : "tescoblue-text"}
                >
                  <path fill="currentColor" d={mdiPencilBoxOutline} />
                </Icon>
              </IconButton>
              {#if screenMode === "edit" && isAdmin}
                <!-- History -->
                <IconButton title={$_("history")} size="button" on:click={() => dispatch("history", item.amendCountryId)} disabled={screenMode !== "edit"}>
                  <Icon tag="svg" viewBox="0 0 24 24" class={screenMode !== "edit" ? "" : "tescoblue-text"}>
                    <path fill="currentColor" d={mdiHistory} />
                  </Icon>
                </IconButton>
              {/if}
              {#if screenMode === "upload"}
                <!-- Delete -->
                <IconButton title={$_("delete")} size="button" on:click={() => dispatch("deleteSinglRow", item.id)} disabled={screenMode !== "upload"}>
                  <Icon tag="svg" viewBox="0 0 24 24" class={screenMode !== "upload" ? "" : "tescoblue-text"}>
                    <path fill="currentColor" d={mdiDelete} />
                  </Icon>
                </IconButton>
              {/if}
            </Flex>
          </Cell>
          {#if errorsWarningsInItems}
            <!-- Errors, Warnings-->
            <Cell class="right-splitter-sub" style="overflow: visible">
              <Flex direction="row">
                {#if existErrors(item, "E")}
                  <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      role="button"
                      tabindex="0"
                      aria-label="Show errors"
                      on:mouseenter={(event) => {
                        tooltipYpos = event.clientY;
                        tooltipXpos = event.clientX;
                      }}
                      on:keydown={(event) => {
                        if (event.key === "Enter") {
                          tooltipYpos = event.clientY;
                          tooltipXpos = event.clientX;
                        }
                      }}
                    >
                      <path fill="red" d={mdiAlertCircle} />
                    </svg>
                    <Tooltip
                      style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                      surface$style="max-width: max-content"
                    >
                      <Content style="max-width: fit-content;">
                        {@html item.errors ? item.errors.map((x) => $_(x.message)).join("<br>") : null}
                      </Content>
                    </Tooltip>
                  </Wrapper>
                {/if}

                {#if existErrors(item, "W")}
                  <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      role="button"
                      tabindex="0"
                      aria-label="Show warnings"
                      on:mouseenter={(event) => {
                        tooltipYpos = event.clientY;
                        tooltipXpos = event.clientX;
                      }}
                      on:keydown={(event) => {
                        if (event.key === "Enter") {
                          tooltipYpos = event.clientY;
                          tooltipXpos = event.clientX;
                        }
                      }}
                    >
                      <path fill="orange" d={mdiAlert} />
                    </svg>
                    <Tooltip
                      style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                      surface$style="max-width: max-content"
                    >
                      <Content style="max-width: fit-content;">
                        {@html item.warnings ? item.warnings.map((x) => $_(x.message)).join("<br>") : null}
                      </Content>
                    </Tooltip>
                  </Wrapper>
                {/if}
              </Flex>
            </Cell>
          {/if}
          <!-- roundNo -->
          {#if !isSupplier}
            <Cell class="text-center right-splitter-sub" numeric>
              {#if existsError(item, "roundNo", "E")}
                <span class="red-text" title={$_(getFieldErrMsg(item, "roundNo", "E"))}>{item.roundNo ?? ""}</span>
              {:else}
                <span>{item.roundNo ?? ""}</span>
              {/if}
            </Cell>
          {/if}
          <!-- warehouseId -->
          <Cell class="text-center right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "warehouseId", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "warehouseId", "E"))}>{item.country} <br />{findWarehouseName(item.warehouseId) ?? ""}</span>
            {:else}
              <span>{item.country} <br />{findWarehouseName(item.warehouseId) ?? ""}</span>
            {/if}
          </Cell>
          <!-- productId -->
          <Cell class="text-right">
            {#if screenMode == "upload" && existsError(item, "productId", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "productId", "E"))}>{item.productId ?? ""}<br />{item.tpnb ?? ""}</span>
            {:else}
              <span>{item.productId ?? ""}<br />{item.tpnb ?? ""}</span>
            {/if}
          </Cell>
          <Cell class="text-center right-splitter-sub">{item.productDescription ?? ""}</Cell>
          <!-- deliveryDate -->
          <Cell class="text-center right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "deliveryDate", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "deliveryDate", "E"))}>{item.deliveryDate ?? ""}</span>
            {:else}
              <span>{item.deliveryDate ?? ""}</span>
            {/if}
          </Cell>
          <!-- supplierId -->
          <Cell numeric class="right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "supplierId", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "supplierId", "E"))}>{item.supplierId ?? ""}<br />{item.supplierDescription ?? ""}</span>
            {:else}
              <span>{item.supplierId ?? ""}<br />{item.supplierDescription ?? ""}</span>
            {/if}
          </Cell>
          {#if !isSupplier}
            <Cell numeric class="right-splitter-sub">{item.amendVol ?? ""}</Cell>
          {/if}
          <!-- requestedVol -->
          <Cell numeric class="right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "requestedVol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "requestedVol", "E"))}>{item.requestedVol ?? ""}</span>
            {:else}
              <span>{item.requestedVol ?? ""}</span>
            {/if}
          </Cell>
          <Cell class="right-splitter-sub">{item.deadline ?? ""}</Cell>
          <!-- confirmedVol -->
          <Cell numeric class="right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "confirmedVol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "confirmedVol", "E"))}>{item.confirmedVol ?? ""}</span>
            {:else}
              <span>{item.confirmedVol ?? ""}</span>
            {/if}
          </Cell>
          <Cell class="right-splitter-sub">{item.supplierComment ?? ""}</Cell>
          {#if !isSupplier}
            <Cell class="right-splitter-sub">{item.countryComment ?? ""}</Cell>
          {/if}
          <Cell class="right-splitter-sub">{item.userComment ?? ""}</Cell>
          <Cell class="right-splitter-sub">{$_(item.status) ?? ""}</Cell>
          <Cell class="right-splitter-sub">{$_(item.applyStatus)}</Cell>
          {#if screenMode !== "upload"}
            <Cell>
              {#if item.fileUuid}
                <IconButton title={$_("download")} on:click={() => dispatch("downloadRow", item.fileUuid)} disabled={item.status == "new"}>
                  <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                    <path fill="currentColor" d={mdiMicrosoftExcel} />
                  </Icon>
                </IconButton>
              {/if}
            </Cell>
          {/if}
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}>last_page</IconButton>
  </Pagination>
</DataTable>

<style>
  .spacer {
    display: inline-block;
    width: 24px;
    height: 24px;
    opacity: 0;
  }
</style>
