<script lang="ts">
    import { Cell } from "@smui/data-table";

    import { _ } from "svelte-i18n";

    export let errors = [];
    export let warnings = [];
    export let screenMode;
    export let country;
    export let currency;
    export let productCurrency;
    export let price;
    export let pricePromo;

    function nvl(val, ret) {
        if (val && val != null) {
            return val;
        } else {
            return ret && ret != null ? ret : "";
        }
    }

    function getFieldErrMsg(field, type) {
        if (type == "E") {
            return $_([...errors].filter((x) => x.field == field)[0].message);
        } else {
            return $_([...warnings].filter((x) => x.field == field)[0].message);
        }
    }

    function existsError(field, type) {
        if (type == "E") {
            return errors && errors != null && errors?.length > 0 && errors.map((x) => x.field).includes(field);
        } else {
            return warnings && warnings != null && warnings.length > 0 && warnings.map((x) => x.field).includes(field);
        }
    }
</script>

{#key currency}
    {#if (currency && currency != null) || productCurrency != null}
        <Cell data-qa={"price-cell-" + (currency || productCurrency || "default")} class="text-right right-splitter-sub">
            <!-- Price Regular -->
            {#if screenMode == "upload"}
                {#if existsError("price" + country, "E")}
                    <span class="red-text" title={getFieldErrMsg("price" + country, "E")}>{nvl(price, "-")}</span>
                {:else if productCurrency == null && price == null}
                    <span />
                {:else if productCurrency != null && price == null}
                    <span />
                {:else}
                    <span>{nvl(price, "")}</span>
                {/if}
            {:else if productCurrency != null && price != null}
                <span>{nvl(price, "-")}</span>
            {:else}
                <span />
            {/if}

            <!-- Price Promo -->
            {#if screenMode == "upload"}
                {#if existsError("pricePromo" + country, "E")}
                    <span>(</span><span class="red-text" title={getFieldErrMsg("pricePromo" + country, "E")}>{nvl(pricePromo, "-")}</span><span>)</span>
                {:else if productCurrency == null && pricePromo == null}
                    <span />
                {:else if productCurrency != null && pricePromo == null}
                    <span />
                {:else}
                    <span>(</span><span>{nvl(pricePromo, "-")}</span><span>)</span>
                {/if}
            {:else if productCurrency != null && pricePromo != null}
                <span>(</span><span>{nvl(pricePromo, "-")}</span><span>)</span>
            {:else}
                <span />
            {/if}

            <!-- Currency -->
            {#if screenMode == "upload"}
                {#if existsError("currency" + country, "E")}
                    <span class="red-text" title={getFieldErrMsg("currency" + country, "E")}>{nvl(currency, "")}</span>
                {:else if productCurrency == null && price == null && pricePromo == null}
                    <span />
                {:else}
                    <span>{nvl(currency, "")}</span>
                {/if}
            {:else}
                <span>{nvl(productCurrency, "")}</span>
            {/if}
        </Cell>
    {:else}
        <!-- Empty Cell -->
        <Cell class="text-right right-splitter-sub" />
    {/if}
{/key}

<style>
</style>
