<script>
    import Paper, { Title, Content } from "@smui/paper";
    import Flex from "svelte-flex";
    import Button from "@smui/button";
    import FormField from "@smui/form-field";
    import Checkbox from "@smui/checkbox";
    import { format } from "date-fns";
    import { _ } from "svelte-i18n";
    import Chip, { Set, Text } from "@smui/chips";

    import { handleApiError } from "./lib/errorHandler";

    import { snackbarSuccess, snackbarError, authenticatedUser } from "../../stores/AppStatus";
    import { roles, dashboardList, weekStatuses, notificationMap, enableNotifications } from "../../stores/AppConfig";
    import { weeks } from "../../stores/Weeks";
    import { getForecast as apiGetForecast } from "../../api/Forecast";
    import { getWeekDashboard as apiGetWeekDashboard, updateWeekStatus as apiUpdateWeekStatus } from "../../api/Weeks";
    import { createNotificationJob as apiCreateNotificationJob } from "../../api/Notification";

    import ConfirmationDialog from "../dialogs/ConfirmationDialog.svelte";
    import QSSummaryCard from "./QuoteSheets/QSSummaryCard.svelte";
    import QSSummarySupplierCard from "./QuoteSheets/QSSummarySupplierCard.svelte";
    import FCSummaryCard from "./Forecasts/FCSummaryCard.svelte";
    import AmSummaryCard from "./SupplierAmendments/AmSummaryCard.svelte";
    import TescoAppLogo from "../../components/elementary/TescoAppLogo.svelte";

    export let pageEmpty = false;
    export let refreshFlag = false;

    let confirmChangeWeekText;
    let confirmChangeWeekTitle;
    let confirmChangeWeekValues = {};
    let confirmChangeWeekNotifyUsers = false;
    let confirmChangeWeekOpen = false;
    let confirmChangeWeekId;
    let confirmChangeWeekNewStatus;
    let confirmChangeWeekNotifyFlagVisible = false;

    // Loading...
    let apiCallProcessing = false;

    $: if (refreshFlag) {
        refreshDashboard();
        refreshFlag = false;
    }

    async function refreshDashboard() {
        console.log("================= refreshDashboard ==============");
        apiCallProcessing = true;
        // console.log("B:", {$dashboardList});
        const ret = await apiGetWeekDashboard();
        console.log({ ret });
        if (ret && Array.isArray(ret)) {
            ret.forEach((w) => {
                if (w.forecasts && Array.isArray(w.forecasts)) {
                    w.forecasts.forEach((r) => {
                        r.fcPurchasedLines = r.fcPurchasedLines + r.fcPurchasedLessLines + r.fcPurchasedMoreLines;
                    });
                }
            });
            $dashboardList = [...ret];
            // console.log("A:", {$dashboardList});
        } else {
            console.log("$dashboardList is empty");
        }
        apiCallProcessing = false;
    }

    async function changeWeekStatus(weekId, year, week, currStatus) {
        console.log("================= changeWeekStatus ==============");
        console.log({ weekId });
        console.log({ year });
        console.log({ week });
        console.log({ currStatus });

        var newStatus = $weekStatuses[$weekStatuses.findIndex((x) => x.id === currStatus) + 1].id;
        console.log({ newStatus });

        confirmChangeWeekId = weekId;
        confirmChangeWeekNewStatus = newStatus;
        console.log({ confirmChangeWeekId });
        console.log({ confirmChangeWeekNewStatus });

        switch (currStatus) {
            case "created":
                confirmChangeWeekTitle = $_("open_week");
                confirmChangeWeekText = "do_you_want_open_week";
                confirmChangeWeekValues = {
                    values: { year: year, week: week },
                };
                confirmChangeWeekNotifyUsers = true;
                confirmChangeWeekNotifyFlagVisible = true;
                confirmChangeWeekOpen = true;
                break;
            case "open":
                confirmChangeWeekTitle = $_("start_buying");
                confirmChangeWeekText = "do_you_want_start_buying";
                confirmChangeWeekValues = {
                    values: { year: year, week: week },
                };
                confirmChangeWeekNotifyUsers = true;
                confirmChangeWeekNotifyFlagVisible = true;
                confirmChangeWeekOpen = true;
                break;
            case "forecasted":
                try {
                    const res = await apiGetForecast(
                        null,
                        year,
                        week,
                        null /*dcSearch.map(x => {return {id: x.id}.id}).join(',').toUpperCase()*/,
                        null /*warehouseId*/,
                        null /*productId*/,
                        null /*productDetailId*/,
                        null /*productDesc*/,
                        null /*tpnb*/,
                        null /*description*/,
                        null /*ean*/,
                        null /*buyer*/,
                        null /*technicalManager*/,
                        null /*note*/,
                        null /*status*/,
                        null /*reasonCode*/,
                        null /*fileName*/,
                        "N" /*purchaseConfirmed*/,
                    );
                    if (res.length > 0) {
                        let buyersCount = 0;
                        try {
                            const rawBuyers = res.map((x) => x?.buyer || "");

                            // Use an object instead of Set
                            const uniqueBuyers = {};
                            rawBuyers.forEach((buyer) => {
                                if (buyer) uniqueBuyers[buyer] = true;
                            });

                            buyersCount = Object.keys(uniqueBuyers).length; // Unique count
                            console.log("Unique Buyers Count:", buyersCount);
                        } catch (error) {
                            console.error("Error while processing buyers:", error);
                        }

                        // Use the computed buyers count instead of creating the array
                        $snackbarError.text = $_("cannot_close_buying_because_uncompleted_purchases", { values: { buyers: buyersCount } });
                        $snackbarError.element && $snackbarError.element.open();
                    } else {
                        confirmChangeWeekTitle = $_("stop_buing");
                        confirmChangeWeekText = "do_you_want_to_stop_buying_for_week";
                        confirmChangeWeekValues = {
                            values: { year: year, week: week },
                        };
                        confirmChangeWeekNotifyUsers = true;
                        confirmChangeWeekNotifyFlagVisible = true;
                        confirmChangeWeekOpen = true;
                    }
                } catch (error) {
                    console.log("changeWeekStatus().error");
                    console.log(error);
                    if (
                        !handleApiError(
                            $_("week_status_update_failed", {
                                values: { reason: error.message },
                            }),
                            error,
                            "non-fatal",
                        )
                    )
                        throw error;
                }
                break;
            case "purchased":
                confirmChangeWeekTitle = $_("close_week");
                confirmChangeWeekText = "do_you_want_to_close_week";
                confirmChangeWeekValues = {
                    values: { year: year, week: week },
                };
                confirmChangeWeekNotifyUsers = true;
                confirmChangeWeekNotifyFlagVisible = false;
                confirmChangeWeekOpen = true;
                break;
            default:
                break;
        }
        // console.log({confirmChangeWeekTitle});
        // console.log({confirmChangeWeekText});
        // console.log({confirmChangeWeekValues});
        // console.log({confirmChangeWeekNotifyUsers});
        // console.log({confirmChangeWeekOpen});

        return newStatus;
    }

    async function updateWeekStatus(weekId, newWeekStatus, notifyUsersFlag) {
        console.log("================= updateWeekStatus ==============");
        console.log({ weekId });
        console.log({ newWeekStatus });
        apiUpdateWeekStatus({ id: weekId, status: newWeekStatus })
            .then((res) => {
                // console.log({ res });
                if (enableNotifications && notifyUsersFlag && $notificationMap.has(newWeekStatus)) {
                    apiCreateNotificationJob(Math.trunc(weekId / 100), weekId % 100, $notificationMap.get(newWeekStatus), null, null, null, null, null, null, null);
                }
                $snackbarSuccess.text = $_("week_status_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                let nb = $dashboardList.findIndex((w) => w.id == weekId);
                $dashboardList[nb].status = newWeekStatus;
                let nw = $weeks.findIndex((w) => w.id == weekId);
                $weeks[nw].status = newWeekStatus;

                console.log({ $dashboardList });
                console.log({ $weeks });
                //canUpdate = false;
            })
            .catch((error) => {
                //console.log(error);
                if (
                    !handleApiError(
                        $_("week_status_update_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    }
</script>

<section id="dashboard">
    <div class="list {$authenticatedUser.isSupplier ? ' slim ' : ''} {!pageEmpty ? 'mt-4' : ''}">
        {#if pageEmpty}
            <TescoAppLogo position="center" size="big" />
        {:else if $authenticatedUser.role && $dashboardList}
            {@const modules = $roles.filter((r) => r.id == $authenticatedUser.role).at(0)?.modules}
            {#each $dashboardList as week}
                <Paper variant="outlined" class="pa-0 ma-0 " data-qa={"dashboard-board-" + week.id + "-" + week.status}>
                    <Title class="pa-0 ma-0 tescoblue white-text sticky-week-header">
                        <Flex direction="row" align="center" justify="between" class="gap-05 ml-3 mr-3">
                            <Flex direction="row" align="center" justify="start" class="gap-05">
                                <div title={$_("week")} class="pl-2 pt-1 pb-1 mt-1 mb-1 pr-4">
                                    {week.description}
                                    <span class="fs-1rem">{format(new Date(week.weekStart), "dd.MM.")} - {format(new Date(week.weekEnd), "dd.MM.")} </span>
                                </div>
                                <Set
                                    data-qa={"dashboard-board-status-" + week.id + "-" + week.status}
                                    chips={[$_(week.status)]}
                                    let:chip
                                    nonInteractive
                                    class="square-chip"
                                    title={$_("status")}
                                >
                                    <Chip {chip}>
                                        <Text>{chip}</Text>
                                    </Chip>
                                </Set>
                            </Flex>
                            {#if week.status != "closed" && $authenticatedUser.isAdmin}
                                <div title={$_([...$weekStatuses].filter((w) => w.id == week.status)?.at(0)?.actionLabel)}>
                                    <Button
                                        data-qa={"dashboard-board-week-status-btn-" + week.id + "-" + week.status}
                                        class="tescoblue-text white-tescoblue-outlined pl-4 pr-4"
                                        on:click={() => {
                                            changeWeekStatus(week.id, week.tescoYear, week.tescoWeek, week.status);
                                        }}>{$_([...$weekStatuses].filter((w) => w.id == week.status)?.at(0)?.actionLabel)}</Button
                                    >
                                </div>
                            {/if}
                        </Flex>
                    </Title>
                    <Content class="ma-0 pa-0">
                        {#if !$authenticatedUser.isSupplier}
                            <Flex direction="row" align="stretch" justify="around" class="gap-1" style="height=100%">
                                {#if modules.includes("qts")}
                                    <QSSummaryCard {week} {apiCallProcessing} on:editQSWeek on:uploadQSData />
                                    {#if modules.includes("fc") || modules.includes("bs")}
                                        <div class="divider" />
                                    {/if}
                                {/if}
                                {#if modules.includes("fc") || modules.includes("bs")}
                                    <FCSummaryCard bind:week {apiCallProcessing} on:editFCWeek on:uploadFCData on:buyingFCWeek on:editForecastsDC on:buyingDC />
                                {/if}
                            </Flex>
                        {:else}
                            <Flex direction="row" align="stretch" justify="around" class="gap-1" style="height=100%">
                                {#if modules.includes("qts")}
                                    <QSSummarySupplierCard {week} {apiCallProcessing} on:editQSWeek on:uploadQSData />
                                    {#if modules.includes("ams")}
                                        <div class="divider" />
                                    {/if}
                                {/if}
                                {#if modules.includes("ams") || modules.includes("bs")}
                                    <AmSummaryCard {week} {apiCallProcessing} on:editASWeek on:uploadASData />
                                {/if}
                            </Flex>
                        {/if}
                    </Content>
                </Paper>
                <br />
            {/each}
        {/if}
    </div>
</section>

<ConfirmationDialog
    open={confirmChangeWeekOpen}
    title={confirmChangeWeekTitle}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmChangeWeekOpen = false;
    }}
    on:confirm={() => {
        confirmChangeWeekOpen = false;
        updateWeekStatus(confirmChangeWeekId, confirmChangeWeekNewStatus, confirmChangeWeekNotifyUsers);
    }}
>
    <Flex direction="column">
        <div class="tescoblue-text fs-1p2rem">
            {$_(confirmChangeWeekText, confirmChangeWeekValues)}
        </div>
        {#if enableNotifications && confirmChangeWeekNotifyFlagVisible}
            <FormField style="align-self: start;">
                <Checkbox bind:checked={confirmChangeWeekNotifyUsers} />
                <span slot="label">{$_("notify_users")}</span>
            </FormField>
        {/if}
    </Flex>
</ConfirmationDialog>

<style>
    section#dashboard {
        height: 100%;
        overflow-x: clip;
        overflow-y: auto;
    }
</style>
