<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Label } from "@smui/data-table";
  import IconButton, { Icon } from "@smui/icon-button";

  import { mdiPencilBoxOutline, mdiCartPlus } from "@mdi/js";

  import Flex from "svelte-flex";
  import { _, number } from "svelte-i18n";

  import { createEventDispatcher } from "svelte";

  import { appParameters } from "../../../stores/AppConfig";

  // Items
  export let items: Items[] = [];
  export let product = {};
  export let filter = [];
  export let hideBuyIcon = false;
  export let hideEditIcon = false;
  export let purchasedSuppliers = [];
  export let proposedPurchaseVolume = [];
  export let country = [];

  type Items = {
    qsId: number;
    quoteSheetId: number;
    supplierId: number;
    supplierName: string;
    country: string;
    currency: string;
    variety: number;
    countryOfOrigin: number;
    cartonLogistics: number;
    palletLogistics: number;
    lwCartonLogistics: number;
    lwPalletLogistics: number;
    participation: number;
    volume: number;
    volume1w: number;
    volume1y: number;
    remainingVolume: number;
    units: string;
    cartonVolume: number;
    palletVolume: number;
    price: number;
    priceEur: number;
    priceEurLW: number;
    priceEurLY: number;
    priceLW: number;
    priceLY: number;
    pricePromo: number;
    pricePromoEur: number;
  };
  const dispatch = createEventDispatcher();

  // $: {
  // console.log("--------------------- PSQuoteSheetsTable ---------------------");
  //   console.log({purchasedSuppliers});
  //   console.log({proposedPurchaseVolume});
  // console.log({items});
  // console.log({product});
  // console.log({filter});
  // console.log({country});
  // console.log({$appParameters});

  //   console.log("dialogTopPosition:", dialogTopPosition);
  //   console.log("dialogRightPosition:", dialogRightPosition)
  //
  // }
</script>

<DataTable table$aria-label="Quote Sheets table" class="w-100 mt-2">
  <Head>
    <Row>
      <Cell
        rowspan="2"
        columnId="actions"
        class="datatable-header pl-0 pr-0 sticky-col"
      >
        <Label></Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="supplierId"
        class="datatable-header right-splitter-sub"
      >
        <Label>{$_("supplier")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="supplierName"
        class="datatable-header right-splitter-sub"
      >
        <Label>{$_("supplier_name")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="country"
        class="datatable-header right-splitter-sub"
      >
        <Label>{$_("country")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="currency"
        class="datatable-header right-splitter-sub"
      >
        <Label>{$_("currency")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="variety"
        class="datatable-header right-splitter-sub"
      >
        <Label>{$_("variety")}</Label>
      </Cell>
      <Cell
        rowspan="3"
        columnId="countryOfOrigin"
        class="datatable-header text-center wrap-spaces right-splitter-sub bottom-splitter-sub"
      >
        <Label>{$_("country_of_origin")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="cartonLogistics"
        class="datatable-header text-center wrap-spaces right-splitter-sub"
      >
        <Label>{$_("carton_logistics")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="palletLogistics"
        class="datatable-header text-center wrap-spaces right-splitter-sub"
      >
        <Label>{$_("pallet_logistics")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="volume"
        class="datatable-header right-splitter-sub wrap-spaces"
      >
        <Label>{$_("volume")} <small>{$_("participation")}</small></Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="remainingVolume"
        class="datatable-header text-center wrap-spaces right-splitter-sub"
      >
        <Label>{$_("remaining_volume")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="units"
        class="datatable-header right-splitter-sub"
      >
        <Label>{$_("units")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="cartonVolume"
        class="datatable-header text-center wrap-spaces right-splitter-sub"
      >
        <Label>{$_("carton_volume")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="palletVolume"
        class="datatable-header text-center wrap-spaces right-splitter-sub"
      >
        <Label>{$_("pallet_volume")}</Label>
      </Cell>
      <Cell
        colspan="3"
        columnId="price"
        class="datatable-header text-center wrap-spaces right-splitter-sub bottom-splitter-sub"
      >
        <Label>{$_("offered_price")}</Label>
      </Cell>
      <Cell
        rowspan="2"
        columnId="pricePromo"
        class="datatable-header text-center wrap-spaces right-splitter-sub bottom-splitter-sub"
      >
        <Label>{$_("offered_price_promo")}</Label>
      </Cell>
    </Row>
    <Row>
      <Cell
        columnId="price"
        class="datatable-header text-center wrap-spaces right-splitter-sub no-border-radius"
      >
        <Label>{$_("cur")}</Label>
      </Cell>
      <Cell
        columnId="price1w"
        class="datatable-header text-center wrap-spaces right-splitter-sub"
      >
        <Label>{$_("lw")}</Label>
      </Cell>
      <Cell
        columnId="price1y"
        class="datatable-header text-center wrap-spaces right-splitter-sub no-border-radius"
      >
        <Label>{$_("ly")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if items.length == 0 || (items.length > 0 && items.filter((i) => (filter.length == 0 || filter.includes(i.country)) && (!country || country.length == 0 || country.includes(i.country))).length == 0)}
      <Row>
        <td
          colspan="18"
          class="mdc-data-table__cell text-center"
          style="width: 100%">{$_("no_data")}</td
        >
      </Row>
    {:else}
      {#each items
        .filter((i) => filter.length == 0 || filter.includes(i.country))
        .sort((a, b) => ([a.country[0]
              .replace("C", "1")
              .replace("S", "2")
              .replace("H", "3"), a.supplierName.toLowerCase()].join(":") > [b.country[0]
              .replace("C", "1")
              .replace("S", "2")
              .replace("H", "3"), b.supplierName.toLowerCase()].join(":") ? 1 : -1)) as item (item.id)}
        {#if (country && country.length > 0 && country.includes(item.country)) || !country || country.length == 0}
          {@const itemPurchasedVolume =
            proposedPurchaseVolume
              .filter(
                (x) =>
                  x.quoteSheetId == item.qsId &&
                  x.supplierId == item.supplierId,
              )
              .at(0)?.totalVol || 0}
          {@const remainingVolume = item.remainingVolume - itemPurchasedVolume}
          {@const logParam =
            $appParameters["logistics_equal_" + item.country.toLowerCase()]
              ?.value}

          <Row>
            <Cell class="pl-0 pr-0 sticky-col">
              <Flex direction="row">
                {#if !hideBuyIcon && purchasedSuppliers.filter((x) => x.supplierId == item.supplierId).length == 0}
                  <div title={$_("buy")}>
                    <IconButton
                      size="button"
                      class="tescoblue-text opacity-1"
                      on:click={() => dispatch("newPurchase", { ...item })}
                    >
                      <Icon
                        tag="svg"
                        viewBox="0 0 24 24"
                        class="tescoblue-text"
                      >
                        <path fill="currentColor" d={mdiCartPlus} />
                      </Icon>
                    </IconButton>
                  </div>
                {/if}
                {#if !hideEditIcon}
                  <div title={$_("edit")}>
                    <IconButton
                      size="button"
                      class="tescoblue-text opacity-1"
                      on:click={() =>
                        dispatch("editQuoteSheetRow", { ...item })}
                    >
                      <Icon
                        tag="svg"
                        viewBox="0 0 24 24"
                        class="tescoblue-text"
                      >
                        <path fill="currentColor" d={mdiPencilBoxOutline} />
                      </Icon>
                    </IconButton>
                  </div>
                {/if}
              </Flex>
            </Cell>
            <Cell class="right-splitter-sub">{item.supplierId}</Cell>
            <Cell class="right-splitter-sub">{item.supplierName}</Cell>
            <Cell class="right-splitter-sub text-center">{item.country}</Cell>
            <Cell class="right-splitter-sub text-center">{item.currency}</Cell>
            <Cell class="right-splitter-sub"
              >{item.variety ? item.variety : ""}</Cell
            >
            <Cell class="right-splitter-sub text-center text-bold"
              >{item.countryOfOrigin}</Cell
            >
            <Cell class="right-splitter-sub" numeric>
              {#if ["ALL", "CART"].includes(logParam) && item.lwCartonLogistics && item.cartonLogistics != item.lwCartonLogistics}
                <span
                  class="red lighten-3 pa-2"
                  title={$_("lw") + ": " + item.lwCartonLogistics}
                >
                  {item.cartonLogistics}
                </span>
              {:else if ["ALL", "CART"].includes(logParam) && product.cartonLogistics && item.cartonLogistics != product.cartonLogistics}
                <span
                  class="yellow lighten-2 pa-2"
                  title={$_("product") + ": " + product.cartonLogistics}
                >
                  {item.cartonLogistics}
                </span>
              {:else}
                {item.cartonLogistics}
              {/if}
            </Cell>
            <Cell class="right-splitter-sub" numeric>
              {#if ["ALL", "PAL"].includes(logParam) && item.lwPalletLogistics && item.palletLogistics != item.lwPalletLogistics}
                <span
                  class="red lighten-3 pa-2"
                  title={$_("lw") + ": " + item.lwPalletLogistics}
                >
                  {item.palletLogistics}
                </span>
              {:else if ["ALL", "PAL"].includes(logParam) && product.palletLogistics && item.palletLogistics != product.palletLogistics}
                <span
                  class="yellow lighten-2 pa-2"
                  title={$_("product") + ": " + product.palletLogistics}
                >
                  {item.palletLogistics}
                </span>
              {:else}
                {item.palletLogistics}
              {/if}
            </Cell>
            <Cell class="right-splitter-sub" numeric
              >{item.volume}<br /><small>({item.participation} %)</small>
            </Cell>
            <Cell class="right-splitter-sub" numeric
              ><span
                class="font-weight-bold {item.remainingVolume == 0
                  ? 'tescored-text'
                  : 'tescoblue-text'}">{item.remainingVolume}</span
              >
            </Cell>
            <Cell class="right-splitter-sub text-center">{item.units}</Cell>
            <Cell class="right-splitter-sub" numeric>{item.cartonVolume}</Cell>
            <Cell class="right-splitter-sub" numeric>{item.palletVolume}</Cell>
            <Cell class="right-splitter-sub text-right">
              <span class="price">
                <span class=" text-bold text-end">{item.price}</span> <br />
                <div class="black-text">
                  <small><span>{item.priceEur} EUR</span></small>
                </div>
              </span>
            </Cell>
            <Cell class="right-splitter-sub text-right">
              {#if item.priceLW}
                <span class="text-end">{item.priceLW}</span>
                {#if item.priceEurLW > 0}
                  <br />
                  <div class="grey-text">
                    <small><span>{item.priceEurLW} EUR</span></small>
                  </div>
                {/if}
              {/if}
            </Cell>
            <Cell class="right-splitter-sub text-right">
              {#if item.priceLY}
                <span class="text-end">{item.priceLY}</span>
                {#if item.priceEurLY > 0}
                  <br />
                  <div class="grey-text">
                    <small><span>{item.priceEurLY} EUR</span></small>
                  </div>
                {/if}
              {/if}
            </Cell>
            <Cell class="text-right">
              <span class="price">
                {#if item.pricePromo}
                  <span class=" text-bold text-end">{item.pricePromo}</span>
                  <br />
                  <div class="black-text">
                    <small><span>{item.pricePromoEur} EUR</span></small>
                  </div>
                {/if}
              </span>
            </Cell>
          </Row>
        {/if}
      {/each}
    {/if}
  </Body>
</DataTable>

<style>
</style>
