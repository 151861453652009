<script lang="ts">
    import { onMount } from "svelte";

    import { countries } from "../../../stores/AppConfig";
    import { Flags } from "../../../services/Images";

    import { Cell } from "@smui/data-table";
    import Select, { Option } from "@smui/select";

    import { _ } from "svelte-i18n";

    export let value;
    export let editable = true;
    export let showIcon = false;
    export let replaceList = null;
    export let style = "";

    let edEl;
    let focused = false;

    let list = [];

    // const setFocus = (() => {
    //     if (edEl && edEl?.getElement()) {
    //         edEl.getElement().focus();
    //         edEl.getElement().select();
    //         focused = true;
    //     }
    // })

    // onMount(() => {
    //     console.log("OnMount!!!");
    //     list = (replaceList && Array.isArray(replaceList) ? [...replaceList] : [...$countries]);
    //     list.forEach(x => x.id = x.id.toUpperCase())
    //     console.log({list});
    // });

    $: if (replaceList && Array.isArray(replaceList)) {
        list = [...replaceList];
        list.forEach((x) => (x.id = x.id.toUpperCase()));
    } else {
        list = [...$countries];
        list.forEach((x) => (x.id = x.id.toUpperCase()));
    }

    // $: {
    //     console.log("===========")
    //     console.log({value});
    //     console.log({editable});
    //     console.log({showIcon});
    //     console.log({replaceList})
    //     console.log({list})
    //     console.log("^^^^^^^^^^^")
    // }
</script>

<div class="select-cell">
    <Select
        variant="filled"
        bind:value
        bind:this={edEl}
        data-qa="dt-currency-select-cell"
        noLabel={true}
        {style}
        class="white tescoblue-text w-100 outlined"
        key={(item) => `${item ? item.id : ""}`}
        disabled={!editable}
        withLeadingIcon={value}
        on:SMUISelect:change
    >
        <svelte:fragment slot="leadingIcon">
            {#if showIcon && value && value.length > 0}
                {@const country = $countries.filter((x) => x.id == value).at(0)}
                <div class="pl-4 pr-2">
                    <img src={Flags[country.flag]} alt={country.id} width="20px" />
                </div>
            {:else}
                <div class="pl-4" />
            {/if}
        </svelte:fragment>

        {#if list && list.length > 0}
            {#key value}
                {#each list as country (country.id)}
                    <Option value={country.id}><img src={Flags[country.flag]} alt={country.id} width="20px" /> <span class="pl-4">{country.id}</span></Option>
                {/each}
            {/key}
        {/if}
    </Select>
</div>

<style>
    .select-cell {
        margin-top: 1px;
    }
</style>
