<script lang="ts">
    import { years } from "../../../stores/Weeks";

    import Menu from "@smui/menu";
    import List, { Item, Text } from "@smui/list";
    import Button, { Group, GroupItem, Label } from "@smui/button";

    import { Icon } from "@smui/icon-button";
    import { mdiMenuDown } from "@mdi/js";

    import { _ } from "svelte-i18n";

    export let year;
    export let disabled = false;

    let yearsMenu: Menu;
    let yearsMenuBtnWidth;
</script>

{#if $years}
    <Group id="years-menu-grp" class="text-box white-text valign-center ma-0 pa-0 pr-2 pl-2 h-100">
        <div use:GroupItem>
            <Button
                data-qa="select-year-btn"
                title={$_("year")}
                on:click={() => {
                    yearsMenuBtnWidth = document.getElementById("years-menu-grp").clientWidth;
                    yearsMenu.setOpen(true);
                }}
                variant="raised"
                class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} h-100 pt-0 pb-0 pl-4 pr-2"
                {disabled}
            >
                <Label class="font-weight-medium">{year.year}</Label>
                <Icon class="pr-0 mr-0 pl-0 ml-1" tag="svg" viewBox="2 2 20 20">
                    <path fill="currentColor" d={mdiMenuDown} />
                </Icon>
            </Button>
            <Menu bind:this={yearsMenu} anchorCorner="BOTTOM_RIGHT" class="actions-menu" style="min-width: {yearsMenuBtnWidth}px">
                <List>
                    {#each $years.sort((a, b) => (a.year < b.year ? 1 : -1)) as yr}
                        <Item
                            data-qa={"year-item-" + yr.year}
                            class="fs-14 small"
                            on:SMUI:action={() => {
                                year = yr;
                            }}
                        >
                            <Text>{yr.year}</Text>
                        </Item>
                    {/each}
                </List>
            </Menu>
        </div>
    </Group>
{/if}

<style>
</style>
