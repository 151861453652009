<script>
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";

    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();

    export let name = "add_item";
    export let disabled = false;
    export let hint = "";
</script>

<Flex direction="row" justify="start" class="gap-1" title={hint ? hint : $_("add_item")}>
    <Button
        class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
        {disabled}
        data-qa="add-item-button"
        on:click={() => {
            dispatch("addItemBtnClicked");
        }}
    >
        <Icon class="material-icons">add_circle</Icon>
        {$_(name)}
    </Button>
</Flex>

<style>
</style>
