<script lang="ts">
    import { countries } from "../../../stores/AppConfig";
    import { Flags } from "../../../services/Images";

    import Paper from "@smui/paper";
    import IconButton, { Icon } from "@smui/icon-button";
    import Button, { Group, GroupItem, Label } from "@smui/button";
    import { mdiClose, mdiFilterVariant, mdiCloseCircleOutline, mdiCheckAll, mdiViewGrid, mdiReload, mdiMenuDown } from "@mdi/js";

    import Menu from "@smui/menu";
    import List, { Item, Separator, Text } from "@smui/list";

    import MenuSurface from "@smui/menu-surface";
    import FormField from "@smui/form-field";
    import { Input } from "@smui/textfield";
    import Checkbox from "@smui/checkbox";
    import Select, { Option } from "@smui/select";

    import FloatingLabel from "@smui/floating-label";

    import Flex from "svelte-flex";

    import { _ } from "svelte-i18n";

    import { createEventDispatcher } from "svelte";

    export let title = $_("filter");
    export let selection = [];
    export let size = 20;
    export let multiselect = false;

    const dispatch = createEventDispatcher();

    let surface: MenuSurface;

    let countryMenu: Menu;
    let countryMenuBtnWidth;

    let filterValue;

    $: if (selection || !selection) {
        console.log({ selection });
        if (Array.isArray(selection) && selection.length > 0) {
            filterValue = selection
                .sort((x, y) => (x.orderNo > y.orderNo ? 1 : -1))
                .map((x) => x.id.toUpperCase())
                .join(", ");
        } else {
            filterValue = null;
        }

        // console.log("filterValue: ", filterValue);
    }

    // $: {
    //     console.log({selection});
    //     console.log({filterValue});
    // }

    const handleFilterClose = (e) => {
        if (e.detail.action === "accept") {
            console.log({ selection });
        }
    };
</script>

<div class="">
    {#if !multiselect}
        <div class="select-cell">
            <Select
                variant="outlined"
                bind:value={selection}
                class="white tescoblue-text small custom-icon with-parameter w-inherit"
                key={(country) => `${country ? country.id : ""}`}
                label={title}
                withLeadingIcon={selection}
                menu$class="mdc-menu-surface--fixed with-parameter__menu"
            >
                <svelte:fragment slot="leadingIcon">
                    {#if selection && selection.length > 0}
                        {@const country = $countries.filter((x) => x.id == selection).at(0)}
                        <div class="pl-4 pr-2">
                            <img src={Flags[country.flag]} alt={country.id} width="20px" />
                        </div>
                    {/if}
                </svelte:fragment>

                <Option value="" />
                {#key selection}
                    <!--force list recreation to properly set default option-->
                    {#each $countries as country}
                        <Option value={country.id}><img src={Flags[country.flag]} alt={country.id} width="20px" /> <span class="pl-4">{country.id.toUpperCase()}</span></Option>
                    {/each}
                {/key}
            </Select>
        </div>
    {:else}
        <!--Multiselect-->
        <Paper class="filter-bar" elevation={0} style="width: {size}rem">
            <IconButton
                data-qa="country-filter-dropdown-btn"
                class="tescoblue white-text prefix tescoblue-border-thin"
                on:click={() => surface.setOpen(true)}
                size="button"
                title={$_("set_filter")}
            >
                <Icon tag="svg" viewBox="0 0 24 24" class="pl-1" size="mini">
                    <path fill="currentColor" d={mdiFilterVariant} />
                </Icon>
            </IconButton>
            <div
                class="value pa-0 ma-0 tescoblue-border-thin {selection && selection.length > 0 ? 'black-text' : 'grey-text text-darken-2'} px-1"
                role="button"
                tabindex="0"
                on:click={() => surface.setOpen(true)}
                on:keydown={(e) => e.key === "Enter" && surface.setOpen(true)}
                title={filterValue ? [title, filterValue].join(": ") : title}
            >
                {filterValue ? filterValue : title}
            </div>
            <IconButton
                data-qa="country-filter-clear-btn"
                class="tescoblue {filterValue === '' ? 'tescoblue-text' : 'white-text'}  suffix tescoblue-border-thin"
                on:click={() => (selection = [])}
                size="button"
                disabled={filterValue === ""}
                title={$_("clear_filter")}
            >
                <Icon tag="svg" viewBox="0 0 24 24" class="pr-1" size="mini">
                    <path fill="currentColor" d={mdiCloseCircleOutline} />
                </Icon>
            </IconButton>
        </Paper>
        <MenuSurface bind:this={surface} anchorCorner="TOP_LEFT" class="tescoblue-border-thin" style="min-width: {size}rem">
            <Flex direction="column" align="start">
                <div class="tescoblue white-text w-100 pa-2 bs-bb fs-1rem">
                    <Label>{title}</Label>
                </div>
                <Flex direction="column" align="start" class="pa-2 w-100 bs-bb">
                    <Flex directon="row" class="w-100 gap-1 pa-1 bs-bb white sticky-toolbar" justify="start">
                        <Button
                            data-qa="country-filter-select-all-btn"
                            class="small tescoblue-text outlined "
                            on:click={() => {
                                selection = [...$countries];
                            }}
                        >
                            <Icon tag="svg" viewBox="0 0 24 24" class="mr-1">
                                <path fill="currentColor" d={mdiCheckAll} />
                            </Icon>
                            {$_("all")}
                        </Button>
                        <Button
                            data-qa="country-filter-deselect-all-btn"
                            class="small tescoblue-text outlined "
                            on:click={() => {
                                selection = [];
                            }}
                        >
                            <Icon tag="svg" viewBox="0 0 24 24" class="mr-1">
                                <path fill="currentColor" d={mdiViewGrid} />
                            </Icon>
                            {$_("none")}
                        </Button>
                    </Flex>

                    {#each $countries as country (country.id)}
                        <FormField class="w-100 hover">
                            <Checkbox
                                data-qa={"country-filter-value-" + country.id}
                                bind:group={selection}
                                value={country}
                                on:change={() => {
                                    dispatch("change", { data: country, checked: selection.map((c) => c.id).includes(country.id) });
                                }}
                            />
                            <img src={Flags[country.flag]} alt={country.id} width="20px" /> <span class="pl-4">{country.id.toUpperCase()} </span></FormField
                        >
                    {/each}
                    <Flex direction="row" justify="end" class="w-100">
                        <Button
                            data-qa="country-filter-close-btn"
                            title={$_("close")}
                            on:click={() => surface.setOpen(false)}
                            variant="raised"
                            class="tescoblue white-text h-100 pt-0 pb-0 pl-2 pr-2"
                        >
                            <Label class="font-weight-medium">
                                {$_("close")}
                            </Label>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </MenuSurface>
    {/if}
</div>

<style>
    .value {
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 34px;
        line-height: 2rem;
        white-space: nowrap;
    }
</style>
