<script lang="ts">
    import { _ } from "svelte-i18n";
    import DataTable, { Head, Body, Row, Cell, Label } from "@smui/data-table";
    import IconButton, { Icon } from "@smui/icon-button";
    import { mdiPencilBoxOutline, mdiDelete } from "@mdi/js";
    import Flex from "svelte-flex";
    import CircularProgress from "@smui/circular-progress";
    import { createEventDispatcher } from "svelte";
    import { Flags } from "../../../../services/Images";

    export let allExchangeRates = [];
    export let currencySearchArray = [];
    export let validFromSearch = "";
    export let validToSearch = "";
    export let validNow = false;

    export let dataValid = false;
    export let loadingData = false;

    // Pagging
    let rowsPerPage = 10;
    let currentPage = 0;

    const date = new Date();
    const today = date.toISOString().split("T")[0];

    const dispatch = createEventDispatcher();

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, finalFilteredItems.length);
    $: slice = finalFilteredItems.slice(start, end);
    $: lastPage = Math.max(Math.ceil(finalFilteredItems.length / rowsPerPage) - 1, 0);
    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    function getLatestEntries(data) {
        const latestEntries = new Map();
        data.forEach((item) => {
            if (!latestEntries.has(item.currencyId) || new Date(item.validFrom) > new Date(latestEntries.get(item.currencyId).validFrom)) {
                latestEntries.set(item.currencyId, item);
            }
        });
        return Array.from(latestEntries.values());
    }

    // Initial Filter
    $: filteredItems = allExchangeRates.filter((item) => {
        const currencyMatch = currencySearchArray.length === 0 || currencySearchArray.some((currency) => currency.id === item.currencyId);
        const validFromMatch = !validFromSearch || new Date(item.validFrom) >= new Date(validFromSearch);
        const validToMatch = !validToSearch || new Date(item.validFrom) <= new Date(validToSearch);
        return currencyMatch && validFromMatch && validToMatch;
    });

    // Apply validNow Filter if needed
    $: finalFilteredItems = validNow ? getLatestEntries(filteredItems) : filteredItems;
</script>

<DataTable stickyHeader table$aria-label={$_("exchange_rate_list")} style="width: 100%; max-height: calc(100vh - 250px);">
    <Head>
        <Row>
            <Cell columnId="actions" class="datatable-header">
                <Label></Label>
            </Cell>
            <Cell columnId="currency" class="datatable-header text-center">
                <Label>{$_("currency")}</Label>
            </Cell>
            <Cell columnId="currencyName" class="datatable-header text-center">
                <Label>{$_("name")}</Label>
            </Cell>
            <Cell columnId="validFrom" class="datatable-header">
                <Label>{$_("valid_from")}</Label>
            </Cell>
            <Cell columnId="valueInEur" class="datatable-header">
                <Label>{$_("value_in_eur")}</Label>
            </Cell>
        </Row>
    </Head>
    <Body>
        {#if loadingData}
            <Row>
                <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">
                    <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
                </td>
            </Row>
        {:else if slice.length == 0}
            {#if !dataValid}
                <Row>
                    <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("search_instructions")}</td>
                </Row>
            {:else}
                <Row>
                    <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_found")}</td>
                </Row>
            {/if}
        {:else}
            {#each slice.sort((x, y) => (Number(x.id) > Number(y.id) ? 1 : -1)) as rate (rate.id)}
                <Row>
                    <Cell class="pa-0 ma-0">
                        <Flex direction="row" class="gap-0">
                            <IconButton
                                title={$_("edit")}
                                size="button"
                                class={rate.validFrom < today ? "" : "tescoblue-text"}
                                disabled={rate.validFrom < today}
                                on:click={() => dispatch("editRow", rate.id)}
                            >
                                <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
                                    <path fill="currentColor" d={mdiPencilBoxOutline} />
                                </Icon>
                            </IconButton>
                            <IconButton
                                title={$_("delete")}
                                size="button"
                                class={rate.validFrom < today ? "" : "tescoblue-text"}
                                disabled={rate.validFrom < today}
                                on:click={() => dispatch("deleteRow", rate)}
                            >
                                <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
                                    <path fill="currentColor" d={mdiDelete} />
                                </Icon>
                            </IconButton>
                        </Flex>
                    </Cell>
                    <Cell class="text-center"><img src={Flags[rate.currencyFlag]} alt={rate.currencyName} width="20px" /> {rate.currencyCode}</Cell>
                    <Cell class="text-center">{$_(rate.currencyName)}</Cell>
                    <Cell>{rate.validFrom}</Cell>
                    <Cell>{rate.valueInEur} {rate.currencySign}</Cell>
                </Row>
            {/each}
        {/if}
    </Body>
</DataTable>

<style>
</style>
