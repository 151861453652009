<script lang="ts">
    import Paper from "@smui/paper";
    import Accordion, { Panel, Header, Content } from "@smui-extra/accordion";
    import IconButton, { Icon } from "@smui/icon-button";
    import Button, { Label } from "@smui/button";
    import { mdiFilterVariant, mdiCloseCircleOutline, mdiCheckAll, mdiViewGrid } from "@mdi/js";
    import MenuSurface from "@smui/menu-surface";
    import FormField from "@smui/form-field";
    import Checkbox from "@smui/checkbox";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    export let title = $_("filter");
    export let valuesArray = [];
    export let selection = [];
    export let size = 20;
    export let sortValues = true;

    let surface: MenuSurface;
    let filterValue = $_(title);
    let categorizedMessages = {};
    let headerCheckboxStates = {};
    let permanentValidSelection = { type: "V", dummy: true, message: "Valid", field: "id" };

    // Define categories for display
    const categories = {
        E: "errors",
        W: "warnings",
        V: "valid",
    };

    // Sort messages based on their type
    $: categorizedMessages = valuesArray.reduce(
        (acc, item) => {
            if (!acc[item.type]) acc[item.type] = [];
            acc[item.type].push(item);
            return acc;
        },
        { E: [], W: [], V: [permanentValidSelection] },
    );

    // Update headerCheckboxStates based on selection changes
    $: Object.keys(categorizedMessages).forEach((category) => {
        const items = categorizedMessages[category];
        const allSelected = items.length > 0 && items.every((item) => selection.includes(item));
        const noneSelected = items.length === 0 || items.every((item) => !selection.includes(item));

        headerCheckboxStates[category] = {
            checked: items.length > 0 && allSelected,
            indeterminate: !allSelected && !noneSelected,
        };
    });

    function handleHeaderCheckboxChange(category, event) {
        const isChecked = event.target.checked;
        if (isChecked) {
            // Add all items of this category to the selection if not already included
            categorizedMessages[category].forEach((item) => {
                if (!selection.includes(item)) selection.push(item);
            });
        } else {
            // Remove all items of this category from the selection
            selection = selection.filter((item) => !categorizedMessages[category].includes(item));
        }
        // Trigger Svelte
        selection = selection.slice();
    }

    function handleItemCheckboxChange(item, event) {
        const isChecked = event.target.checked;
        if (isChecked && !selection.includes(item)) {
            selection.push(item);
        } else if (!isChecked) {
            selection = selection.filter((i) => i !== item);
        }
        // Trigger Svelte
        selection = selection.slice();
    }

    $: if (selection || !selection) {
        if (Array.isArray(selection) && selection.length > 0) {
            if (typeof selection.at(0) === "object") {
                filterValue = selection
                    .sort((x, y) => (x.id > y.id ? 1 : -1))
                    .map((x) => $_(x.message))
                    .join(", ");
            } else {
                filterValue = selection.sort((x, y) => (Number(x) > Number(y) ? 1 : -1)).join(", ");
            }
        } else {
            filterValue = title;
        }
    }

    //    $: console.log("Selection changed:", selection);
    //    $: console.log("categorizedMessages changed:", categorizedMessages);
</script>

<div class="">
    <Paper class="filter-bar" elevation={0} style="width: {size}rem">
        <IconButton
            data-qa="multi-select-errors-set-filter"
            class="tescoblue white-text prefix tescoblue-border-thin"
            on:click={() => surface.setOpen(true)}
            size="button"
            title={$_("set_filter")}
        >
            <Icon class="pl-1" size="mini" tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiFilterVariant} />
            </Icon>
        </IconButton>
        <div
            class="value pa-0 ma-0 tescoblue-border-thin {selection && selection.length > 0 ? 'black-text' : 'grey-text text-darken-2'} px-1"
            on:click={() => surface.setOpen(true)}
            on:keydown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                    surface.setOpen(true);
                }
            }}
            tabindex="0"
            role="button"
            title={filterValue}
            aria-label="Open filter options"
        >
            {filterValue}
        </div>
        <IconButton
            data-qa="multi-select-errors-clear-filter"
            class="tescoblue {filterValue === '' ? 'tescoblue-text' : 'white-text'}  suffix tescoblue-border-thin"
            on:click={() => (selection = [])}
            size="button"
            disabled={filterValue === ""}
            title={$_("clear_filter")}
        >
            <Icon class="pr-1" size="mini" tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiCloseCircleOutline} />
            </Icon>
        </IconButton>
    </Paper>
    <MenuSurface bind:this={surface} anchorCorner="TOP_LEFT" class="tescoblue-border-thin" style="min-width: {size}rem">
        <Flex direction="column" align="start">
            <div class="tescoblue white-text w-100 pa-2 bs-bb fs-1rem">
                <Label>{title}</Label>
            </div>
            <Flex direction="column" align="start" class="pa-2 w-100 bs-bb">
                <Flex directon="row" class="w-100 gap-1 pa-1 bs-bb white sticky-toolbar" justify="start">
                    <Button
                        data-qa="multi-select-errors-all"
                        class="small tescoblue-text outlined "
                        on:click={() => {
                            selection = [...valuesArray];
                            selection.push(permanentValidSelection);
                        }}
                    >
                        <Icon tag="svg" viewBox="0 0 24 24" class="mr-1">
                            <path fill="currentColor" d={mdiCheckAll} />
                        </Icon>
                        {$_("all")}
                    </Button>
                    <Button
                        data-qa="multi-select-errors-none"
                        class="small tescoblue-text outlined "
                        on:click={() => {
                            selection = [];
                        }}
                    >
                        <Icon tag="svg" viewBox="0 0 24 24" class="mr-1">
                            <path fill="currentColor" d={mdiViewGrid} />
                        </Icon>
                        {$_("none")}
                    </Button>
                </Flex>
                {#if typeof valuesArray.at(0) === "object"}
                    <Accordion>
                        {#each Object.keys(categories) as category}
                            <Panel extend>
                                <Header>
                                    <FormField data-qa="multi-select-errors-formfield-category" class="w-100 hover" inline style="margin-right: 8px;">
                                        <Checkbox
                                            data-qa="multi-select-errors-accordion-checkbox"
                                            checked={headerCheckboxStates[category]?.checked}
                                            indeterminate={headerCheckboxStates[category]?.indeterminate}
                                            on:change={(event) => handleHeaderCheckboxChange(category, event)}
                                        />
                                        <span slot="label">{$_(categories[category])}</span>
                                    </FormField>
                                </Header>
                                {#if categorizedMessages[category] && categorizedMessages[category].length > 0}
                                    <Content>
                                        <ul>
                                            {#each categorizedMessages[category] as item (item.message + item.field)}
                                                <FormField data-qa={"multi-select-errors-formfield-message-" + item.message} class="w-100 hover">
                                                    <Checkbox checked={selection.includes(item)} on:change={(event) => handleItemCheckboxChange(item, event)} />
                                                    <span slot="label">{$_(item.message)}</span>
                                                </FormField>
                                            {/each}
                                        </ul>
                                    </Content>
                                {/if}
                            </Panel>
                        {/each}
                    </Accordion>
                {:else}
                    {#each sortValues ? valuesArray.sort((x, y) => (x > y ? 1 : -1)) : valuesArray as option}
                        <FormField data-qa={"multi-select-errors-formfield-option-" + option} class="w-100 hover">
                            <Checkbox bind:group={selection} value={option} checked={selection.includes(option)} />
                            <span slot="label">{$_(option)}</span>
                        </FormField>
                    {/each}
                {/if}
                <Flex direction="row" justify="end" class="w-100 pt-2">
                    <Button
                        data-qa="multi-select-errors-close-button"
                        title={$_("close")}
                        on:click={() => surface.setOpen(false)}
                        variant="raised"
                        class="tescoblue white-text h-100 pt-0 pb-0 pl-2 pr-2"
                    >
                        <Label class="font-weight-medium">
                            {$_("close")}
                        </Label>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    </MenuSurface>
</div>

<style>
    .value {
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 34px;
        line-height: 2rem;
        white-space: nowrap;
    }
</style>
