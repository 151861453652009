<script lang="ts">
    import { onMount } from "svelte";
    import Dialog, { Title as DTitle, Content as Content, Actions, InitialFocus } from "@smui/dialog";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Button, { Label } from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import Textfield from "@smui/textfield";
    import Flex from "svelte-flex";
    import ChipInput from "../common/ChipInput.svelte";
    import CircularProgress from "@smui/circular-progress";

    import DTInputCell from "../common/DTInputCell.svelte";
    import DTSelectCell from "../common/DTSelectCell.svelte";
    import Autocomplete from "../../extra/Autocomplete.svelte";

    import { snackbarError, selectedWeek } from "../../../stores/AppStatus";

    import { _ } from "svelte-i18n";
    import { countryList, currencyList, unitList } from "../../../stores/AppConfig";
    import { suppliersList } from "../../../stores/Suppliers";
    import { loadQuoteSheet } from "../Load/QuoteSheet.js";

    import { loadSuppliers as callLoadSuppliers, loadSupplierProducts as callLoadSupplierProducts } from "../Load/Supplier";
    import { nvl, getFieldErrMsg, existsError, clearError, updateErrors } from "../Upload/Upload.js";
    import {
        validateSupplierStatus,
        validateProductStatus,
        validateUnits,
        validateVolume,
        validateCurrency,
        validatePrice,
        validateCartonLogistics,
        validatePalletLogistics,
        validateCountryOfOriginCode,
        validatePriceIsNotEmpty,
    } from "../Upload/ValidateQuoteSheet";

    import { createEventDispatcher } from "svelte";

    export let item;
    export let supplierDisabled = false;
    export let productDisabled = true;
    export let screenMode = "edit";
    export let open;
    export let title;
    export let headerColors = "tescoblue white-text";
    export let big = false;
    export let type = "add";

    let unitListLoc = [];
    let currencyListLoc = [];
    let countries = ["Cz", "Sk", "Hu"];
    let hasValidated = false;

    // Variables for product
    let productText = "";
    let productOptions = [];
    let itemPrivilage: boolean = false;
    let itemExists = false;
    let productsFetching = false;
    let selectedProduct = null;

    // Supplier variables
    let supplierOptions = [];
    let supplierValue = {};
    let supplierText = "";
    let suppliersFetching = false;
    let selectedSupplier = null;

    let itemDuplicityLoadCheck = false;

    // Upload mode - store fields with error
    let keepUnitsEnabled = screenMode == "upload" && existsError(item, "units", "E");

    let buttonSaveEnabled = false;

    // Autocomplete
    let suppEl;
    let prodEl;
    let menuBottom;
    let menuWidth;

    // Country variables options and text
    let countryChips = [];
    let selectedCountryOfOrigin = null;
    let countryOptions = [...$countryList]
        .filter((e) => e.active)
        .map((x) => {
            return { code: x.code, name: x.description, text: x.code + " - " + x.description };
        });

    const dispatch = createEventDispatcher();

    // Check if user has the privilage to edit the item
    $: itemPrivilage = item && item.itemPrivilage !== undefined ? item.itemPrivilage : false;

    $: buttonSaveEnabled =
        !suppliersFetching &&
        !productsFetching &&
        !itemDuplicityLoadCheck &&
        item &&
        itemPrivilage &&
        (item.errors == null || (Array.isArray(item.errors) && item.errors.length === 0)) &&
        item?.countryOfOrigin &&
        item?.volume &&
        item?.supplierId != null &&
        (item?.productStatus === 1 || item?.productStatus === true);

    $: productNotSelected = !item || (item && (!item?.productId || item?.productId == ""));

    let supplierInfo = `${item.supplierId} - ${item.supplierName}`;
    let productInfo = `${item.productId} - ${item.productDesc}`;
    if (type == "edit") {
        item.productCurrencyCz = item.currencyCz;
        item.productCurrencySk = item.currencySk;
        item.productCurrencyHu = item.currencyHu;
    }

    //CoO Chips
    // Search CoO name and code
    function searchCountries(input) {
        if (!input) return [];
        const lowerInput = input.toLowerCase();
        return countryOptions.filter(
            (option) =>
                (option.name.toLowerCase().includes(lowerInput) || option.code.toLowerCase().includes(lowerInput)) && !countryChips.some((chip) => chip.code === option.code),
        );
    }

    // Update countryOfOrigin when chips changes
    function handleChipChange(event) {
        console.log("handleChipChange", event);

        const { type, detail } = event; // Extract type and detail

        if (type === "add") {
            // Ensure chip is added to countryChips only once
            if (!countryChips.some((chip) => chip.code === detail.code)) {
                countryChips = [...countryChips, detail];
            }
        } else if (type === "remove") {
            // Remove the chip from countryChips
            countryChips = countryChips.filter((chip) => chip.code !== detail.code);
        }

        // Update countryOfOrigin using countryChips
        item.countryOfOrigin = countryChips
            .map((chip) => chip.code)
            .sort()
            .join(", ");
        console.log("Updated countryOfOrigin", item.countryOfOrigin);

        // Perform validations
        validateCountryOfOrigin();
        validateQsExist();
    }

    // Validation functions
    function validateSupplierId() {
        console.log("=== validateSupplierId() ===");
        clearError(item, "supplierId");
        let errors = [];
        let fields = [];

        console.log("item in validateSupplierId", { item });

        // Check if supplierId is null
        if (item.supplierId == null) {
            errors.push({ priority: 2, message: "invalid_supplier", field: "supplierId", type: "E" });
            fields.push("supplierId");
        } else {
            // Perform additional supplier validations
            errors.push(validateSupplierStatus(item));
            fields.push("supplierId");
        }

        console.log({ errors });

        // Update errors in the item
        if (errors.length > 0) {
            item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
        }
    }

    async function validateProductId() {
        console.log("=== validateProductId() ===");
        clearError(item, "productId");
        clearError(item, "product");
        let errors = [];
        let fields = [];

        // Check if productId is null
        if (item.productId == null) {
            console.log("null");
            errors.push({ priority: 2, message: "invalid_product", field: "productId", type: "E" });
            fields.push("productId");
        } else {
            // Perform product-related validations
            // For example, check if product exists in productOptions
            itemExists = productOptions.some((x) => Number(x.productId) === Number(item.productId));
            if (!itemExists) {
                console.log("missing");
                errors.push({ priority: 3, message: "product_not_found_or_missing_product_supplier_link", field: "productId", type: "E" });
                fields.push("productId");
            } else {
                console.log("satus", { item });
                errors.push(validateProductStatus(item.product, item));
                fields.push("productId");
            }
        }

        // Update errors in the item
        if (errors.length > 0) {
            item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
        }
    }

    async function qsExistLoad() {
        console.log("====================== qsExistLoad =========================");
        try {
            return await loadQuoteSheet(item.year, item.week, item.supplierId, item.productId, item.countryOfOrigin, item.variety, item.cartonLogistics, item.palletLogistics);
        } catch (error) {
            console.error("Error fetching Supplier autocomplete data:", error);
            return [];
        }
    }

    async function validateQsExist() {
        console.log("=== validateQsExist() ===");

        if (!item || !item.supplierId || !item.productId || !item.countryOfOrigin) {
            console.log("Skipping validateQsExist validation");
            return;
        }

        itemDuplicityLoadCheck = true;

        clearError(item, "quoteSheetExists");
        let errors = [];
        let fields = [];

        const qs = await qsExistLoad();

        console.log({ qs });

        if (qs && qs.length > 0 && qs[0].id && item.id != qs[0].id) {
            const qsData = qs[0];

            console.log("check: ", item.id, qs[0].id);

            errors.push({ priority: 3, message: "quote_sheet_exists_already", field: "quoteSheetExists", type: "E", qsId: qsData.id });
            fields.push("quoteSheetExists");
        }

        // Update errors in the item
        if (errors.length > 0) {
            console.log({ errors });
            item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
        }

        itemDuplicityLoadCheck = false;
    }

    function validateUnitsfn() {
        console.log("=== validateUnits() ===");
        clearError(item, "units");
        let errors = [];
        let fields = [];

        // Perform units validation
        errors.push(validateUnits(item, item.product));
        fields.push("units");

        // Update errors in the item
        if (errors.length > 0) {
            console.log({ errors });
            item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
        }
    }

    function validateCountryOfOrigin() {
        console.log("=== validateCountryOfOrigin() ===");
        clearError(item, "countryOfOrigin");
        let errors = [];
        let fields = [];

        console.log({ item });
        // Perform country of origin validation
        errors.push(validateCountryOfOriginCode(item, countryOptions));
        fields.push("countryOfOrigin");

        // Update errors in the item
        if (errors.length > 0) {
            console.log({ errors });
            item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
        }
    }

    function validateCarton() {
        if (itemExists) {
            console.log("=== validateCarton() ===");
            clearError(item, "cartonLogistics");
            let errors = [];
            let fields = [];

            // Perform volume validation
            errors.push(validateCartonLogistics(item, item.product));
            fields.push("cartonLogistics");
            console.log({ errors });
            // Update errors in the item
            if (errors.length > 0) {
                item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
            }
        }
    }

    function validatePallet() {
        if (itemExists) {
            console.log("=== validatePallet() ===");
            clearError(item, "palletLogistics");
            let errors = [];
            let fields = [];

            // Perform volume validation
            errors.push(validatePalletLogistics(item, item.product));
            fields.push("palletLogistics");

            console.log({ errors });

            // Update errors in the item
            if (errors.length > 0) {
                item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
            }
        }
    }

    function validateVolumeField() {
        console.log("=== validateVolumeField() ===");
        clearError(item, "volume");
        let errors = [];
        let fields = [];

        // Perform volume validation
        errors.push(validateVolume(item));
        fields.push("volume");

        console.log({ errors });

        // Update errors in the item
        if (errors.length > 0) {
            item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
        }
    }

    function validateInputPrice() {
        if (!hasValidated) {
            hasValidated = true; // delay as on:change and on:input do not trigger in the same time

            console.log("=== validateInputPrice() ===", { item });
            clearError(item, "price");
            let errors = [];
            let fields = [];

            errors.push(validatePriceIsNotEmpty(item, item.product));
            fields.push("price");

            for (let cntr of countries) {
                clearError(item, "price" + cntr);
                clearError(item, "pricePromo" + cntr);
                clearError(item, "currency" + cntr);

                errors.push(validatePrice(item, item.product, cntr.toUpperCase(), "REGULAR"));
                fields.push("price" + cntr);

                errors.push(validatePrice(item, item.product, cntr.toUpperCase(), "PROMO"));
                fields.push("pricePromo" + cntr);

                errors.push(validateCurrency(item, item.product, cntr.toUpperCase()));
                fields.push("currency" + cntr);
            }

            console.log({ errors });

            // Update errors in the item
            if (errors.length > 0) {
                item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
            }

            // When invalid currency because supplier is not linked to country, but price is not entered then delete warning and currency value from item
            for (let cntr of countries) {
                if (existsError(item, "currency" + cntr, "W") && getFieldErrMsg(item, "currency" + cntr, "W") == "invalid_" + cntr.toLowerCase() + "_currency_but_not_used") {
                    clearError(item, "currency" + cntr);
                    item["currency" + cntr] = null;
                }
            }
            setTimeout(() => {
                hasValidated = false;
            }, 100);
        }
    }

    onMount(async () => {
        unitListLoc = $unitList
            .filter((e) => e.active)
            .map((x) => {
                return { id: x.code, name: x.description };
            });
        currencyListLoc = $currencyList
            .filter((e) => e.active)
            .map((x) => {
                return { id: x.code, name: x.code };
            });

        suppliersFetching = true;
        loadSuppliers(null, null, 1);
        suppliersFetching = false;

        if (item && item.supplierId && item.productId) {
            productsFetching = true;
            loadSupplierProducts(item.supplierId, item.productId, 1);
            productsFetching = false;

            if (item.countryOfOrigin) {
                countryChips = item.countryOfOrigin.split(",").map((code) => {
                    const match = countryOptions.find((option) => option.code === code.trim());
                    return match ? { code: match.code, name: match.name, invalid: false } : { code: code.trim(), name: "Invalid", invalid: true };
                });
                // Sort invalid chips to the top
                countryChips.sort((a, b) => b.invalid - a.invalid);
            }
        }
    });

    async function loadSuppliers(id, rmsSupplierName, status) {
        console.log("====================== loadSuppliers =========================");
        if ($suppliersList && Array.isArray($suppliersList) && $suppliersList.length > 0) {
            supplierOptions = $suppliersList;

            if (supplierOptions.length === 1) {
                const matchedOption = supplierOptions[0];
                supplierValue = matchedOption;
                supplierText = matchedOption.text;

                item.supplierId = matchedOption.id;
                item.supplierName = matchedOption.name;
                item.supplierStatus = matchedOption.status;
                selectedSupplier = matchedOption.text;
                supplierDisabled = true;
                validateSupplierId();
                loadSupplierProducts(matchedOption.id, null, 1);
            }
            return;
        }

        suppliersFetching = true;
        $suppliersList = await callLoadSuppliers(id, rmsSupplierName, status);
        supplierOptions = $suppliersList;
        if (supplierOptions.length === 1) {
            const matchedOption = supplierOptions[0];
            console.log({ matchedOption });
            supplierValue = matchedOption;
            supplierText = matchedOption.text;
            item.supplierId = matchedOption.id;
            item.supplierName = matchedOption.name;
            item.supplierStatus = matchedOption.status;
            selectedSupplier = matchedOption.text;
            supplierDisabled = true;
            validateSupplierId();
            loadSupplierProducts(matchedOption.id, null, 1);
        }
        suppliersFetching = false;
    }

    async function loadSupplierProducts(supplierId, productId, status) {
        console.log("====================== loadSupplierProducts =========================");
        productsFetching = true;
        // Fetch the full list of products
        let fullProductList = await callLoadSupplierProducts(supplierId, productId, status);
        console.log({ fullProductList });
        // Limit list while adding items where itemPrivilage is true
        if (type == "add") {
            console.log("add");
            productOptions = fullProductList.filter((product) => product.itemPrivilage === true);
        } else {
            console.log("else");
            productOptions = fullProductList;
        }
        productsFetching = false;

        // If there is just one product, preselect it and disable the autocomplete
        if (productOptions.length === 1) {
            const matchedOption = productOptions[0];
            console.log({ matchedOption });
            selectedProduct = matchedOption;
            productText = matchedOption.text;
            item.productId = matchedOption.productId;
            item.productDesc = matchedOption.productDescription;
            item.hierarchyId = matchedOption.hierarchyId;
            item.hierarchyClassDesc = matchedOption.hierarchyClassDesc;

            item.productStatus = matchedOption.status;
            item.itemPrivilage = matchedOption?.itemPrivilage ?? false;
            item.product = matchedOption;
            productDisabled = true;
            itemExists = true;
            if (type === "add") {
                item.year = $selectedWeek.tesco_year;
                item.week = $selectedWeek.tesco_week;
                item.productCartonLogistics = matchedOption.cartonLogistics;
                item.productPalletLogistics = matchedOption.palletLogistics;
                item.cartonLogistics = matchedOption.cartonLogistics;
                item.palletLogistics = matchedOption.palletLogistics;
                item.currencyCz = matchedOption.currencyCz == null ? "" : matchedOption.currencyCz;
                item.currencySk = matchedOption.currencySk == null ? "" : matchedOption.currencySk;
                item.currencyHu = matchedOption.currencyHu == null ? "" : matchedOption.currencyHu;
                item.productCurrencyCz = matchedOption.currencyCz;
                item.productCurrencySk = matchedOption.currencySk;
                item.productCurrencyHu = matchedOption.currencyHu;
                item.units = matchedOption.units;
                item.volume = null;
                item.priceCz = null;
                item.priceSk = null;
                item.priceHu = null;
                item.pricePromoCz = null;
                item.pricePromoSk = null;
                item.pricePromoHu = null;
                item.fileName = null;
            }
        } else {
            productDisabled = false;
        }
        console.log("item exists?", itemExists);
        if (itemExists) {
            console.log("Validate loaded data", { ...item });
            checkCurrency();
            validateProductId();
            validateUnitsfn();
            validateCarton();
            validatePallet();
            validateCountryOfOrigin();
            validateVolumeField();
            validateInputPrice();
            validateQsExist();
            console.log("Validation end", { ...item });
        }
    }

    // Automatically copy currency if item is linked to item and no price entered and currency is missing
    const checkCurrency = () => {
        if (item && item.product) {
            for (let cntry of countries) {
                if (item.product["currency" + cntry] && !item["currency" + cntry] && !item["price" + cntry] && !item["pricePromo" + cntry]) {
                    item["currency" + cntry] = item.product["currency" + cntry];
                }
            }
        }
    };

    function productSelected(option) {
        console.log("====================== productSelected =========================");

        if (option && option.detail) {
            selectedProduct = option.detail;
            item.year = $selectedWeek.tesco_year;
            item.week = $selectedWeek.tesco_week;
            item.productId = selectedProduct.productId;
            item.productDesc = selectedProduct.productDescription;
            item.hierarchyId = selectedProduct.hierarchyId;
            item.hierarchyClassDesc = selectedProduct.hierarchyClassDesc;
            item.productCartonLogistics = selectedProduct.cartonLogistics;
            item.productPalletLogistics = selectedProduct.palletLogistics;
            item.cartonLogistics = selectedProduct.cartonLogistics;
            item.palletLogistics = selectedProduct.palletLogistics;
            item.countryOfOrigin = selectedProduct.countryOfOrigin == null ? "" : selectedProduct.countryOfOrigin;
            item.currencyCz = selectedProduct.currencyCz == null ? "" : selectedProduct.currencyCz;
            item.currencySk = selectedProduct.currencySk == null ? "" : selectedProduct.currencySk;
            item.currencyHu = selectedProduct.currencyHu == null ? "" : selectedProduct.currencyHu;
            item.productCurrencyCz = item.currencyCz;
            item.productCurrencySk = item.currencySk;
            item.productCurrencyHu = item.currencyHu;
            item.units = selectedProduct.units;
            item.priceCz = null;
            item.priceSk = null;
            item.priceHu = null;
            item.pricePromoCz = null;
            item.pricePromoSk = null;
            item.pricePromoHu = null;
            item.fileName = null;
            item.volume = null;
            item.variety = "";
            item.productStatus = selectedProduct.status;
            item.itemPrivilage = selectedProduct?.itemPrivilage ?? false;
            item.product = selectedProduct;
            itemExists = true;
            // Update productText
            productText = selectedProduct.text;

            productDisabled = false;
            countryOfOriginClear();
            checkCurrency();
            validateCarton();
            validatePallet();
            validateCountryOfOrigin();
            validateVolumeField();
            validateInputPrice();
            validateQsExist();
        }
    }

    function productClear() {
        console.log("====================== productClear =========================");
        itemExists = false;
        item.productId = null;
        item.productDesc = null;
        item.hierarchyId = null;
        item.hierarchyClassDesc = "";
        item.cartonLogistics = null;
        item.palletLogistics = null;
        item.productCartonLogistics = null;
        item.productPalletLogistics = null;
        item.currencyCz = "";
        item.currencySk = "";
        item.currencyHu = "";
        item.productCurrencyCz = "";
        item.productCurrencySk = "";
        item.productCurrencyHu = "";
        item.units = "";
        item.variety = "";
        item.volume = null;
        item.priceCz = null;
        item.priceSk = null;
        item.priceHu = null;
        item.pricePromoCz = null;
        item.pricePromoSk = null;
        item.pricePromoHu = null;
        item.product = null;
        selectedProduct = null;
        productText = "";
        countryChips.length = 0;
        item.errors = [];
        item.warnings = [];
        item.countryOfOrigin = null;
        selectedCountryOfOrigin = null;
    }

    function productBlur(object) {
        console.log("====================== productBlur =========================");
        console.log({ productText });
        if (typeof productText === "undefined" || !productText || productText == null || productText == "") {
            productClear();
        } else if (item?.productId != null) {
            // Check if productText exists in productOptions
            const found = productOptions.some((option) => option.text === productText);

            if (!found) {
                productClear();
            }
        }
    }

    async function supplierSelected(option) {
        console.log("====================== supplierSelected =========================");
        if (option) {
            productClear();
            item.supplierId = option.detail.id;
            item.supplierName = option.detail.name;
            item.supplierStatus = option.detail.status;
            selectedSupplier = option.detail.text;
            console.log({ item });
            validateSupplierId();
            loadSupplierProducts(option.detail.id, null, 1);
        }
    }

    function supplierClear() {
        console.log("====================== supplierClear =========================");
        item.length = 0;
        selectedSupplier = null;
        productDisabled = true;
        selectedProduct = null;
    }

    function supplierBlur(object) {
        console.log("====================== supplierBlur =========================");
        console.log({ selectedSupplier });
        if (typeof selectedSupplier === "undefined" || !selectedSupplier || selectedSupplier == null || selectedSupplier == "") {
            supplierClear();
            productClear();
        }
    }

    function countryOfOriginClear() {
        console.log("====================== countryOfOriginClear =========================");
        item.countryOfOrigin = null;
        selectedCountryOfOrigin = null;
        validateCountryOfOrigin();
    }

    function saveItemDetail() {
        console.log("====================== saveItemDetail =========================");
        if (item && item.errors && item.errors != null && item.errors.length > 0) {
            $snackbarError.text = $_("cannot_save_qs_errors_found", { values: { reason: "cannot_save_qs_errors_found" } });
            $snackbarError.element && $snackbarError.element.open();
        } else {
            dispatch("quoteSheetDialogSave", { item: item, type: type });
        }
    }

    function cancelItemDetail() {
        console.log("====================== cancelItemDetail =========================");
        item.length = 0;
        dispatch("reject");
    }

    $: {
        console.log("");
        console.log(">>>>>>>>>>>> <<<<<<<<<<<<");
        console.log(item);
        console.log({ countryChips });
        //
        console.log(productsFetching, suppliersFetching);
        console.log(buttonSaveEnabled);
        //console.log("item.priceSk: ", item?.priceSk);
        //console.log("item.priceHu: ", item?.priceHu);
        //
        //console.log("item.pricePromoCz: ", item?.pricePromoCz);
        //console.log("item.pricePromoSk: ", item?.pricePromoSk);
        //console.log("item.pricePromoHu: ", item?.pricePromoHu);
    }
</script>

<Dialog
    data-qa="qs-item-detail"
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style={big ? "width: 850px; max-width: calc(100vw - 32px);" : ""}
>
    <DTitle id="data-changed-title" class={headerColors}>{title}</DTitle>
    <Content>
        <div>
            {#if suppliersFetching}
                <Flex class="w-100" style="height: 500px;">
                    <CircularProgress style="height: 96px; width: 96px;" indeterminate />
                </Flex>
            {:else if item}
                <DataTable class="w-100 input-form no-border pa-2">
                    <Body>
                        <Row>
                            <Cell class="form-label">{$_("supplier")}</Cell>
                            {#if type == "edit"}
                                <DTInputCell colspan="4" value={supplierInfo} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else}
                                <td colspan="4" class="w-100">
                                    <Autocomplete
                                        data-qa="qs-item-detail-supplier-autocomplete"
                                        class="w-100 tescoblue-text edit-cell-border"
                                        bind:options={supplierOptions}
                                        getOptionLabel={(option) => (option ? option.text : "")}
                                        showMenuWithNoInput={false}
                                        selectOnExactMatch={true}
                                        bind:text={supplierText}
                                        bind:value={supplierValue}
                                        bind:this={suppEl}
                                        menu$class="mdc-menu-surface--fixed with-parameter__menu"
                                        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
                                        on:input={() => {
                                            productClear();
                                        }}
                                        on:focusin={() => {
                                            //console.log("In Focus", {suppEl});
                                            menuWidth = suppEl?.getElement()?.clientWidth;
                                            menuBottom = suppEl?.getElement()?.getBoundingClientRect()?.bottom;
                                        }}
                                        on:click={() => {
                                            //console.log("On Click", {suppEl});
                                            menuWidth = suppEl?.getElement()?.clientWidth;
                                            menuBottom = suppEl?.getElement()?.getBoundingClientRect()?.bottom;
                                        }}
                                        on:focus={(event) => {
                                            event.detail.target.select();
                                        }}
                                        on:blur={(event) => {
                                            supplierBlur(event);
                                        }}
                                        on:SMUIAutocomplete:selected={(option) => {
                                            productClear();
                                            supplierSelected(option);
                                        }}
                                    >
                                        <Textfield
                                            type="text"
                                            data-qa="qs-item-detail-supplier-autocomplete-text"
                                            bind:value={supplierText}
                                            withTrailingIcon={existsError(item, "supplierId", "E") || suppliersFetching}
                                            disabled={supplierDisabled || suppliersFetching}
                                            invalid={existsError(item, "supplierId", "E")}
                                            input$maxlength={30}
                                            input$autocomplete="Nothing"
                                            updateInvalide={false}
                                            input$placeholder={suppliersFetching ? $_("loading_suppliers_wait") : $_("search_supplier")}
                                            required
                                            style="min-width: 500px;"
                                            class="w-100 {supplierDisabled ? 'fs-1rem tescoblue-text edit-cell-border grey lighten-2' : ''}"
                                        >
                                            <svelte:fragment slot="trailingIcon">
                                                {#if existsError(item, "supplierId", "E")}
                                                    <Icon class="material-icons red-text">error</Icon>
                                                {:else if suppliersFetching}
                                                    <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
                                                {/if}
                                            </svelte:fragment>
                                        </Textfield>
                                    </Autocomplete>
                                </td>
                            {/if}
                        </Row>
                        {#if existsError(item, "supplierId", "E") && selectedSupplier}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "supplierId", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("product")}</Cell>
                            {#if type == "edit"}
                                <DTInputCell colspan="4" value={productInfo} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else if selectedSupplier && ((productOptions && productOptions.length > 0) || productsFetching)}
                                <td colspan="4">
                                    <Autocomplete
                                        data-qa="qs-item-detail-product-autocomplete"
                                        class="w-100 tescoblue-text edit-cell-border"
                                        bind:options={productOptions}
                                        getOptionLabel={(option) => (option ? option.text : "")}
                                        showMenuWithNoInput={false}
                                        selectOnExactMatch={true}
                                        bind:text={productText}
                                        bind:value={selectedProduct}
                                        bind:this={prodEl}
                                        menu$class="mdc-menu-surface--fixed with-parameter__menu"
                                        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
                                        on:focusin={() => {
                                            menuWidth = prodEl?.getElement()?.clientWidth;
                                            menuBottom = prodEl?.getElement()?.getBoundingClientRect()?.bottom;
                                        }}
                                        on:click={() => {
                                            menuWidth = prodEl?.getElement()?.clientWidth;
                                            menuBottom = prodEl?.getElement()?.getBoundingClientRect()?.bottom;
                                        }}
                                        on:focus={(event) => {
                                            event.detail.target.select();
                                        }}
                                        on:blur={(event) => {
                                            productBlur(event);
                                        }}
                                        on:SMUIAutocomplete:selected={(option) => {
                                            productSelected(option);
                                        }}
                                    >
                                        <Textfield
                                            type="text"
                                            data-qa="qs-item-detail-product-autocomplete-text"
                                            bind:value={productText}
                                            withTrailingIcon={existsError(item, "productId", "E")}
                                            withLeadingIcon={productsFetching}
                                            disabled={productDisabled || productsFetching}
                                            invalid={existsError(item, "productId", "E")}
                                            input$maxlength={30}
                                            input$onInput={(event) => (event.target.value = productText)}
                                            input$autocomplete="Nothing"
                                            input$placeholder={productsFetching ? $_("loading_products_wait") : $_("search_product")}
                                            updateInvalide={false}
                                            required
                                            style="min-width: 500px;"
                                            class="w-100 {productDisabled ? 'fs-1rem tescoblue-text edit-cell-border grey lighten-2' : ''}"
                                        >
                                            <svelte:fragment slot="leadingIcon">
                                                {#if productsFetching}
                                                    <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
                                                {/if}
                                            </svelte:fragment>
                                            <svelte:fragment slot="trailingIcon">
                                                {#if existsError(item, "productId", "E")}
                                                    <Icon class="material-icons red-text">error</Icon>
                                                {/if}
                                            </svelte:fragment>
                                        </Textfield>
                                    </Autocomplete>
                                </td>
                            {:else if !selectedSupplier}
                                <Cell colspan={4}>{$_("supplier_not_selected")}</Cell>
                            {:else if !item.itemPrivilage}
                                <Cell colspan={4}>{$_("supplier_has_no_products_role")}</Cell>
                            {:else}
                                <Cell colspan={4}>{$_("supplier_has_no_products_linked")}</Cell>
                            {/if}
                        </Row>
                        {#if itemExists && existsError(item, "productId", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item ? "product" : "productId", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {:else if existsError(item, "product", "W")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="orange-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "product", "W"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("hierarchy")}</Cell>
                            <!-- <DTInputCell colspan="2" bind:value={item.hierarchyId} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" /> -->
                            <DTInputCell colspan="4" bind:value={item.hierarchyClassDesc} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        </Row>
                        <Row>
                            <Cell class="form-label">{$_("units_of_sale")}</Cell>
                            {#if existsError(item, "units", "E") || keepUnitsEnabled}
                                <td colspan="4">
                                    <DTSelectCell
                                        bind:value={item.units}
                                        list={unitListLoc}
                                        format={{ showId: false, translateName: true }}
                                        disabled={screenMode == "edit" || (!existsError(item, "units", "E") && !keepUnitsEnabled) || productNotSelected}
                                        errorMsg={existsError(item, "units", "E")}
                                        required
                                        class="w-100 {existsError(item, 'units', 'E') ? '' : 'grey lighten-2'}"
                                        on:change={() => {
                                            validateUnitsfn();
                                        }}
                                    ></DTSelectCell>
                                </td>
                            {:else}
                                <DTInputCell colspan="4" value={$_(nvl(item.units, ""))} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {/if}
                        </Row>
                        {#if existsError(item, "units", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "units", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {:else if existsError(item, "units", "W")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="orange-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "units", "W"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("file_name")}</Cell>
                            <DTInputCell colspan="4" value={$_(nvl(item.fileName, ""))} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        </Row>
                        <Row>
                            <Cell class="form-label">{$_("carton_logistics")}</Cell>
                            {#if !itemPrivilage || !itemExists}
                                <DTInputCell colspan="4" value={item.cartonLogistics} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else}
                                <td colspan="4">
                                    <Textfield
                                        variant="outlined"
                                        type="text"
                                        disabled={productNotSelected}
                                        bind:value={item.cartonLogistics}
                                        withTrailingIcon={existsError(item, "cartonLogistics", "E") || existsError(item, "cartonLogistics", "W")}
                                        invalid={existsError(item, "cartonLogistics", "E")}
                                        updateInvalide={false}
                                        input$maxlength={3}
                                        input$class="tescoblue-text fs-1rem"
                                        on:input={() => {
                                            validateCarton();
                                            validateQsExist();
                                        }}
                                        on:focus={(event) => {
                                            event.detail.target.select();
                                        }}
                                        required
                                        class="w-100"
                                    >
                                        <svelte:fragment slot="trailingIcon">
                                            {#if existsError(item, "cartonLogistics", "E")}
                                                <Icon class="material-icons red-text  mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                            {:else if existsError(item, "cartonLogistics", "W")}
                                                <Icon class="material-icons orange-text  mdc-text-field__icon mdc-text-field__icon--trailing">warning</Icon>
                                            {/if}
                                        </svelte:fragment>
                                    </Textfield>
                                </td>
                            {/if}
                        </Row>

                        {#if existsError(item, "cartonLogistics", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "cartonLogistics", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {:else if existsError(item, "cartonLogistics", "W")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="orange-text fs-08rem">
                                        {$_("carton_logistics_different_from_product_definition_extended", {
                                            values: { productValue: item.productCartonLogistics },
                                        })}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("pallet_logistics")}</Cell>
                            {#if !itemPrivilage || !itemExists}
                                <DTInputCell colspan="4" value={item.palletLogistics} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else}
                                <td colspan="4">
                                    <Textfield
                                        variant="outlined"
                                        type="text"
                                        disabled={productNotSelected}
                                        bind:value={item.palletLogistics}
                                        withTrailingIcon={existsError(item, "palletLogistics", "E") || existsError(item, "palletLogistics", "W")}
                                        invalid={existsError(item, "palletLogistics", "E")}
                                        updateInvalide={false}
                                        input$maxlength={3}
                                        input$emptyValueUndefined
                                        input$class="tescoblue-text fs-1rem"
                                        on:input={() => {
                                            validatePallet();
                                            validateQsExist();
                                        }}
                                        on:focus={(event) => {
                                            event.detail.target.select();
                                        }}
                                        required
                                        class="w-100"
                                    >
                                        <svelte:fragment slot="trailingIcon">
                                            {#if existsError(item, "palletLogistics", "E")}
                                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                            {:else if existsError(item, "palletLogistics", "W")}
                                                <Icon class="material-icons orange-text mdc-text-field__icon mdc-text-field__icon--trailing">warning</Icon>
                                            {/if}
                                        </svelte:fragment>
                                    </Textfield>
                                </td>
                            {/if}
                        </Row>
                        {#if existsError(item, "palletLogistics", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "palletLogistics", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {:else if existsError(item, "palletLogistics", "W")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="orange-text fs-08rem">
                                        {$_("pallet_logistics_different_from_product_definition_extended", {
                                            values: { productValue: item.productPalletLogistics },
                                        })}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("country_of_origin")}</Cell>
                            {#if !itemPrivilage || !itemExists}
                                <DTInputCell colspan="4" value={selectedCountryOfOrigin} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else}
                                <td colspan="4">
                                    <ChipInput
                                        class="edit-cell-border"
                                        autocomplete$class="pa-2"
                                        bind:chips={countryChips}
                                        key={(country) => country.code}
                                        getChipLabel={(country) => `${country.name} (${country.code})`}
                                        getChipText={(country) => country.name}
                                        autocomplete$search={searchCountries}
                                        autocomplete$getOptionLabel={(country) => `${country.name} (${country.code})`}
                                        input$disabled={countryChips.length >= 3}
                                        chipTrailingAction$class="material-icons"
                                        chipTrailingAction$aria-label="Remove country"
                                        on:add={handleChipChange}
                                        on:remove={handleChipChange}
                                    >
                                        <svelte:fragment slot="chipTrailingAction">cancel</svelte:fragment>
                                        <svelte:fragment slot="label">{countryChips.length >= 3 ? $_("invalid_count_of_coo_codes") : $_("search")}</svelte:fragment>
                                        <CircularProgress slot="loading" style="height: 24px; width: 24px;" indeterminate />
                                    </ChipInput>
                                </td>
                            {/if}
                        </Row>
                        {#if existsError(item, "countryOfOrigin", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {!productNotSelected && (item?.countryOfOrigin == null || item?.countryOfOrigin == "")
                                            ? $_("field_is_required")
                                            : $_(getFieldErrMsg(item, "countryOfOrigin", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {:else if existsError(item, "countryOfOrigin", "W")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="orange-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "countryOfOrigin", "W"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("variety")}</Cell>
                            {#if !itemPrivilage || !itemExists}
                                <DTInputCell colspan="4" value={item.variety} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else}
                                <DTInputCell
                                    colspan="4"
                                    bind:value={item.variety}
                                    editable={!productNotSelected}
                                    numeric={false}
                                    class="fs-1rem tescoblue-text edit-cell-border rounded"
                                    on:change={() => {
                                        validateQsExist();
                                    }}
                                ></DTInputCell>
                            {/if}
                        </Row>
                        {#if existsError(item, "quoteSheetExists", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "quoteSheetExists", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        <Row>
                            <Cell class="form-label">{$_("volume")}</Cell>
                            {#if !itemPrivilage || !itemExists}
                                <DTInputCell colspan="4" value={item.volume} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                            {:else}
                                <td colspan="4">
                                    <Textfield
                                        variant="outlined"
                                        type="text"
                                        disabled={productNotSelected}
                                        bind:value={item.volume}
                                        withTrailingIcon={existsError(item, "volume", "E")}
                                        invalid={existsError(item, "volume", "E") || (!productNotSelected && (item?.volume == null || item?.volume == ""))}
                                        updateInvalide={false}
                                        input$maxlength={8}
                                        input$autocomplete="Nothing"
                                        input$emptyValueUndefined
                                        input$class="tescoblue-text fs-1rem"
                                        on:input={() => {
                                            validateVolumeField();
                                        }}
                                        on:focus={(event) => {
                                            event.detail.target.select();
                                        }}
                                        required
                                        class="w-100 tescoblue-text edit-cell-border"
                                    >
                                        <svelte:fragment slot="trailingIcon">
                                            {#if existsError(item, "volume", "E") || item?.volume == null || item?.volume == ""}
                                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                            {/if}
                                        </svelte:fragment>
                                    </Textfield>
                                </td>
                            {/if}
                        </Row>
                        {#if existsError(item, "volume", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {!productNotSelected && (item?.volume == null || item?.volume == "") && itemPrivilage
                                            ? $_("field_is_required")
                                            : $_(getFieldErrMsg(item, "volume", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {:else if existsError(item, "volume", "W")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="orange-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "volume", "W"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                        {#each countries as cntry}
                            {#if (item.product && ((item.product["currency" + cntry] && item.product["currency" + cntry] != "") || item["price" + cntry] != null || item["pricePromo" + cntry] != null)) || existsError(item, "currency" + cntry, "E")}
                                {@const hasPriceError = existsError(item, "price" + cntry, "E")}
                                {@const hasPromoPriceError = existsError(item, "pricePromo" + cntry, "E")}
                                {@const hasCurrencyError = existsError(item, "currency" + cntry, "E")}
                                {@const hasCurrencyWarning = existsError(item, "currency" + cntry, "W")}

                                <Row>
                                    <Cell class="form-label">{$_(cntry + "_price")}</Cell>
                                    <td colspan="1">
                                        <Textfield
                                            disabled={!itemPrivilage}
                                            bind:value={item["price" + cntry]}
                                            variant="outlined"
                                            type="text"
                                            editable={true}
                                            numeric={false}
                                            class="w-100 {!itemPrivilage ? 'grey lighten-2' : ''}"
                                            withTrailingIcon={hasPriceError}
                                            invalid={hasPriceError}
                                            updateInvalide={false}
                                            input$maxlength={5}
                                            input$autocomplete="Nothing"
                                            input$emptyValueUndefined
                                            input$class="tescoblue-text fs-1rem"
                                            on:input={() => {
                                                validateInputPrice();
                                            }}
                                            on:change={() => {
                                                validateInputPrice();
                                            }}
                                            on:focus={(event) => {
                                                event.detail.target.select();
                                            }}
                                        >
                                            <svelte:fragment slot="trailingIcon">
                                                {#if hasPriceError}
                                                    <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                                {/if}
                                            </svelte:fragment>
                                        </Textfield>
                                    </td>
                                    <Cell class="w-25 form-label">{$_("promo_price")}</Cell>
                                    <td colspan="1">
                                        <Textfield
                                            disabled={!itemPrivilage}
                                            bind:value={item["pricePromo" + cntry]}
                                            variant="outlined"
                                            type="text"
                                            class="w-100 {!itemPrivilage ? 'grey lighten-2' : ''}"
                                            withTrailingIcon={hasPromoPriceError}
                                            invalid={hasPromoPriceError}
                                            updateInvalide={false}
                                            input$maxlength={5}
                                            input$autocomplete="Nothing"
                                            input$emptyValueUndefined
                                            input$class="tescoblue-text fs-1rem"
                                            on:input={() => {
                                                validateInputPrice();
                                            }}
                                            on:change={() => {
                                                validateInputPrice();
                                            }}
                                            on:focus={(event) => {
                                                event.detail.target.select();
                                            }}
                                        >
                                            <svelte:fragment slot="trailingIcon">
                                                {#if hasPromoPriceError}
                                                    <Icon class="material-icons red-text  mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                                {/if}
                                            </svelte:fragment>
                                        </Textfield>
                                    </td>
                                    {#if screenMode == "edit" || !hasCurrencyError}
                                        <DTInputCell
                                            colspan="1"
                                            value={item["currency" + cntry]}
                                            editable={false}
                                            numeric={false}
                                            style="width: 7rem;"
                                            class="fs-1rem tescoblue-text grey lighten-2"
                                        />
                                    {:else}
                                        <DTSelectCell
                                            bind:value={item["currency" + cntry]}
                                            list={currencyListLoc}
                                            format={{ showId: false, translateName: true }}
                                            style="width: 7rem;"
                                            on:change={() => {
                                                validateInputPrice();
                                            }}
                                        ></DTSelectCell>
                                    {/if}
                                </Row>
                                {#if hasPriceError}
                                    <Row class="helpertext__row">
                                        <Cell></Cell>
                                        <Cell colspan={4} style="padding: 0">
                                            <div class="red-text fs-08rem">
                                                {$_(getFieldErrMsg(item, "price" + cntry, "E"))}
                                            </div>
                                        </Cell>
                                    </Row>
                                {/if}
                                {#if hasPromoPriceError}
                                    <Row class="helpertext__row">
                                        <Cell></Cell>
                                        <Cell colspan={4} style="padding: 0">
                                            <div class="red-text fs-08rem">
                                                {$_(getFieldErrMsg(item, "pricePromo" + cntry, "E"))}
                                            </div>
                                        </Cell>
                                    </Row>
                                {/if}
                                {#if hasCurrencyError}
                                    <Row class="helpertext__row">
                                        <Cell></Cell>
                                        <Cell colspan={4} style="padding: 0">
                                            <div class="red-text fs-08rem">
                                                {$_(getFieldErrMsg(item, "currency" + cntry, "E"))}
                                            </div>
                                        </Cell>
                                    </Row>
                                {:else if hasCurrencyWarning}
                                    <Row class="helpertext__row">
                                        <Cell></Cell>
                                        <Cell colspan={4} style="padding: 0">
                                            <div class="orange-text fs-08rem">
                                                {$_(getFieldErrMsg(item, "currency" + cntry, "W"))}
                                            </div>
                                        </Cell>
                                    </Row>
                                {/if}
                            {/if}
                        {/each}

                        {#if existsError(item, "price", "E")}
                            <Row class="helpertext__row">
                                <Cell></Cell>
                                <Cell colspan={4} style="padding: 0">
                                    <div class="red-text fs-08rem">
                                        {$_(getFieldErrMsg(item, "price", "E"))}
                                    </div>
                                </Cell>
                            </Row>
                        {/if}
                    </Body>
                </DataTable>
            {/if}
        </div></Content
    >
    <Actions class="pr-4 gap-1 space-around">
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click={() => {
                cancelItemDetail();
            }}
        >
            <Label>{$_("cancel")}</Label>
        </Button>
        <Button
            action="save"
            use={[InitialFocus]}
            class={buttonSaveEnabled ? "tescoblue white-text" : "grey white-text"}
            disabled={!buttonSaveEnabled}
            on:click={() => {
                saveItemDetail();
            }}
        >
            <Label>{$_("save")}</Label>
        </Button>
    </Actions>
</Dialog>

<style>
</style>
