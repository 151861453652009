<script lang="ts">
    import { onMount, createEventDispatcher } from "svelte";
    import { writable } from "svelte/store";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    import Paper from "@smui/paper";

    import { dcList } from "../../../stores/AppConfig";

    import WeekInfo from "../common/WeekInfo.svelte";

    import GetDataButton from "../common/GetDataButton.svelte";
    import UploadButton from "../common/UploadButton.svelte";
    import ClearButton from "../common/ClearButton.svelte";
    import UniversalConfirmButton from "./UniversalConfirmButton.svelte";

    import AmDataTable from "./AmDataTable.svelte";
    import AmFilterBar from "./AmFilterBar.svelte";
    import AmDetailDialog from "./AmDetailDialog.svelte";
    import FilesUploadDialog from "../common/FilesUploadDialog.svelte";
    import AmHistoryDialog from "./AmHistoryDialog.svelte";

    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";
    import FullscreenLoader from "../../elementary/FullscreenLoader.svelte";

    import {
        getSupplierAmend as apiGetSupplierAmend,
        updateSupplierAmend as apiUpdateSupplierAmend,
        nextRoundSupplierAmend as apiNextRoundSupplierAmend,
        getSupplierAmendRound as apigetSupplierAmendRound,
        generateXlsxFile,
        downloadXlsxFile,
        getSupplierAuditChanges,
    } from "../../../api/AmendmentsSupplier";

    import { getPurchase, applyUpdatePurchase } from "../../../api/Purchase";
    import { getSupplier } from "../../../api/Supplier";

    import { isActionEnabled } from "../../modules/lib/Functions";
    import { snackbarSuccess, snackbarWarning, selectedWeek, supplierAmendments, authenticatedUser } from "../../../stores/AppStatus";

    import { handleApiError } from "../lib/errorHandler";

    // Upload data
    import { uploadFiles } from "../../modules/Upload/Upload";
    import { validateUploadedAMSData } from "../Upload/ValidateAmendmentSupplier";

    // Type
    import type { SupplierAM } from "../../../types/Amendments";

    export let items: SupplierAM[] = [];
    export let showUploadDialog = false;
    export let autoLoadData = false;

    const isSupplier = $authenticatedUser.isSupplier;
    const isAdmin = $authenticatedUser.isAdmin;

    let checkboxItems: SupplierAM[] = [];
    let resetScroll = false;
    let statusErrors = writable([]);

    let showDetailDialog = false;
    let detailDialogMode = "add";

    // SA History
    let showSADialog = false;
    let loadingHistoryData = false;
    let itemHistoryData = [];

    // Delete rows dialog
    let confirmationDialogData;
    let showDeleteConfirmationDialog = false;

    // Filters
    let countrySearchArray = [];
    let selectedDC = [];
    let ceTpnSearch = "";
    let suppSearch = "";
    let ceDescSearch = "";
    let deliveryDateSearch = "";
    let statusSearchArray = [];
    let applyStatusSearchArray = [];
    let statusSearchErrorsArray = [];

    let rowData: SupplierAM;
    let backupRowData: SupplierAM;
    let screenMode = "edit";
    let suppliersData = [];

    // File file upload
    let filesUploadDialogOpen = false;
    let filesUploadState;
    let filesStatus = [];
    let filesUploadCount = 0;
    let dataLoaded = false;
    let loadingData = false;
    let unsavedRowsExists = false;
    let dataValid = false;

    // Loading...
    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    let canInsert = false;
    let canUpdate = false;
    let canDelete = false;

    const dispatch = createEventDispatcher();

    onMount(() => {
        updatePrivileges();
        checkboxItems = [];
    });

    $: if (autoLoadData) {
        getAmData();
        autoLoadData = false;
    }

    $: rowDataValid = rowData && rowData.errors && rowData.errors.length < 1 && (!isSupplier || (rowData.confirmedVol !== null && rowData.confirmedVol !== undefined));

    // Save - Rows without errors
    // All rows
    $: allValid = items && Array.isArray(items) ? items.filter((x) => !x.errors || x.errors.length === 0) : [];
    // CheckBox list rows
    $: checkboxItemsValid = checkboxItems && Array.isArray(checkboxItems) ? checkboxItems.filter((item) => !item.errors || item.errors.length === 0) : [];

    $: uploadFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.uploadStatus)).length : 0;
    $: validationFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.validationStatus)).length : 0;

    $: if ($supplierAmendments && Array.isArray($supplierAmendments) && $supplierAmendments.length > 0) {
        console.log({ $supplierAmendments });
        items = [...$supplierAmendments];
        $supplierAmendments = [];
        console.log({ items });
    }

    // create a list of errors while upload mode
    $: if (screenMode === "upload") {
        // All errors and warnings
        const allErrorsAndWarnings = items.flatMap((item) => [
            ...(item.errors ? item.errors.filter((error) => error !== null && error !== undefined) : []),
            ...(item.warnings ? item.warnings.filter((warning) => warning !== null && warning !== undefined) : []),
        ]);

        const uniqueErrors = Array.from(new Map(allErrorsAndWarnings.map((errorOrWarning) => [errorOrWarning.message, errorOrWarning])).values());

        statusErrors.set(uniqueErrors);
    } else {
        statusErrors.set([]); // Clear statusErrors if not in 'upload' mode
    }

    // Reset horizontal scroll position
    $: if (resetScroll) {
        const tableContainer = document.querySelector(".mdc-data-table__table-container");
        if (tableContainer) {
            tableContainer.scrollLeft = 0;
        }
        resetScroll = false;
    }

    const updatePrivileges = () => {
        const actionType = isSupplier ? "supp_amendments" : "amendments";

        if ($selectedWeek && $selectedWeek.status) {
            canInsert = isActionEnabled(actionType, "insert", $selectedWeek.status);
            canUpdate = isActionEnabled(actionType, "edit", $selectedWeek.status);
            canDelete = isActionEnabled(actionType, "delete", $selectedWeek.status);
            dataValid = false;
        } else {
            canInsert = canUpdate = canDelete = false;
        }
        //console.log("canInsert, canUpdate, canDelete: ", canInsert, canUpdate, canDelete, $selectedWeek);
    };

    const getAmData = async () => {
        console.log("GET");
        checkboxItems.length = 0;
        resetScroll = !resetScroll; // Reset data table horizontal scroll
        try {
            loadingData = true;
            screenMode = "edit";
            const res = await apigetSupplierAmendRound(null, $selectedWeek.tesco_year, $selectedWeek.tesco_week);

            console.log({ res });
            res.forEach((x) => {
                x.warehouseCode = $dcList.filter((w) => w.id == x.warehouseId).at(0)?.code;
                x.countryOrder = x.country.at(0).replace("C", "1").replace("S", "2").replace("H", "4");
            });
            items = [
                ...res.sort((a, b) =>
                    [a.productDescription, a.countryOrder, a.warehouseCode, a.deliveryDate].join("#").toLowerCase() >
                    [b.productDescription, b.countryOrder, b.warehouseCode, b.deliveryDate].join("#").toLowerCase()
                        ? 1
                        : -1,
                ),
            ];
            if (items.length == 0) {
                dataValid = true;
                console.log("getAmData().empty");
            } else {
                dataValid = false;
                items = items;
                console.log({ items });
            }
            dispatch("getDataRequest");
            dataLoaded = true;
            loadingData = false;
        } catch (error) {
            console.log("getAmData().error");
            loadingData = false;
            if (!handleApiError($_("get_data_failed"), error, "non-fatal")) throw error;
        }
    };

    const getSAHistory = async (id) => {
        console.log("getSAHistory");
        try {
            showSADialog = true;
            loadingHistoryData = true;
            const res = await getSupplierAuditChanges(id);
            console.log({ res });
            itemHistoryData = [...res];
            loadingHistoryData = false;
        } catch (error) {
            console.log("getSAHistory().error", { error });
            loadingHistoryData = false;
            handleApiError("get_sa_history", error, true);
        }
    };

    const nextRound = (data: SupplierAM[]) => {
        console.log("================= nextRound ==============");
        console.log({ data });

        let nextRoundRows = [...data].filter((i) => i.requestedVol !== i.confirmedVol);
        console.log({ nextRoundRows });

        if (nextRoundRows.length === 0) {
            $snackbarWarning.text = $_("no_rows_can_be_assigned_to_supplier");
            $snackbarWarning.element && $snackbarWarning.element.open();
        } else {
            apiCallProcessing = true;
            apiCallProcessingMessage = $_("updating_please_wait");
            let failedCount = 0;

            apiNextRoundSupplierAmend(nextRoundRows.map((e) => e.amendCountryId))
                .then((response) => {
                    console.log({ response });
                    console.log({ data });

                    // Process the response and update warnings
                    response.forEach((res) => {
                        if (res.id < 0) {
                            // Find the corresponding item in the items array
                            let item = items.find((i) => i.amendCountryId === res.amendCountryId);
                            if (item) {
                                // Clear previous warnings
                                item.warnings = [];

                                // Add the error to the warnings array
                                item.warnings.push({
                                    priority: 2,
                                    message: res.errorMessage,
                                    field: "roundNo",
                                    type: "W",
                                });
                                failedCount++;
                            }
                        } else if (res.id > 0) {
                            // Remove the original row from items
                            items = items.filter((i) => i.amendCountryId !== res.amendCountryId);
                            // Push the new row from the response
                            items.push(res);
                        }
                    });

                    console.log("items in round", { items });
                    items = items;
                    checkboxItems.length = 0;
                    apiCallProcessing = false;
                    apiCallProcessingMessage = $_("");

                    if (failedCount === 0) {
                        $snackbarSuccess.text = $_("amendments_successfully_assigned");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                    } else {
                        $snackbarWarning.text = $_("amendments_assigned_with_errors");
                        $snackbarWarning.element && $snackbarWarning.element.open();
                    }

                    console.log({ items }); // Log updated items to verify warnings
                })
                .catch((error) => {
                    apiCallProcessing = false;
                    apiCallProcessingMessage = $_("");
                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    };

    const weekDataInvalidated = () => {
        dataLoaded = false;
        items = [];
        checkboxItems.length = 0;
        unsavedRowsExists = false;
        updatePrivileges();
    };

    const editRow = async (row) => {
        row.errors = [];

        if (row.status != "new") {
            suppliersData = [
                {
                    id: row.supplierId,
                    name: row.supplierDescription,
                    status: row.supplierStatus,
                },
            ];
        } else {
            try {
                // Fetch purchase data and allRoundsAmendSupplierData concurrently
                const [purchaseData, allRoundsAmendSupplierData] = await Promise.all([
                    getPurchase(null, null, null, null, null, null, null, null, null, null, null, row.forecastId),
                    apiGetSupplierAmend(null, null, null, row.amendCountryId),
                ]);

                // Initial filtering of purchaseData based on items data
                const filteredPurchaseData = purchaseData.filter(
                    (purchase) =>
                        !allRoundsAmendSupplierData.some(
                            (item) =>
                                item.year === purchase.year &&
                                item.week === purchase.week &&
                                item.warehouseId === purchase.warehouseId &&
                                item.productId === purchase.productId &&
                                item.supplierId === purchase.supplierId &&
                                item.supplierId !== row.supplierId,
                        ),
                );

                console.log("filteredPurchaseData", row.supplierId, filteredPurchaseData);

                // Ensuring the current supplier is included
                if (!filteredPurchaseData.some((purchase) => purchase.supplierId === row.supplierId)) {
                    const currentRowSupplierData = purchaseData.find((purchase) => purchase.supplierId === row.supplierId);

                    if (currentRowSupplierData) {
                        filteredPurchaseData.push(currentRowSupplierData);
                    } else {
                        console.error("Current row supplier is not found in purchaseData, which is unexpected.");
                        row.errors.push({ priority: 2, message: "purchase_supplier_data_do_not_exist", field: "supplierId", type: "E" });
                        filteredPurchaseData.push({ supplierId: row.supplierId, fallback: true });
                    }
                }

                // Map each supplier
                const supplierPromises = filteredPurchaseData.map((purchase) => getSupplier(purchase.supplierId, null, 1, null));

                // Fetch all supplier data
                suppliersData = await Promise.all(supplierPromises);
                suppliersData = suppliersData.map((supplierArray) => supplierArray[0]);
                console.log("editRow suppliersData", suppliersData);
            } catch (error) {
                console.error("Error fetching data for edit:", error);
            }
        }

        rowData = { ...row };
        backupRowData = { ...rowData };
        detailDialogMode = "edit";
        showDetailDialog = true;

        console.log("editRow()", row);
    };

    const showDeleteRowConfirmation = (ids) => {
        confirmationDialogData = Array.isArray(ids) ? ids : [ids];
        showDeleteConfirmationDialog = true;
    };

    const deleteRows = (event) => {
        if (event.detail && items.length) {
            items = items.filter((item) => !event.detail.includes(item.id));
            if (items.length === 0) {
                clearAllData();
            }
        }
        confirmationDialogData = null;
    };

    ///////////////  UPLOAD ///////////////

    const uploadProgress = (fileName, status, message) => {
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.uploadStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        //console.log("fileStatus in uploadProgress: ",{ filesStatus });
        filesStatus = filesStatus;
    };

    const validationProgress = (fileName, status, message) => {
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.validationStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const validateFiles = async (files, validationProgressCallBack) => {
        console.log("============== validateFiles =============");

        if (files && files.length > 0) {
            const filePromises = files.map(async (f) => {
                validationProgressCallBack(f.fileName, "processing");

                try {
                    let amsData = [...f.items.flatMap((x) => x)];
                    let validatedData = await validateUploadedAMSData(amsData, $selectedWeek);

                    validationProgressCallBack(f.fileName, "done");
                    return validatedData;
                } catch (error) {
                    console.error("Error in validateFiles:", error);
                    validationProgressCallBack(f.fileName, "error");
                    throw error;
                }
            });

            const responses = await Promise.allSettled(filePromises);
            return responses.map((response) => response.value);
        }
    };

    const uploadAMSData = async (files) => {
        console.log("================= uploadAMSData ==============");
        try {
            filesUploadDialogOpen = true;
            filesUploadState = "started";
            filesStatus = [];
            screenMode = "upload";
            items = [];
            checkboxItems.length = 0;

            for (let f = 0; f < files.length; f++) {
                filesStatus.push({ fileName: files.item(f).name, uploadStatus: "waiting", validationStatus: "waiting" });
            }
            filesStatus = filesStatus;
            console.log({ filesStatus });

            const results = await uploadFiles(files, "AS", uploadProgress);
            console.log("Uploaded result: ", { results });
            if (results && filesUploadState == "started") {
                try {
                    const validationResults = await validateFiles(results, validationProgress);
                    console.log({ validationResults });
                    if (validationResults) {
                        for (let fileRows of validationResults) {
                            console.log({ fileRows });
                            if (fileRows && fileRows.at(0)?.productId) {
                                for (let itemRow of fileRows) {
                                    items.push(itemRow);
                                }
                            }
                        }
                        if (filesUploadState == "started") {
                            //await duplicityCheckUploadedFCData(items);
                            items = items;
                        } else {
                            items = [];
                        }
                    }
                } catch (error) {
                    console.log("Validation Fails!", { error });
                }
            }

            if (filesUploadState == "started") {
                filesUploadState = "finished";
                console.log("Uploading XLS data finished");
                unsavedRowsExists = true;
                dataLoaded = true;
            } else {
                // cancell requested
                filesUploadDialogOpen = false;
                filesUploadState = "cancelled";
            }
        } catch (error) {
            filesUploadDialogOpen = false;
            console.log({ error });
            if (!handleApiError($_(error.message + " - " + error.cause, { values: { reason: error.message } }), error, "non-fatal")) throw error;
        }
    };

    ////////// END OF UPLOAD ////////////

    const clearAllData = () => {
        console.log("================= clearAllData ==============");
        items = [];
        checkboxItems.length = 0;
        screenMode = "edit";
        filesUploadDialogOpen = false;
        dataLoaded = false;
        unsavedRowsExists = false;
    };

    const updateSendStatus = async (data) => {
        console.log("================= updateSendStatus ==============");
        console.log("updateSendStatus(data)", { data });

        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        try {
            // Extract IDs from data
            const ids = data.map((item) => item.id);

            // Call generateXlsxFile and wait for response
            const response = await generateXlsxFile({ weekId: $selectedWeek.id, ids: ids });
            console.log("generateXlsxFile response: ", { weekId: $selectedWeek.id, ids: ids }, response);

            // Update items with received UUID and deadline
            if (response) {
                const [returnMap, deadlineMap] = response;
                for (const [uuid, idsToUpdate] of Object.entries(returnMap)) {
                    idsToUpdate.forEach((id) => {
                        const index = items.findIndex((item) => item.id === id);
                        if (index !== -1) {
                            // fileUuid
                            items[index].fileUuid = uuid;
                            // Status sent
                            items[index].status = "sent";
                            // Update the deadline if present
                            items[index].deadline = deadlineMap[id.toString()];
                        } else {
                            console.log(`Item with id ${id} not found`);
                        }
                    });
                }
            }

            $snackbarSuccess.text = $_("supplier_amendment_updated");
            $snackbarSuccess.element && $snackbarSuccess.element.open();
            unsavedRowsExists = false;
        } catch (error) {
            console.log("Update error: ", error);
            if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
        } finally {
            checkboxItems.length = 0;
            apiCallProcessing = false;
        }
    };

    const updateData = (data, type = null) => {
        console.log("================= updateData ==============");
        console.log("updateData(data,type)", { data }, type);

        checkboxItems.length = 0;
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        if (type === "edit" && screenMode === "upload") {
            // Update items array with new data
            if (data && data.length === 1) {
                const newItem = data[0];
                const index = items.findIndex((item) => item.id === newItem.id);
                if (index !== -1) {
                    items[index] = {
                        ...items[index],
                        ...newItem,
                    };
                } else {
                    items.push(newItem);
                }
            }

            $snackbarSuccess.text = $_("supplier_amendment_updated");
            $snackbarSuccess.element && $snackbarSuccess.element.open();
            unsavedRowsExists = false;
            apiCallProcessing = false;
        } else {
            apiUpdateSupplierAmend(data, type)
                .then((res) => {
                    console.log("apiUpdateSupplierAmend response: ", res);
                    $snackbarSuccess.text = $_("supplier_amendment_updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    unsavedRowsExists = false;
                    apiCallProcessing = false;

                    data.forEach((updatedItem) => {
                        const index = items.findIndex((item) => item.id === updatedItem.id);
                        console.log("Directly update the item in the array", updatedItem.id, " index: ", index);
                        if (index !== -1) {
                            items[index] = {
                                ...items[index],
                                supplierId: updatedItem.supplierId,
                                supplierDescription: updatedItem.supplierDescription,
                                confirmedVol: updatedItem.confirmedVol,
                                requestedVol: updatedItem.requestedVol,
                                supplierDate: updatedItem.supplierDate,
                                supplierComment: updatedItem.supplierComment,
                                timeLimit: updatedItem.timeLimit,
                                userComment: updatedItem.userComment,
                                status: updatedItem.status,
                                warnings: [],
                                ...(type === "apply" && { applyStatus: "applied" }),
                            };
                        }
                    });
                    screenMode = "edit";
                })
                .catch((error) => {
                    apiCallProcessing = false;
                    console.log("Update error: ", error);
                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    };

    async function fetchXlsx(uuid) {
        try {
            await downloadXlsxFile(uuid);
        } catch (error) {
            console.error("Error downloading XLSX:", error);
        }
    }

    const handleEvents = async (event) => {
        console.log("================= handleEvents ==============");
        console.log({ event });
        switch (event.type) {
            case "openFileDialogConfirmed":
                console.log("Event Import AMSupplier response");
                uploadAMSData(event.detail);
                break;
            case "history":
                console.log("Show item row history id: ", event.detail);
                const detailId = event.detail;
                getSAHistory(detailId);
                break;
            case "clearAllData":
                console.log("Event Clear All Imported Forecast");
                clearAllData();
                break;
            case "save_amendment":
                const saveUploadedRows = event.detail;
                console.log(`Event ${event.type} with rows:`, saveUploadedRows);
                updateData(saveUploadedRows, "save");
                break;
            case "next_round":
                const nextRoundRowsToProcess = event.detail;
                console.log(`Event ${event.type} with rows:`, nextRoundRowsToProcess);
                nextRound(nextRoundRowsToProcess);
                break;
            case "apply":
                const applyRowsToProcess = event.detail;
                const applyStatusUpdate = applyRowsToProcess.map((item) => ({
                    ...item,
                    applyStatus: "applied",
                }));
                console.log(`Event ${event.type} with rows:`, applyStatusUpdate);
                updateData(applyStatusUpdate, "apply");
                break;
            case "send":
                const sendRowsToProcess = event.detail;
                const sendStatusUpdate = sendRowsToProcess.map((item) => ({
                    ...item,
                    status: "sent",
                }));
                console.log(`Event ${event.type} with rows:`, sendStatusUpdate);
                updateSendStatus(sendStatusUpdate);
                break;
            case "deleteSelectedRows":
                console.log(`Event ${event.type} with rows:`, event.detail);
                showDeleteRowConfirmation(event.detail);
                break;
            case "deleteSinglRow":
                console.log(`Event ${event.type} with row:`, event.detail);
                showDeleteRowConfirmation(event.detail);
                break;
            case "downloadRow":
                console.log(`Event ${event.type} with row:`, event.detail);
                fetchXlsx(event.detail);
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };
</script>

<section class="main-data-view">
    <section class="w-100 header tescoblue-border-bottom-thin">
        <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1" justify="between">
            <WeekInfo disabled={screenMode == "upload"} on:weekChanged={weekDataInvalidated} on:weekStatusChanged={updatePrivileges} />
            <Flex direction="row" justify="between" class="gap-1 w-100">
                {#if screenMode != "upload"}
                    <GetDataButton
                        disabled={$selectedWeek.status != "purchased" && $selectedWeek.status != "closed"}
                        approvalNeeded={unsavedRowsExists}
                        approvalMessage={unsavedRowsExists ? $_("discard_unsaved_changes") : ""}
                        on:get={getAmData}
                    />
                {/if}
                <Flex direction="row" justify="end" class="w-100 gap-1">
                    {#if screenMode == "edit"}
                        <UploadButton bind:dialogOpen={showUploadDialog} disabled={!canUpdate} on:openFileDialogConfirmed={handleEvents} />
                        {#if isAdmin}
                            <UniversalConfirmButton
                                title="send"
                                {items}
                                selected={checkboxItems}
                                icon="mdiSendOutline"
                                allowedConditions={[{ status: "new", applyStatus: "not_applied" }]}
                                disabled={!canUpdate || items.length === 0 || screenMode != "edit"}
                                on:send={handleEvents}
                            />
                            <UniversalConfirmButton
                                title="apply"
                                {items}
                                selected={checkboxItems}
                                icon="mdiFileUploadOutline"
                                allowedConditions={[
                                    { status: "confirmed_less", applyStatus: "not_applied" },
                                    { status: "confirmed_all", applyStatus: "not_applied" },
                                ]}
                                disabled={!canUpdate || items.length === 0 || screenMode != "edit"}
                                on:apply={handleEvents}
                            />
                            <UniversalConfirmButton
                                title="next_round"
                                {items}
                                selected={checkboxItems}
                                icon="mdiPageNextOutline"
                                allowedConditions={[
                                    { status: "rejected", applyStatus: "not_applied" },
                                    { status: "not_responded", applyStatus: "not_applied" },
                                    { status: "confirmed_less", applyStatus: "applied" },
                                ]}
                                disabled={!canUpdate || items.length === 0 || screenMode != "edit"}
                                on:next_round={handleEvents}
                            />
                        {/if}
                    {/if}
                    {#if screenMode == "upload"}
                        <ClearButton title={$_("supplier_am")} on:clearAllData={handleEvents} />
                        <UniversalConfirmButton
                            title="save_amendment"
                            items={allValid}
                            selected={checkboxItemsValid}
                            icon="mdiFileUploadOutline"
                            allowedConditions={[
                                { status: "rejected", applyStatus: "not_applied" },
                                { status: "not_responded", applyStatus: "not_applied" },
                                { status: "confirmed_less", applyStatus: "not_applied" },
                                { status: "confirmed_all", applyStatus: "not_applied" },
                            ]}
                            disabled={!canInsert || allValid.length === 0}
                            on:save_amendment={handleEvents}
                        />
                    {/if}
                </Flex>
            </Flex>
        </Flex>
    </section>

    <section class="filters">
        <Paper class="grey lighten-3 w-100 bs-bb pb-1 pt-1">
            <div class="smui-paper__content bs-bb">
                <section class="w-100">
                    <AmFilterBar
                        bind:countrySearchArray
                        bind:selectedDC
                        bind:ceTpnSearch
                        bind:suppSearch
                        bind:ceDescSearch
                        bind:deliveryDateSearch
                        bind:statusSearchArray
                        bind:applyStatusSearchArray
                        bind:statusSearchErrorsArray
                        selectedWeek={$selectedWeek}
                        {screenMode}
                        {statusErrors}
                    />
                </section>
            </div>
        </Paper>
    </section>

    <section class="data tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb table-container">
                <AmDataTable
                    {items}
                    {isSupplier}
                    {screenMode}
                    {loadingData}
                    {dataValid}
                    {canUpdate}
                    bind:checkboxItems
                    countrySearchArray={countrySearchArray.map((x) => x.id.toUpperCase())}
                    {selectedDC}
                    {ceTpnSearch}
                    {suppSearch}
                    {ceDescSearch}
                    {deliveryDateSearch}
                    {statusSearchErrorsArray}
                    statusSearchArray={statusSearchArray.map((x) => x.id)}
                    applyStatusSearchArray={applyStatusSearchArray.map((x) => x.id)}
                    on:editRow={(e) => {
                        console.log("edit", { e });
                        editRow(e.detail);
                    }}
                    on:history={handleEvents}
                    on:deleteSelectedRows={handleEvents}
                    on:deleteSinglRow={handleEvents}
                    on:downloadRow={handleEvents}
                />
            </div>
        </Paper>
    </section>
</section>

<ConfirmationDialog
    open={showDetailDialog}
    title={detailDialogMode == "add" ? $_("add_item") : $_("edit_item")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    itemData={null}
    big={true}
    headerColors="tescoblue white-text"
    dataValid={rowDataValid}
    on:reject={() => {
        showDetailDialog = false;
        //revertChanges(rowData, detailDialogMode);
        suppliersData.length = 0;
    }}
    on:confirm={() => {
        showDetailDialog = false;
        updateData([rowData], detailDialogMode);
        suppliersData = [];
    }}
>
    <AmDetailDialog bind:rowData {suppliersData} {backupRowData} {isSupplier} />
</ConfirmationDialog>

<ConfirmationDialog
    open={showDeleteConfirmationDialog}
    title={$_("delete")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    itemData={confirmationDialogData}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showDeleteConfirmationDialog = false;
    }}
    on:confirm={deleteRows}
>
    {$_("do_you_want_delete_row")}
</ConfirmationDialog>

<FilesUploadDialog
    bind:filesUploadDialogOpen
    bind:filesUploadState
    {uploadFinishedCount}
    {filesUploadCount}
    {filesStatus}
    {validationFinishedCount}
    {items}
    on:clearAllData={handleEvents}
/>

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

<AmHistoryDialog
    open={showSADialog}
    {itemHistoryData}
    bind:loadingHistoryData
    on:closeHistoryDialog={() => {
        showSADialog = false;
    }}
    on:downloadRow={handleEvents}
/>

<style>
    section.header {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 100%;
    }
</style>
