<script>
    import { tick } from "svelte";

    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiFileExcel } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    export let disabled = false;
    export let hint = "";
    export let dialogOpen = false;

    let files = [];
    let el;

    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    const openFileDialog = async () => {
        await tick();
        document.getElementById("QSFileUploadInput")?.click();
    };

    $: if (dialogOpen) {
        openFileDialog();
        dialogOpen = false;
    }
</script>

<Flex direction="row" justify="start" class="gap-1" title={hint ? hint : $_("upload_from_excel")}>
    <Button
        data-qa="upload-excel-btn"
        class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
        {disabled}
        on:click={() => {
            document.getElementById("QSFileUploadInput").click();
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={mdiFileExcel} />
        </Icon>
        {$_("upload_from_excel")}
    </Button>
    <input
        id="QSFileUploadInput"
        type="file"
        bind:files
        accept=".xlsx, .xls"
        multiple
        style="display:none"
        on:change={() => {
            dispatch("openFileDialogConfirmed", files);
        }}
        on:click={(event) => {
            event.target.value = null;
        }}
    />
</Flex>

<style>
</style>
