<script lang="ts">
    import Paper from "@smui/paper";
    import IconButton, { Icon } from "@smui/icon-button";
    import Button, { Group, Label } from "@smui/button";
    import { mdiClose, mdiFilterVariant, mdiCloseCircleOutline, mdiCheckAll, mdiViewGrid } from "@mdi/js";

    import MenuSurface from "@smui/menu-surface";
    import FormField from "@smui/form-field";
    import Checkbox from "@smui/checkbox";
    import CircularProgress from "@smui/circular-progress";

    import Flex from "svelte-flex";

    import { _ } from "svelte-i18n";

    export let title = $_("filter");
    export let valuesArray = [];
    export let selection = [];
    export let size = 20;
    export let sortValues = true;
    export let loaded = true;

    let surface: MenuSurface;

    let filterValue = $_(title);

    $: if (selection || !selection) {
        if (Array.isArray(selection) && selection.length > 0) {
            if (typeof selection.at(0) === "object") {
                filterValue = selection
                    .sort((x, y) => (x.id > y.id ? 1 : -1))
                    .map((x) => x.label)
                    .join(", ");
            } else {
                filterValue = selection.sort((x, y) => (Number(x) > Number(y) ? 1 : -1)).join(", ");
            }
        } else {
            filterValue = $_(title);
        }

        // console.log("filterValue: ", filterValue);
    }

    // $: {
    //     console.log("MultiSelectFilterBar - ", {loaded}, {valuesArray}, {selection});
    // }

    const handleFilterClose = (e) => {
        if (e.detail.action === "accept") {
            console.log({ selection });
        }
    };
</script>

<div class="">
    <Paper class="filter-bar" elevation={0} style="width: {size}rem">
        <IconButton
            data-qa="multi-select-dropdown-btn"
            class="tescoblue white-text prefix tescoblue-border-thin"
            on:click={() => surface.setOpen(true)}
            size="button"
            title={$_("set_filter")}
        >
            <Icon class="pl-1" size="mini" tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiFilterVariant} />
            </Icon>
        </IconButton>
        <div
            tabindex="0"
            role="button"
            class="value pa-0 ma-0 tescoblue-border-thin {selection && selection.length > 0 ? 'black-text' : 'grey-text text-darken-2'} px-1"
            on:click={() => surface.setOpen(true)}
            on:keydown={() => surface.setOpen(true)}
            title="{title}: {filterValue}"
        >
            {filterValue}
        </div>
        <IconButton
            data-qa="multi-select-filter-close"
            class="tescoblue {filterValue === '' ? 'tescoblue-text' : 'white-text'}  suffix tescoblue-border-thin"
            on:click={() => (selection = [])}
            size="button"
            disabled={filterValue === ""}
            title={$_("clear_filter")}
        >
            <Icon class="pr-1" size="mini" tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiCloseCircleOutline} />
            </Icon>
        </IconButton>
    </Paper>
    <MenuSurface bind:this={surface} anchorCorner="TOP_LEFT" class="tescoblue-border-thin" style="min-width: {size}rem; overflow-y: hidden;">
        {#if !loaded}
            <CircularProgress style="height: 2rem; width: 10rem; margin: auto" indeterminate />
        {:else}
            <Flex direction="column" align="start">
                <div class="tescoblue white-text w-100 pa-2 bs-bb fs-1rem">
                    <Label>{title}</Label>
                </div>
                <Flex direction="column" align="start" class="pa-2 w-100 bs-bb">
                    <Flex directon="row" class="w-100 gap-1 pa-1 bs-bb white sticky-toolbar" justify="start">
                        <Button
                            data-qa="multi-select-filter-select-all"
                            class="small tescoblue-text outlined "
                            on:click={() => {
                                selection = [...valuesArray];
                            }}
                        >
                            <Icon tag="svg" viewBox="0 0 24 24" class="mr-1">
                                <path fill="currentColor" d={mdiCheckAll} />
                            </Icon>
                            {$_("all")}
                        </Button>
                        <Button
                            data-qa="multi-select-filter-select-none"
                            class="small tescoblue-text outlined "
                            on:click={() => {
                                selection = [];
                            }}
                        >
                            <Icon tag="svg" viewBox="0 0 24 24" class="mr-1">
                                <path fill="currentColor" d={mdiViewGrid} />
                            </Icon>
                            {$_("none")}
                        </Button>
                    </Flex>

                    <div style="overflow-y: auto; max-height: 300px;">
                        {#if typeof valuesArray.at(0) === "object"}
                            {#each sortValues ? valuesArray.sort((x, y) => (Number(x.id) > Number(y.id) ? 1 : -1)) : valuesArray as option (option.id)}
                                <FormField class="w-100 hover">
                                    <Checkbox data-qa={"multi-select-option-" + option.label} bind:group={selection} value={option} />
                                    <span slot="label">{option.label}</span>
                                </FormField>
                            {/each}
                        {:else}
                            {#each sortValues ? valuesArray.sort((x, y) => (x > y ? 1 : -1)) : valuesArray as option}
                                <FormField class="w-100 hover">
                                    <Checkbox data-qa={"multi-select-option-" + option} bind:group={selection} value={option} />
                                    <span slot="label">{option}</span>
                                </FormField>
                            {/each}
                        {/if}
                    </div>
                    <Flex direction="row" justify="end" class="w-100">
                        <Button
                            data-qa="multi-select-filter-close-btn"
                            title={$_("close")}
                            on:click={() => surface.setOpen(false)}
                            variant="raised"
                            class="tescoblue white-text h-100 pt-0 pb-0 pl-2 pr-2"
                        >
                            <Label class="font-weight-medium">
                                {$_("close")}
                            </Label>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        {/if}
    </MenuSurface>
</div>

<style>
    .value {
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 34px;
        line-height: 2rem;
        white-space: nowrap;
    }
</style>
