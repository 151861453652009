<script lang="ts">
  import CircularProgress from "@smui/circular-progress";
  import Tooltip, { Wrapper, Title, Content } from "@smui/tooltip";
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton, { Icon } from "@smui/icon-button";
  import { mdiPencilBoxOutline, mdiDelete, mdiAlert, mdiAlertCircle, mdiCheckboxOutline, mdiMinusBoxOutline, mdiCheckboxBlankOutline } from "@mdi/js";
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";

  import Checkbox from "@smui/checkbox";

  import type { ForecastItems } from "../../../types/Forecast";
  import { dcList } from "../../../stores/AppConfig";
  import { authenticatedUser } from "../../../stores/AppStatus";
  import { createEventDispatcher } from "svelte";
  import { nvl, getFieldErrMsg, existsError, existErrors } from "../Upload/Upload.js";

  // Items
  export let items: ForecastItems[] = [];
  let filteredItems: ForecastItems[] = [];
  export let checkboxItems = [];

  export let screenMode;

  // Filters
  export let selectedDC = [];
  export let tpnbSearch = "";
  export let ceTpnSearch = "";
  export let tpnbDescSearch = "";
  export let ceDescSearch = "";
  export let fileNameSearch = "";
  export let statusSearchArray = [];
  export let statusSearchErrorsArray = [];

  // Flags
  export let dataValid = false;
  export let loadingData = false;
  export let canUpdate = false;
  export let canDelete = false;
  export let dmlPossible = false;

  // Tooltip position fix
  let tooltipXpos;
  let tooltipYpos;

  const dispatch = createEventDispatcher();

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  const isReadOnly = $authenticatedUser.isReadOnly;
  let checkboxUncheck = false;

  $: selectableItems = filteredItems.filter((item) => item.status === "new" && canDelete);

  // Reactive statement to determine the state of the select all button
  $: selectAllState = (() => {
    if (checkboxItems.length === selectableItems.length && selectableItems.length > 0) {
      return "checked";
    } else if (checkboxItems.length === 0) {
      return "unchecked";
    } else {
      return "indeterminate";
    }
  })();

  function toggleSelectAll() {
    console.log("Selectable items:", selectableItems);

    if (selectableItems.length === 0) {
      console.log("No selectable items available.");
      return;
    }

    // Select or unselect items based on the current selection
    if (checkboxItems.length > 0) {
      console.log("Uncheck all");
      checkboxItems.length = 0; // Uncheck all
    } else {
      console.log("Check all");
      checkboxItems = selectableItems.map((item) => item.id); // Check all
    }

    console.log("Updated checkboxItems:", checkboxItems);
  }

  $: if (selectableItems.length === 0) {
    checkboxUncheck = false;
  }

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  $: {
    console.log("------FCDataTable-------");
    console.log({ items });
    console.log({ checkboxItems });
    console.log({ slice });
  }

  // Filter
  $: filteredItems = items?.filter((item) => {
    const ceTpnMatch = (item.productId ?? "").toString().includes(ceTpnSearch);
    const tpnbMatch = (item.tpnb ?? "").toString().includes(tpnbSearch);
    const productNameEnMatch = (item.descriptionEn ?? "").toLowerCase().includes(ceDescSearch?.toLowerCase() ?? "");
    const tpnbDescMatch = (item.description ?? "").toLowerCase().includes(tpnbDescSearch.toLowerCase() ?? "");
    const fileNameMatch = (item.fileName ?? "").toLowerCase().includes(fileNameSearch.toLowerCase() ?? "");

    // arrays
    const statusMatch = statusSearchArray.length > 0 ? statusSearchArray.some((unitId) => item.status?.includes(unitId)) : true;

    // Convert item.warehouseId to a DC code for comparison
    const itemDcCode = findDcCodeById(item.warehouseId);
    const dcMatch = selectedDC.length > 0 ? selectedDC.includes(itemDcCode) : true;

    // Check for any errors or warnings
    let errorsMatch = true;
    if (statusSearchErrorsArray.length > 0) {
      const errorsExist = item.errors?.some((errorItem) => statusSearchErrorsArray.some((searchError) => searchError.message === errorItem.message));
      const warningsExist = item.warnings?.some((warningItem) => statusSearchErrorsArray.some((searchError) => searchError.message === warningItem.message));
      errorsMatch = errorsExist || warningsExist;
    }

    // Check for valid type
    const includesValidType = statusSearchErrorsArray.some(({ type }) => type === "V");

    // Include items with no errors or warnings
    if (includesValidType) {
      errorsMatch = errorsMatch || ((!item.errors || item.errors.length === 0) && (!item.warnings || item.warnings.length === 0));
    }
    return ceTpnMatch && productNameEnMatch && tpnbMatch && tpnbDescMatch && statusMatch && dcMatch && errorsMatch && fileNameMatch;
  });

  // Function to find DC code by ID
  function findDcCodeById(dcId) {
    const dc = $dcList.find((d) => d.id === dcId);
    return dc ? dc.code : "Unknown";
  }

  // Define the default table head
  let defaultTableHead = [
    { columnId: "actions", label: "", numeric: false, extraClasses: "fc right-splitter" },
    { columnId: "error", label: "error", numeric: false, extraClasses: "right-splitter" },
    { columnId: "tpnEn", label: "tpn_en_tpnb", numeric: false, extraClasses: "" },
    { columnId: "productNameEn", label: "product_en_tpnb", numeric: false, extraClasses: "right-splitter" },
    { columnId: "dc", label: "dc", numeric: false, extraClasses: "right-splitter" },
    { columnId: "units", label: "units", numeric: false, extraClasses: "right-splitter" },
    { columnId: "flexVol", label: "forecast_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d1Vol", label: "d1_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d2Vol", label: "d2_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d3Vol", label: "d3_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d4Vol", label: "d4_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d5Vol", label: "d5_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d6Vol", label: "d6_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "d7Vol", label: "d7_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "totalVol", label: "total_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "note", label: "note", numeric: false, extraClasses: "right-splitter" },
    { columnId: "status", label: "status", numeric: false, extraClasses: "right-splitter" },
    { columnId: "reason", label: "reject_reason", numeric: false, extraClasses: "wrap-spaces right-splitter" },
    { columnId: "buyerComment", label: "buyer_comment", numeric: false, extraClasses: "wrap-spaces right-splitter" },
  ];

  // Columns to remove or add
  const columnsToRemove = ["actions", "error"];

  let tableHead = [...defaultTableHead];

  $: {
    // Rebuild tableHead and update tpnEn extraClasses
    tableHead = defaultTableHead.map((col) => {
      if (col.columnId === "tpnEn") {
        // Recalculate extraClasses
        return {
          ...col,
          extraClasses: "right-splitter",
        };
      }
      return col;
    });

    // Remove 'actions' and 'error' columns when read-only or DML is not possible
    if (isReadOnly || !dmlPossible) {
      console.log("remove actions and error", isReadOnly, dmlPossible, screenMode);
      tableHead = tableHead.filter((col) => !columnsToRemove.includes(col.columnId));
    }
  }
</script>

<DataTable stickyHeader table$aria-label="Forecast Table" class="report" style="width: 100%; max-height: calc(100vh - 330px);">
  <Head>
    <Row>
      {#each tableHead as head (head.columnId)}
        {#if head.columnId === "actions" && slice.length != 0}
          <Cell class="datatable-header {head.extraClasses}">
            <!-- IconButton acting as a Checkbox -->
            <IconButton size="mini" on:click={toggleSelectAll} disabled={selectableItems.length === 0 || (!canDelete && screenMode !== "upload")}>
              {#if selectAllState === "checked"}
                <Icon tag="svg" viewBox="0 0 24 24">
                  <path fill="" d={mdiCheckboxOutline} />
                </Icon>
              {:else if selectAllState === "indeterminate"}
                <Icon tag="svg" viewBox="0 0 24 24">
                  <path fill="" d={mdiMinusBoxOutline} />
                </Icon>
              {:else}
                <Icon tag="svg" viewBox="0 0 24 24">
                  <path fill="" d={mdiCheckboxBlankOutline} />
                </Icon>
              {/if}
            </IconButton>
            <!-- Spacer instead of edit -->
            <div class="spacer"></div>
            <!-- Delete Button -->
            <IconButton
              title={$_("delete_all")}
              size="button"
              on:click={() => checkboxItems.length > 0 && dispatch("deleteSelectedForecastRow", checkboxItems)}
              disabled={checkboxItems.length === 0 || (!canDelete && screenMode !== "upload")}
            >
              <Icon tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiDelete} />
              </Icon>
            </IconButton>
          </Cell>
        {:else if head.columnId !== "error" || screenMode === "upload"}
          <Cell numeric={head.numeric} columnId={head.columnId} class={`${slice.length > 0 ? `${head.extraClasses} datatable-header` : "datatable-header"}`}>
            <Label>{$_(head.label)}</Label>
          </Cell>
        {/if}
      {/each}
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice.length == 0}
      {#if !dataValid}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("load_week_data_or_import")}</td>
        </Row>
      {:else}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_for_week")}</td>
        </Row>
      {/if}
    {:else}
      {#each slice as item (item.id)}
        <Row>
          {#if !isReadOnly && dmlPossible}
            <Cell class="pl-0 pr-0 right-splitter-sub" checkbox>
              <Flex direction="row">
                <!-- Checkbox -->
                <Checkbox bind:group={checkboxItems} value={item.id} valueKey={item.id} disabled={item.status !== "new" || (!canDelete && screenMode !== "upload")} />
                <!-- Edit -->
                <IconButton
                  title={$_("edit")}
                  size="button"
                  on:click={() => dispatch("editForecastRow", item)}
                  disabled={existsError(item, "week", "E") || existsError(item, "tpnb", "E") || existsError(item, "productId", "E") || !canUpdate}
                >
                  <Icon
                    tag="svg"
                    viewBox="0 0 24 24"
                    class={existsError(item, "week", "E") || existsError(item, "tpnb", "E") || existsError(item, "productId", "E") || !canUpdate ? "" : "tescoblue-text"}
                  >
                    <path fill="currentColor" d={mdiPencilBoxOutline} />
                  </Icon>
                </IconButton>
                <!-- Delete -->
                <IconButton
                  title={$_("delete")}
                  size="button"
                  on:click={() => dispatch("deleteForecastRow", item.id)}
                  disabled={item.status !== "new" || (!canDelete && screenMode !== "upload")}
                >
                  <Icon tag="svg" viewBox="0 0 24 24" class={item.status !== "new" || (!canDelete && screenMode !== "upload") ? "" : "tescoblue-text"}>
                    <path fill="currentColor" d={mdiDelete} />
                  </Icon>
                </IconButton>
              </Flex>
            </Cell>
          {/if}
          {#if screenMode == "upload"}
            <!-- Errors, Warnings-->
            <Cell class="right-splitter-sub" style="overflow: visible">
              <Flex direction="row">
                {#if existErrors(item, "E")}
                  <Wrapper rich style="position: unset; !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      role="button"
                      tabindex="0"
                      aria-label="Show errors"
                      on:mouseenter={(event) => {
                        tooltipYpos = event.clientY;
                        tooltipXpos = event.clientX;
                      }}
                    >
                      <path fill="red" d={mdiAlertCircle} />
                    </svg>
                    <Tooltip
                      style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                      surface$style="max-width: max-content"
                    >
                      <Content style="max-width: fit-content;">
                        {@html item.errors ? item.errors.map((x) => $_(x.message)).join("<br>") : null}
                      </Content>
                    </Tooltip>
                  </Wrapper>
                {/if}
                {#if existErrors(item, "W")}
                  <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      role="button"
                      tabindex="0"
                      aria-label="Show warnings"
                      on:mouseenter={(event) => {
                        tooltipYpos = event.clientY;
                        tooltipXpos = event.clientX;
                      }}
                    >
                      <path fill="orange" d={mdiAlert} />
                    </svg>
                    <Tooltip
                      style="position: fixed !important; left: calc(var(--tooltip-left, 10rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                      surface$style="max-width: max-content"
                    >
                      <Content style="max-width: fit-content;">
                        {@html item.warnings ? item.warnings.map((x) => $_(x.message)).join("<br>") : null}
                      </Content>
                    </Tooltip>
                  </Wrapper>
                {/if}
              </Flex>
            </Cell>
          {/if}
          <!-- productId TPNB -->
          <Cell class="right-splitter">
            {#if screenMode == "upload" && (existsError(item, "productId", "E") || existsError(item, "tpnb", "E"))}
              <span class="red-text" title={$_(getFieldErrMsg(item, "productId", "E") || getFieldErrMsg(item, "tpnb", "E"))}>
                {item.productId ?? ""}<br />{item.tpnb ?? ""}
              </span>
            {:else}
              <span>
                {item.productId ?? ""}<br />{item.tpnb ?? ""}
              </span>
            {/if}
          </Cell>
          <Cell class="right-splitter-sub">{item.descriptionEn ?? ""}<br />{item.description ?? ""}</Cell>
          <Cell class="right-splitter-sub">{findDcCodeById(item.warehouseId)}</Cell>
          <!-- Units -->
          <Cell class="text-center right-splitter-sub">
            {#if screenMode == "upload" && existErrors(item, "E") && item.errors.map((x) => x.field).includes("units")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "units", "E"))}>{$_(nvl(item.units, ""))}</span>
            {:else}
              <span>{$_(nvl(item.units, ""))}</span>
            {/if}
          </Cell>
          <!-- Volume -->
          <!-- Flex -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "flexVol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "flexVol", "E"))}>{item.flexVol ?? ""}</span>
            {:else}
              <span>{item.flexVol ?? ""}</span>
            {/if}
          </Cell>
          <!-- d1Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d1Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d1Vol", "E"))}>{item.d1Vol ?? ""}</span>
            {:else}
              <span>{item.d1Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- Fld2Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d2Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d2Vol", "E"))}>{item.d2Vol ?? ""}</span>
            {:else}
              <span>{item.d2Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- d3Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d3Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d3Vol", "E"))}>{item.d3Vol ?? ""}</span>
            {:else}
              <span>{item.d3Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- d4Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d4Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d4Vol", "E"))}>{item.d4Vol ?? ""}</span>
            {:else}
              <span>{item.d4Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- d5Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d5Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d5Vol", "E"))}>{item.d5Vol ?? ""}</span>
            {:else}
              <span>{item.d5Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- d6Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d6Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d6Vol", "E"))}>{item.d6Vol ?? ""}</span>
            {:else}
              <span>{item.d6Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- d7Vol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "d7Vol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "d7Vol", "E"))}>{item.d7Vol ?? ""}</span>
            {:else}
              <span>{item.d7Vol ?? ""}</span>
            {/if}
          </Cell>
          <!-- totalVol -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "totalVol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "totalVol", "E"))}>{item.totalVol ?? ""}</span>
            {:else}
              <span>{item.totalVol ?? ""}</span>
            {/if}
          </Cell>
          <!-- note -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "note", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "note", "E"))}>{item.note ?? ""}</span>
            {:else}
              <span>{$_(nvl(item.note, ""))}</span>
            {/if}
          </Cell>
          <Cell class="right-splitter-sub">{$_(nvl(item.status, ""))}</Cell>
          <Cell class="right-splitter-sub">{$_(nvl(item.reasonCode, ""))}</Cell>
          <Cell class="right-splitter-sub">{item.userComment ?? ""}</Cell>
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}>last_page</IconButton>
  </Pagination>
</DataTable>

<style>
  .spacer {
    display: inline-block;
    width: 24px;
    height: 24px;
    opacity: 0;
  }
</style>
