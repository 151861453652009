import Api from "../services/Api";

// Method to get current List of Purchases
export const getPurchase = async (id, year, week, country, supplierId, productId, productDesc, countryOfOrigin, variety, units, forecastId) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (country) params.append('country', country);
    if (supplierId) params.append('supplierId', supplierId);
    if (productId) params.append('productId', productId);
    if (productDesc) params.append('productDesc', productDesc);
    if (countryOfOrigin) params.append('countryOfOrigin', countryOfOrigin);
    if (variety) params.append('variety', variety);
    if (units) params.append('units', units);
    if (forecastId) params.append('forecastId', forecastId);

    console.log('getPurchase params:', params.toString());

    const response = await Api.get("/purchase/", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

export const checkPurchaseItems = async (data) => {
  try {

    const response = await Api.post("/purchase/exist", data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Save Purchase and Forecast Changes
export async function savePurchase(fcData, mergeRows) {
  try {
    // Prepare payload with fcData and mergeRows
    const payload = {
      fcData,
      mergeRows
    };

    const response = await Api.post("/purchase/saveChanges", payload);

    if (response.status) {
      console.log("Purchase changes saved successfully:", response.payload);

      // Map response payload to include idXLS, rowId, type, and operation
      const mappedData = response.payload.map((item) => {
        let mappedItem = { ...item };

        // Include idXLS if provided in the original data
        if (mergeRows) {
          const matchingRow = mergeRows.find(row => row.rowId === item.rowId || row.id === item.rowId);
          if (matchingRow && matchingRow.idXLS) {
            mappedItem.idXLS = matchingRow.idXLS;
          }
        }

        // Use type and operation directly from response for consistency
        mappedItem.type = item.type;
        mappedItem.operation = item.operation;

        return mappedItem;
      });

      // Return response wrapped in a `data` key to match the expected output structure
      return {
        status: true,
        message: response.message || "",
        data: mappedData
      };
    } else {
      console.error("Failed to save purchase changes:", response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error("Error in savePurchaseChanges:", error);
    throw new Error(error.message, { cause: error });
  }
}

// Save Purchase and Forecast Changes
export async function savePurchaseNew(prepData) {
  try {
    // Send the new data structure
    const payload = { prepData };

    const response = await Api.post("/purchase/operations", payload);

    if (response.status) {
      console.log("Purchase changes saved successfully:", response.payload);

      // Process the payload / backend response format
      const mappedData = response.payload.map((item) => {
        let mappedItem = { ...item };
        return mappedItem;
      });

      return {
        status: true,
        message: response.message || "",
        data: mappedData
      };
    } else {
      console.error("Failed to save purchase changes:", response.payload || response.message);
      throw new Error(response.payload || response.message);
    }
  } catch (error) {
    console.error("Error in savePurchaseChanges:", error);
    throw new Error(error.message, { cause: error });
  }
}


// Create new Purchase
export const createPurchase = async (data) => {
  try {
    let url = Array.isArray(data) ? "/purchase/array" : "/purchase/";

    const response = await Api.post(url, data);
    console.log({ response });
    if (response.status) {
      if (data?.idXLS && data?.idXLS != null) {
        response.payload[0].idXLS = data?.idXLS;
      }
      return { ...response.payload[0], type: "purchase", operation: "create", rowId: data?.rowId };
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Update Purchase data
export const updatePurchase = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/purchase/", params, data);
    console.log({ response });
    if (response.status) {
      return { ...response.payload, type: "purchase", operation: "update", id: data?.id, rowId: data?.rowId };
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Update Purchase logistics data
export const updatePurchaseLogistics = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/purchase/logistics", params, data);
    console.log({ response });
    if (response.status) {
      return { ...response.payload, type: "purchase", operation: "update", id: data?.id, rowId: data?.rowId };
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Apply Purchase (Increase day amend value and status)
export const applyUpdatePurchase = async (data) => {
  try {
    const response = await Api.post("/purchase/apply", data);
    console.log({ response });

    if (response.status) {
      return { message: response.message, type: "purchase", operation: "apply" };
    } else {
      console.log(response ? response.message : response.message);
      throw new Error(response ? response.message : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};


// Confirm Forecast's Purchase with forecastId
export const confirmForecastPurchase = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('forecastId', data?.forecastId);

    const response = await Api.put("/purchase/confirm", params, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Delete Purchase
export const deletePurchase = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/purchase/", params);
    console.log({ response });

    if (response.status) {
      return { ...response.payload, operation: "delete", rowId: id };
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, { cause: error });
  }
};


// Perform Purchase Auotbuy
export const purchaseAutobuy = async (data) => {
  try {
    let url = "/purchase/autobuy";

    const response = await Api.post(url, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Get Hostoric Prices
export const getHistoricPrices = async (year, week, country, supplierId, productId) => {
  try {
    const params = new URLSearchParams();
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (country) params.append('country', country);
    if (supplierId) params.append('supplierId', supplierId);
    if (productId) params.append('productId', productId);

    console.log('getHistoricPrices params:', params.toString());

    const response = await Api.get("/purchase/historicPrices", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

export const adjustPurchaseLogistics = async (data) => {

  try {
    const params = new URLSearchParams();
    // params.append('forecastId', data?.forecastId);

    const response = await Api.put("/purchase/adjustment", params, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

