<script lang="ts">
    import Paper from "@smui/paper";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import ConfirmDeleteDialog from "../../../dialogs/ConfirmationDialog.svelte";
    import FilterProDataTable from "./FilterProDataTable.svelte";
    import ProductEdit from "./ProductEdit.svelte";
    import ProductListDataTable from "./ProductListDataTable.svelte";
    import {
        getProduct as apiGetProduct,
        updateProduct as apiUpdateProduct,
        getProductDetail as apiGetProductDetail,
        getProductSupplier as apiGetProductSupplier,
        deleteProduct as apiDeleteProduct,
    } from "../../../../api/Product";
    import { getSupplierLink as apiGetSupplierLink } from "../../../../api/Supplier.js";
    import { supplierLinkList } from "../../../../stores/Suppliers";
    import { snackbarSuccess } from "../../../../stores/AppStatus";
    import { handleApiError } from "../../lib/errorHandler";

    // Product list data
    let productList = [];
    let productDetailList = [];
    let productSupplierList = [];

    // Table msg
    let loaded = true;
    let tableSearchMessage = true;

    // Product Data
    let productData = {};

    // Screen variables
    let showDetails = false;
    let productIdToDelete = null;
    let showConfirmDeleteDialog = false;
    let confirmationDialogQuestion = "";
    let pageToShow = true;
    let editType = "add";
    let currentPage = 0;

    // $: {
    //     console.log({currentPage});
    // }

    const handleEvents = (event) => {
        console.log("handleEvents");
        console.log({ event });
        let data;
        switch (event.type) {
            case "loadProductList":
                loaded = false;
                data = event.detail;
                loadProducts(
                    data.id,
                    data.tpnb,
                    data.ean,
                    data.description,
                    data.buyer,
                    data.technicalManager,
                    data.country,
                    data.supplierName,
                    data.status,
                );
                currentPage = 0;
                break;
            case "editProduct":
                data = event.detail.row;
                editProduct(
                    data.id,
                    data.description,
                    data.cartonLogistics,
                    data.palletLogistics,
                    data.temperature,
                    data.units,
                    data.hierarchyId,
                    data.status,
                );
                break;
            case "createProduct":
                createProduct();
                break;
            case "confirmDeleteProduct":
                confirmDeleteProduct(event.detail.id);
                break;
            case "changeProductStatus":
                changeProductStatus(event.detail);
                break;
            case "clearProductList":
                productList = [];
                tableSearchMessage = true;
                console.log("clearProductList");
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    function createProduct() {
        console.log("createProduct");
        // Set Product variables
        productData.id = "";
        productData.description = null;
        productData.cartonLogistics = null;
        productData.palletLogistics = null;
        productData.temperature = null;
        productData.units = null;
        productData.hierarchyId = null;
        productData.status = true;
        productDetailList = [];
        productSupplierList = [];

        // Set screen
        editType = "add";
        pageToShow = false;
    }

    function editProduct(id, description, cartonLogistics, palletLogistics, temperature, units, hierarchyId, status) {
        console.log("editProduct: " + id);

        // Set Product variables
        productData.id = id;
        productData.description = description;
        productData.cartonLogistics = cartonLogistics;
        productData.palletLogistics = palletLogistics;
        productData.temperature = temperature;
        productData.units = units;
        productData.hierarchyId = hierarchyId;
        productData.status = status;
        loadProductDetails(id);
        loadProductSuppliers(id);

        // Set screen
        editType = "edit";
        pageToShow = false;
        console.log("editProduct", { productData });
    }

    function confirmDeleteProduct(id) {
        console.log("confirmDeleteProduct: " + id);
        confirmationDialogQuestion = $_("do_you_want_delete_product", { values: { id: id } });
        productIdToDelete = id;
        showConfirmDeleteDialog = true;
    }

    const loadProducts = (tpnEn, tpnb, ean, description, buyer, technicalManager, country, supplierName, status) => {
        console.log("Management loadProducts");

        apiGetProduct(tpnEn, tpnb, ean, description, buyer, technicalManager, country, supplierName, status, null)
            .then((res) => {
                console.log({ res });
                productList = res.map((product) => ({
                    ...product,
                    status: product.status == 1,
                }));

                // Check if supplierLinkList store already has data
                if ($supplierLinkList.length === 0) {
                    loadSupplierLinks(null, null, null, null, null, 1);
                } else {
                    console.log({$supplierLinkList});
                }

                if (productList.length === 0) {
                    console.log("loadProducts().empty");
                    tableSearchMessage = false;
                } else {
                    console.log({ productList });
                }

                loaded = true;
            })
            .catch((error) => {
                console.log(error);
                if (error?.cause?.response?.status == 401) {
                    throw error;
                }
                loaded = true;
            });
    };

    const loadProductDetails = (tpnEn) => {
        apiGetProductDetail(null, tpnEn, null, null, null, null, null, null, null, null, null, null, null)
            .then((res) => {
                console.log({ res });
                productDetailList = [];
                for (let productDetail of res) {
                    // console.log({productDetail});
                    productDetail.autobuy = productDetail.autobuy ? true : false;
                    productDetail.status = productDetail.status ? true : false;
                    productDetailList.push({ ...productDetail });
                }

                if (productDetailList.length == 0) {
                    console.log("loadProductDetails().empty");
                } else {
                    productDetailList = productDetailList;
                    console.log({ productDetailList });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error?.cause?.response?.status == 401) {
                    throw error;
                }
            });
    };

    const loadProductSuppliers = (tpnEn) => {
        console.log("Management loadProductSuppliers");
        apiGetProductSupplier(null, tpnEn, null, null, null, null, null, null, null)
            .then((res) => {
                console.log({ res });
                productSupplierList = [];
                for (let productSupplier of res) {
                    // console.log({productSupplier});
                    productSupplier.status = productSupplier.status ? true : false;
                    productSupplierList.push({ ...productSupplier });
                }

                if (productSupplierList.length == 0) {
                    console.log("loadProductSuppliers().empty");
                } else {
                    productSupplierList = productSupplierList;
                    console.log({ productSupplierList });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error?.cause?.response?.status == 401) {
                    throw error;
                }
            });
    };

    const loadSupplierLinks = (id, supplierId, country, rmsSupplierNo, rmsSupplierName, status) => {
        apiGetSupplierLink(id, supplierId, country, rmsSupplierNo, rmsSupplierName, status)
            .then((res) => {
                const updatedSupplierLinkList = res.map((supplierLink) => {
                    return {
                        ...supplierLink,
                        text: `${supplierLink.supplierId} - ${supplierLink.supplierName} (${supplierLink.rmsSupplierNo} ${supplierLink.rmsSupplierName})`,
                    };
                });

                // Update the store with new data
                supplierLinkList.set(updatedSupplierLinkList);

                if (updatedSupplierLinkList.length === 0) {
                    console.log("loadSupplierLinks().empty");
                } else {
                    console.log({ updatedSupplierLinkList });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error?.cause?.response?.status == 401) {
                    throw error;
                }
            });
    };

    const deleteProduct = (id) => {
        showConfirmDeleteDialog = false;
        apiDeleteProduct(id)
            .then((res) => {
                console.log({ res });
                productList = [...productList.filter((x) => x.id != id)];
                $snackbarSuccess.text = $_("product_deleted");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log({ error });
                if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    };

    function changeProductStatus(data) {
        var tmp = { ...data };
        var saveData = { ...data };

        // Update Product Detail List table
        var tmp = productList.filter((x) => x.id == data.id)[0];
        tmp.status = !data.status;
        saveData.status = data.status ? 1 : 0;
        apiUpdateProduct(saveData)
            .then((res) => {
                console.log({ res });
                // Update Product List table
                var tmp = productList.filter((x) => x.id == data.id)[0];
                productData = { ...data };
                tmp.cartonLogistics = data.cartonLogistics;
                tmp.description = data.description;
                tmp.palletLogistics = data.palletLogistics;
                tmp.status = data.status;
                tmp.hierarchyId = data.hierarchyId;
                tmp.temperature = data.temperature;
                tmp.units = data.units;
                productList = productList;
                $snackbarSuccess.text = $_("product_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }
</script>

{#if pageToShow}
    <section class="header w-100 top-splitter">
        <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3" justify="between">
            <Flex direction="row" class="gap-1 w-100">
                <div class="w-100 font-weight-bold">
                    {$_("products_management")}
                </div>

                <Flex direction="row" justify="end" class="w-100">
                    <Button title={$_("add_product")} class="tescoblue-text white-tescoblue-outlined pr-4 nowrap" on:click={() => createProduct()}>
                        <Icon class="material-icons">add_circle</Icon>
                        {$_("add_product")}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    </section>

    <section hidden={showDetails} class="filters tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb">
            <Flex direction="column" class="gap-1" align="stretch">
                <section class="w-100">
                    <FilterProDataTable on:loadProductList={handleEvents} on:clearProductList={handleEvents} />
                </section>
            </Flex>
        </Paper>
    </section>

    <section class="data w-100 tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb">
                <ProductListDataTable
                    bind:pageToShow
                    bind:currentPage
                    bind:productList
                    bind:loaded
                    bind:tableSearchMessage
                    on:editProduct={handleEvents}
                    on:confirmDeleteProduct={handleEvents}
                    on:changeProductStatus={handleEvents}
                />
            </div>
        </Paper>
    </section>
{:else}
    <ProductEdit bind:pageToShow {editType} {productData} bind:productList {productDetailList} {productSupplierList} />
{/if}

<!-- Confirm Delete Dialog -->
<ConfirmDeleteDialog
    open={showConfirmDeleteDialog}
    title={$_("delete")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showConfirmDeleteDialog = false;
    }}
    on:confirm={() => deleteProduct(productIdToDelete)}
>
    {confirmationDialogQuestion}
</ConfirmDeleteDialog>
