
import { selectedWeek } from "../../../stores/AppStatus";
import { getSupplier as apiGetSupplier } from "../../../api/Supplier";
import { get as getValue } from 'svelte/store';
import { _ } from 'svelte-i18n';
import { read as readXLSX, utils as XLSXUtils } from '@e965/xlsx';
import { weekStatuses, dcList, batchSize } from "../../../stores/AppConfig";

export let error = null;

let supplierList = [];

const allProgress = async (proms, progressCallBack) => {
	let d = 0;
	progressCallBack(0);
	for (const p of proms) {
		p.then((x) => {
			d++;
			progressCallBack(x.fileName, 'done');
		}).catch((x) => {
			d++;
			console.log("CATCH")
			console.log({ x });
			progressCallBack(x.at(0).fileName, 'error');
		});

	}
	return Promise.all(proms);
}

export const uploadFiles = async (files, type, callback) => {
	console.log('================= uploadFiles ==============');
	// console.log({files});
	// console.log({type});
	var wb, ws, fileHeader, fileId = 0, fileData = [], responses = [], errMap = new Map();

	if (files && files != null && files.length > 0) {
		let filesArray = [...files];
		supplierList = await apiGetSupplier(null, null, null, null);
		if (!(supplierList && Array.isArray(supplierList) && supplierList.length > 0)) {
			errMap.set(0, { 'row': 0, 'error': ('no_active_suppliers_found') });
		} else {
			supplierList = supplierList.map(s => s.id);
			console.log({supplierList});
		}
		while (filesArray.length) {
			const filePromises = filesArray.splice(0, batchSize).map((f) => {
				// Return a promise per file
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsArrayBuffer(f);
					reader.onload = async (e) => {
						console.log('Processing File: ' + f.name)
						fileId++;
						/* Parse data */
						const bstr = e.target.result;
						try {
							try {
								wb = await readXLSX(bstr, { type: 'array' });
							}
							catch (err) {
								console.log('Can\'t open selected XLS sheet. May be it is encrypted...')
								console.log(err);
								errMap.set(0, { 'row': 0, 'error': ('wrong_file') });
								callback(f.name, 'rejected', 'file_cant_be_open');
								resolve({
									fileId: fileId,
									fileName: f.name,
									supplierId: null,
									currency: {
										'Cz': null,
										'Sk': null,
										'Hu': null
									},
									week: null,
									errors: errMap,
									items: []
								});
							}
							if (wb) {
								// Process file upload
								ws = wb.Sheets[wb.SheetNames[0]];
								if (type == 'QS') {
									fileHeader = getQuoteSheetHeader(ws, fileId, f.name);
									console.log({ fileHeader });
									if (fileHeader.errors.size == 0) {
										fileData = getQuoteSheetRows(ws, fileId, f.name, callback);
										fileHeader.items = [...fileData];
										console.log("fileHeader.items: ", fileHeader.items);
										if (fileHeader.items.length == 0) {
											console.log("Empty file");
											errMap.set(0, { 'row': 0, 'error': ('empty_file') });
											fileHeader.errors = errMap;
											console.log({ fileHeader });
											callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
										}
									} else {
										callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
										// reject (new Error('file_contains_errors', {cause: fileHeader.errors.get(0)?.error}));
									}
									console.log({ fileHeader });
									resolve(fileHeader);
								} else if (type == 'FC') {
									fileHeader = getForecastHeader(ws, fileId, f.name);
									console.log({ fileHeader });
									if (fileHeader.errors.size == 0) {
										fileData = getForecastRows(ws, fileId, f.name, callback);
										fileHeader.items = [...fileData];
										console.log("fileHeader.items: ", fileHeader.items);
										if (fileHeader.items.length == 0) {
											console.log("Empty file");
											errMap.set(0, { 'row': 0, 'error': ('empty_file') });
											fileHeader.errors = errMap;
											console.log({ fileHeader });
											callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
										}
									} else {
										callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
									}
									console.log({ fileHeader });
									resolve(fileHeader);
								} else if (type == 'AC') {
									fileHeader = getAmendmentCountryHeader(ws, fileId, f.name);
									console.log({ fileHeader });
									if (fileHeader.errors.size == 0) {
										fileData = getAmendmentCountryRows(ws, fileId, f.name, callback);
										fileHeader.items = [...fileData];
										console.log("fileHeader.items: ", fileHeader.items);
										if (fileHeader.items.length == 0) {
											console.log("Empty file");
											errMap.set(0, { 'row': 0, 'error': ('empty_file') });
											fileHeader.errors = errMap;
											console.log({ fileHeader });
											callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
										}
									} else {
										callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
										// reject (new Error('file_contains_errors', {cause: fileHeader.errors.get(0)?.error}));
									}
									console.log({ fileHeader });
									resolve(fileHeader);
								} else if (type == 'AS') {
									fileHeader = getAmendmentSupplierHeader(ws, fileId, f.name);
									if (fileHeader.errors.size == 0) {
										fileData = getAmendmentSupplierRows(ws, fileId, f.name, callback);
										fileHeader.items = [...fileData];
										console.log("fileHeader.items: ", fileHeader.items);
										if (fileHeader.items.length == 0) {
											console.log("Empty file");
											errMap.set(0, { 'row': 0, 'error': ('empty_file') });
											fileHeader.errors = errMap;
											callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
										}
									} else {
										callback(f.name, 'rejected', fileHeader.errors.get(0)?.error)
									}
									resolve(fileHeader);
								}
								resolve(fileData);
							}

						} catch (error) {
							console.log({ error });
							callback(f.name, 'error')
							resolve(error);
						}
					}
				})
			});

			const batchResponses = await allProgress(filePromises, callback);
			responses = responses.concat(batchResponses);

		}

		// // Wait for all promises to be resolved
		// const responses = await Promise.all(filePromises);

		console.log({ responses });
		return responses;
	}
}

export function nvl(val, ret) {
	if (val && val != null) {
		return val
	} else {
		return (ret && ret != null) ? ret : '';
	}
}

export function updateErrors(row, prevRowErrors, prevRowWarnings, fields, errors) {
	//    console.log('================= updateError ==============');
	// console.log({...row});
	// console.log([...[prevRowErrors]]);
	// console.log([...[prevRowWarnings]]);
	// console.log([...[fields]]);
	// console.log([...[errors]]);
	row.errors = [...prevRowErrors];
	row.warnings = [...prevRowWarnings];
	if (row && row != null) {
		if (fields && Array.isArray(fields) && fields.length > 0) {
			for (let f of fields) {
				//console.log('Clearing errors on field: '+f);
				row = clearError(row, f);
			}
		} else {
			//console.log('Clearing errors on ALL fields');
			row.errors = [];
			row.warnings = [];
		}
		console.log('Row AFTER Clearing Errors');
		console.log({ ...row });

		if (errors && Array.isArray(errors) && errors.length > 0) {
			for (let e of errors) {
				if (e && e != null) {
					if (e.type == 'E') {
						row.errors.push(e);
					} else if (e.type == 'W') {
						row.warnings.push(e);
					}
				}
			}
		}
	}
	console.log({ ...row });
	return { ...row };
}

export function clearError(row, field) {
	//console.log('================= clearError ==============');
	//console.log('Field: '+field);
	if (existsError(row, field, 'E')) {
		//console.log('Errors BEFORE clear errors on field: '+field);
		//console.log([...row.errors]);
		row.errors.splice(row.errors.map(x => x.field).indexOf(field), 1);
		//console.log('Errors AFTER clear errors on field: '+field);
		//console.log([...row.errors]);
	}
	if (existsError(row, field, 'W')) {
		//console.log('Warnings BEFORE clear errors on field: '+field);
		//console.log([...row.warnings]);
		row.warnings.splice(row.warnings.map(x => x.field).indexOf(field), 1);
		//console.log('Warnings AFTER clear errors on field: '+field);
		//console.log([...row.warnings]);
	}
	return row;
}

export function getFieldErrMsg(row, field, type) {
	//console.log('================= getFieldErrMsg ==============');
	//console.log({row});
	//console.log({field});
	//console.log({type});
	var res = null;
	if (type == 'E') {
		res = (existsError(row, field, 'E')) ? [...row.errors].filter(x => x.field == field)[0].message : null;
	} else {
		res = (existsError(row, field, 'W')) ? [...row.warnings].filter(x => x.field == field)[0].message : null;
	}
	//console.log({res});
	return res;
}

export function existsError(row, field, type) {
	//console.log('================= existsError ==============');
	//console.log({row});
	//console.log({field});
	//console.log({type});
	var res = false;
	if (type == 'E' && row && Array.isArray(row.errors)) {
		res = row.errors.filter(error => error !== null).map(x => x.field).includes(field);
	} else if (type != 'E' && row && Array.isArray(row.warnings)) {
		res = row.warnings.filter(warning => warning !== null).map(x => x.field).includes(field);
	}
	return res;
}

export function existErrors(row, type) {
	//console.log('================= existErrors ==============');
	//console.log({row});
	//console.log({type});
	if (type == 'E') {
		return row && row != null && row.errors && Array.isArray(row.errors) && row?.errors?.length > 0;
	} else {
		return row && row != null && row.warnings && Array.isArray(row.warnings) && row.warnings.length > 0;
	}
}



function isUploadColSupplierId(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return null;
	}
	if (Number.isInteger(val)) {
		return val;
	} else {
		errMap.set(row, { 'row': row, 'error': ('wrong_supplier_format') });
		return null;
	}
}

function isUploadColWeek(ws, col, row, errMap) {
	var val;

	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v.toString().trim();
		if (val === "") {
			throw new Error('empty_string');
		}
	} catch (err) {
		const errorMsg = err.message === 'empty_string' ? 'wrong_week_format' : 'week_missing';
		errMap.set(0, { 'row': row, 'error': errorMsg });
		return;
	}

	// Try to convert to a number
	var numVal = Number(val);

	if (Number.isInteger(numVal)) {
		return numVal;
	} else {
		errMap.set(row, { 'row': row, 'error': 'wrong_week_format' });
	}
}


function isFieldEmpty(ws, col, row) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return true;
	}
	if (!val) {
		return true;
	}
	return false;
}

function isUploadColProductId(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return null;
	}
	if (Number.isInteger(Number(val))) {
		return val;
	} else {
		errMap.set(row, { 'row': row, 'error': ('wrong_product_id_format') });
		return null;
	}
}

function isUploadColPrice(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return null;
	}
	if (!ws[XLSXUtils.encode_cell({ c: col, r: row })]) { // When cell is empty ther return "0"
		return 0;
	} else if (Number(val) === val) {
		return val;
	} else {
		errMap.set(row, { 'row': row, 'error': ('wrong_price_format') });
		return val;
	}
}

function isUploadColCurrency(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return null;
	}
	if (["CZK", "EUR", "HUF"].includes(val.toUpperCase())) {
		return val.toUpperCase();
	} else {
		errMap.set(row, { 'row': row, 'error': ('wrong_currency_format') });
		return val;
	}
}


function isUploadColCountryOfOrigin(ws, col, row, errMap) {
    let val;
    try {
        val = ws[XLSXUtils.encode_cell({ c: col, r: row })]?.v;
    } catch (err) {
        return null;
    }

    if (val) {
        try {
            // Split, trim whitespace, filter out empty values, and convert to uppercase
            let values = val.split(',')
                .map((v) => v.trim())
                .filter((v) => v !== '') // Filter out empty strings
                .map((v) => v.toUpperCase());

            // If any code exceeds 2 characters, log an error
            const invalidValues = values.filter((v) => v.length > 2);
            if (invalidValues.length > 0) {
                errMap.set(row, {
                    row,
                    error: `invalid_country_code_length`,
                });
            }

            // Remove duplicates, sort, and join back
            values = Array.from(new Set(values)).sort().join(', ');

            return values;
        } catch (err) {
            // Unexpected format
            errMap.set(row, { row, error: 'wrong_country_format' });
            return null;
        }
    } else {
        errMap.set(row, { row, error: 'wrong_country_format' });
        return null;
    }
}

function isUploadColVariety(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return null;
	}
	if (val) {
        // Trim leading and trailing spaces
        return String(val).trim();
	} else {
		errMap.set(row, { 'row': row, 'error': ('wrong_variety_format') });
		return null;
	}
}

function isUploadColUnits(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v;
	} catch (err) {
		return null;
	}

	if (typeof val === 'string') {
		if (["KG", "PCS"].includes(val.toUpperCase())) {
			return val.toLowerCase();
		} else {
			errMap.set(row, { 'row': row, 'error': 'wrong_units_format' });
			return null;
		}
	} else {
		errMap.set(row, { 'row': row, 'error': 'units_must_be_string' });
		return null;
	}
}

function isUploadColLogistics(ws, col, row, errMap) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v
	}
	catch (err) {
		return null;
	}
	if (Number.isInteger(Number(val))) {
		return val;
	} else {
		errMap.set(row, { 'row': row, 'error': ('wrong_logistics_format') });
		return val;
	}
}

function isUploadNumCol(ws, col, row) {
	var cellAddress;
	try {
		cellAddress = XLSXUtils.encode_cell({ c: col, r: row });
	}
	catch (err) {
		return null;
	}

	var cell = ws[cellAddress];
	var val;

	// Check if cell exists
	if (!cell || cell.v === "") {
		return null;
	}

	val = cell.v;

	// Attempt to convert val to a Number
	var numVal = Number(val);

	if (!isNaN(numVal) && val !== "") {
		// If it was a string or legit number
		return Number(numVal.toFixed());
	}
	return null;
}

function isUploadCharCol(ws, col, row) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v.toString().trim();
	}
	catch (err) {
		return '';
	}
	if (val) {
		return String(val).toLowerCase()
	} else {
		return '';
	}
}

function isUploadStringCol(ws, col, row) {
	var val;
	try {
		val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v.toString().trim();
	}
	catch (err) {
		return '';
	}
	if (val) {
		return String(val);
	} else {
		return '';
	}
}

function isUploadColVolume(ws, col, row) {
	var cellAddress;
	try {
		cellAddress = XLSXUtils.encode_cell({ c: col, r: row });
	}
	catch (err) {
		return null;
	}

	var cell = ws[cellAddress];
	var val;

	// Check if cell exists
	if (!cell || cell.v === "") {
		return 0; // Return 0 when the cell is empty
	}

	val = cell.v;

	// Attempt to convert val to a Number
	var numVal = Number(val);

	if (!isNaN(numVal) && val !== "") {
		// If it was a string or legit number
		return Number(numVal.toFixed());
	}

	// Return a string (validation in ValidateX.js)
	return val;
}

function getStoreValue(store) {
	let value;
	const unsubscribe = store.subscribe((currentValue) => {
		value = currentValue;
	});
	unsubscribe();
	return value;
}

function isUploadColWarehouse(ws, col, row, errMap) {
	try {
		let val = ws[XLSXUtils.encode_cell({ c: col, r: row })].v;
		if (val) {
			const dcListValue = getStoreValue(dcList);
			const warehouse = dcListValue.find(dc => dc.code === val.toUpperCase());
			if (warehouse) {
				if (warehouse.live) {
					return warehouse.id;
				} else {
					errMap.set(0, { 'row': row, 'error': 'warehouse_is_inactive' });
				}
			} else {
				errMap.set(0, { 'row': row, 'error': 'wrong_warehouse_format' });
			}
		} else {
			errMap.set(0, { 'row': row, 'error': 'missing_warehouseId_id' });
		}
	} catch (err) {
		errMap.set(0, { 'row': row, 'error': 'Error reading warehouseId' });
	}
	return null;
}

function getCountry(id) {
	const dcListValue = getStoreValue(dcList);
	const warehouse = dcListValue.find((dc) => dc.id === id);
	if (warehouse) {
		return warehouse.country;
	} else {
		return null;
	}
}

function getQuoteSheetHeader(ws, fileId, fileName) {

	console.log('================= getQuoteSheetHeader ==============');
	console.log({ fileName });
	// console.log({fileId});
	// console.log({ws});

	var uploadErrMap = new Map(),
		firstRow = 4,
		qsLength = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow,
		supplierId = isUploadColSupplierId(ws, 4, 1, uploadErrMap), //ws['E2'].v,
		week = isUploadColWeek(ws, 2, 1, uploadErrMap), //ws['C2'].v,
		currencyCz = isUploadColCurrency(ws, 8, 2, uploadErrMap),
		currencySk = isUploadColCurrency(ws, 10, 2, uploadErrMap),
		currencyHu = isUploadColCurrency(ws, 12, 2, uploadErrMap),
		appWeek = getValue(selectedWeek),
		fileData = {
			fileId: fileId,
			fileName: fileName,
			supplierId: supplierId,
			currency: {
				'Cz': currencyCz,
				'Sk': currencySk,
				'Hu': currencyHu
			},
			week: week,
			errors: null,
			items: []
		};

	//console.log({supplierId});
	//console.log({week});
	//console.log({qsLength});

	if (qsLength <= 0) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('empty_file') });
	}

	if (supplierId == null) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('missing_suplier_id') });
	} else {
		if (!supplierList.includes(supplierId)) {
			uploadErrMap.set(0, { 'row': 0, 'error': ('invalid_or_inactive_supplier') });
		}
	}

	if (week == null) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('missing_week') });
	}

	if (week && week != appWeek.tesco_week) {
		console.log({ week }, { appWeek });
		uploadErrMap.set(0, { 'row': 0, 'error': ('wrong_week_number') });
	}

	if (!currencyCz && !currencySk && !currencyHu) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('none_currency_entered') });
	}

	fileData.errors = uploadErrMap;

	console.log({ fileData });
	return fileData;

}

function getForecastHeader(ws, fileId, fileName) {

	console.log('================= getForecastHeader ==============');
	console.log({ fileName });
	// console.log({ fileId });
	// console.log({ ws });

	var uploadErrMap = new Map(),

		warehouseId = isUploadColWarehouse(ws, 8, 1, uploadErrMap), //ws['E2'].v,
		week = isUploadColWeek(ws, 6, 1, uploadErrMap), //ws['G2'].v,

		firstRow = 4,
		fcLength = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow,
		appWeek = getValue(selectedWeek),
		fileData = {
			fileId: fileId,
			fileName: fileName,
			warehouseId: warehouseId,
			week: week,
			errors: null,
			items: []
		};

	//console.log({warehouseId});
	//console.log({week});
	//console.log({fcLength});

	if (fcLength <= 0) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('empty_file') });
	}

	if (week == null) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('missing_week') });
	}

	if (week && week != appWeek.tesco_week) {
		console.log({ week }, { appWeek });
		uploadErrMap.set(0, { 'row': 0, 'error': ('wrong_week_number') });
	}

	fileData.errors = uploadErrMap;

	//console.log({ fileData });
	return fileData;

}


function getAmendmentCountryHeader(ws, fileId, fileName) {

	console.log('================= getAmendmentCountryHeader ==============');
	console.log({ fileName });
	console.log({ fileId });
	console.log({ ws });

	let uploadErrMap = new Map(),
		firstRow = 2,
		emptyCount = 0,
		week = isUploadColWeek(ws, 1, 0, uploadErrMap),
		appWeek = getValue(selectedWeek),
		rowsToCheck = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow;

	console.log({ week });
	console.log({ rowsToCheck });
	console.log({ uploadErrMap });

	// Check all rows for Warehouse correctnes as in Validation it will be used to get country => data
	for (let i = firstRow; i <= rowsToCheck + firstRow - 1; i++) {
		if (isFieldEmpty(ws, 0, i)) {
			emptyCount++;
			if (emptyCount > 5) {
				break;
			}
		} else {
			emptyCount = 0; // Reset counter if a non-empty field is found
			let warehouseId = isUploadColWarehouse(ws, 0, i, uploadErrMap);
		}
	}

	if (rowsToCheck <= 0) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('empty_file') });
	}

	if (week && week != appWeek.tesco_week) {
		console.log({ week }, { appWeek });
		uploadErrMap.set(0, { 'row': 0, 'error': ('wrong_week_number') });
	}
	if (week == null) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('missing_week') });
	}

	console.log({ uploadErrMap });

	let headerData = {
		fileId: fileId,
		fileName: fileName,
		week: week,
		errors: uploadErrMap,
		items: []
	};

	console.log("Header:", { headerData });

	return headerData;

}


function getAmendmentSupplierHeader(ws, fileId, fileName) {

	console.log('================= getAmendmentSupplierHeader ==============');
	console.log({ fileName });
	console.log({ fileId });
	console.log({ ws });


	let uploadErrMap = new Map(),
		firstRow = 2,
		emptyCount = 0,
		supplierId = isUploadColSupplierId(ws, 3, 0, uploadErrMap),
		week = isUploadColWeek(ws, 1, 0, uploadErrMap),
		appWeek = getValue(selectedWeek),
		rowsToCheck = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow;

	console.log({ supplierId });
	console.log({ week });
	console.log({ rowsToCheck });
	console.log({ uploadErrMap });

	// Check all rows for Warehouse correctnes as in Validation it will be used to get country => data
	for (let i = firstRow; i <= rowsToCheck + firstRow - 1; i++) {
		if (isFieldEmpty(ws, 0, i)) {
			emptyCount++;
			if (emptyCount > 5) {
				break;
			}
		} else {
			emptyCount = 0; // Reset counter if a non-empty field is found
			let warehouseId = isUploadColWarehouse(ws, 0, i, uploadErrMap);
		}
	}

	if (rowsToCheck <= 0) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('empty_file') });
	}

	if (week && week != appWeek.tesco_week) {
		console.log({ week }, { appWeek });
		uploadErrMap.set(0, { 'row': 0, 'error': ('wrong_week_number') });
	}
	if (supplierId == null) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('missing_suplier_id') });
	}

	if (week == null) {
		uploadErrMap.set(0, { 'row': 0, 'error': ('missing_week') });
	}

	console.log({ uploadErrMap });

	let headerData = {
		fileId: fileId,
		fileName: fileName,
		supplierId: supplierId,
		week: week,
		errors: uploadErrMap,
		items: []
	};

	console.log("Header:", { headerData });

	return headerData;
}



function getQuoteSheetRows(ws, fileId, fileName) {
	console.log('================= getQuoteSheetRows ==============');
	console.log({ fileName });
	// console.log({fileId});
	// console.log({ws});

	let uploadErrMap = new Map(),
		firstRow = 4,
		qsLength = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow,
		supplierId = isUploadColSupplierId(ws, 4, 1, uploadErrMap), //ws['E2'].v,
		week = isUploadColWeek(ws, 2, 1, uploadErrMap), //ws['C2'].v,
		qsData = [],
		emptyCount = 0;

	console.log({ qsLength });
	// Reading QuoteSheet rows
	for (let i = firstRow; i <= qsLength + firstRow - 1; i++) {
		let row = {};
		// Break on empty row - do not read milion empty rows.
		if (isFieldEmpty(ws, 0, i,)) {
			emptyCount++;
			if (emptyCount > 5) {
				break
			}
		}
		row.productId = isUploadColProductId(ws, 0, i, uploadErrMap);
		if (row.productId && row.productId != null) {
			//console.log({row});
			row.id = fileId + '-' + i;
			row.fileRowNo = i;
			row.week = week;
			row.year = null;
			row.supplierId = supplierId;
			row.countryOfOrigin = isUploadColCountryOfOrigin(ws, 2, i, uploadErrMap);
			row.variety = isUploadColVariety(ws, 3, i, uploadErrMap);
			row.units = isUploadColUnits(ws, 4, i, uploadErrMap);
			row.cartonLogistics = isUploadColLogistics(ws, 5, i, uploadErrMap);
			row.palletLogistics = isUploadColLogistics(ws, 6, i, uploadErrMap);
			row.volume = isUploadColVolume(ws, 7, i, uploadErrMap);
			row.priceCz = isUploadColPrice(ws, 8, i, uploadErrMap);
			row.pricePromoCz = isUploadColPrice(ws, 9, i, uploadErrMap);
			row.priceSk = isUploadColPrice(ws, 10, i, uploadErrMap);
			row.pricePromoSk = isUploadColPrice(ws, 11, i, uploadErrMap);
			row.priceHu = isUploadColPrice(ws, 12, i, uploadErrMap);
			row.pricePromoHu = isUploadColPrice(ws, 13, i, uploadErrMap);
			row.currencyCz = isUploadColCurrency(ws, 8, 2, uploadErrMap);
			row.currencySk = isUploadColCurrency(ws, 10, 2, uploadErrMap);
			row.currencyHu = isUploadColCurrency(ws, 12, 2, uploadErrMap);
			row.fileName = fileName;
			row.errors = uploadErrMap;
			qsData.push(row);
		}

	}
	console.log({ qsData });
	return qsData;
}


function getForecastRows(ws, fileId, fileName) {
	console.log('================= getForecastRows ==============');
	console.log('Filename = ' + fileName);
	console.log({ fileId });
	console.log({ ws });

	var uploadErrMap = new Map(),
		firstRow = 4,
		fcLength = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow,
		warehouseId = isUploadColWarehouse(ws, 8, 1, uploadErrMap), //ws['E2'].v,
		week = isUploadColWeek(ws, 6, 1, uploadErrMap), //ws['G2'].v,
		fcData = [],
		emptyCount = 0;

	//console.log({ warehouseId });
	//console.log({week});
	//console.log({fcLength});
	error = null;

	if (warehouseId == null) {
		error = 'missing_warehouseId_id';
		throw new Error('missing_warehouseId_id', { cause: fileName });
	}
	if (week == null) {
		error = 'missing_week';
		throw new Error('missing_week', { cause: fileName });
	}
	if (fcLength <= 0) {
		error = 'empty_file';
		throw new Error('empty_file', { cause: fileName });
	}

	// Reading QuoteSheet rows
	for (let i = firstRow; i <= fcLength + firstRow - 1; i++) {
		let row = {};
		// Break on empty row - do not read milion empty rows.
		if (isFieldEmpty(ws, 2, i,)) {
			emptyCount++;
			if (emptyCount > 5) {
				break
			}
		}
		row.id = fileId + '-' + i;
		row.week = week;
		row.year = null;
		row.productId = isUploadNumCol(ws, 2, i);
		row.productDetailId = null;
		row.descriptionEn = null;
		row.tpnb = isUploadNumCol(ws, 6, i);
		row.description = null;
		row.buyer = null
		row.technicalManager = null
		row.country = getCountry(warehouseId);
		row.warehouseId = warehouseId;
		row.units = isUploadColUnits(ws, 8, i, uploadErrMap);
		row.flexVol = isUploadColVolume(ws, 9, i);
		row.d1Vol = isUploadColVolume(ws, 10, i);
		row.d2Vol = isUploadColVolume(ws, 11, i);
		row.d3Vol = isUploadColVolume(ws, 12, i);
		row.d4Vol = isUploadColVolume(ws, 13, i);
		row.d5Vol = isUploadColVolume(ws, 14, i);
		row.d6Vol = isUploadColVolume(ws, 15, i);
		row.d7Vol = isUploadColVolume(ws, 16, i);
		row.totalVol = isUploadColVolume(ws, 17, i);
		row.purchasedVol = null
		row.note = isUploadCharCol(ws, 18, i);
		row.status = "new"
		row.reasonCode = null
		row.userComment = null
		row.fileName = fileName;
		row.errors = [];
		row.warnings = [];
		fcData.push(row);
	}
	console.log({ fcData });
	return fcData;
}


function getAmendmentCountryRows(ws, fileId, fileName) {
    console.log('================= getAmendmentCountryRows ==============');
    console.log('Filename = ' + fileName);
    console.log({ws});
    console.log('Filename = ' + fileName);
	console.log({ fileId });
	console.log({ ws });

	var uploadErrMap = new Map(),
		firstRow = 2,
		acLength = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow,
		week = isUploadColWeek(ws, 1, 0, uploadErrMap), //ws['B1'].v,
		//supplierId = isUploadColSupplierId(ws, 3, 0, uploadErrMap), //ws['D1'].v,
		acData = [],
		emptyCount = 0,
		error = null;

	console.log({ acLength });
	console.log({ week });

	if (week == null) {
		error = 'missing_week';
		throw new Error('missing_week', { cause: fileName });
	}
	if (acLength <= 0) {
		error = 'empty_file';
		throw new Error('empty_file', { cause: fileName });
	}

	// Reading QuoteSheet rows
	for (let i = firstRow; i <= acLength + firstRow - 1; i++) {
		let row = {};
		// Break on empty row - do not read milion empty rows.
		if (isFieldEmpty(ws, 0, i)) {
			emptyCount++;
			if (emptyCount > 5) {
				break
			}
		} else {
			row.id = fileId + '-' + i;
			row.year = null;
			row.week = week;
			row.forecastId = null;
			row.warehouseId = isUploadColWarehouse(ws, 0, i, uploadErrMap);
			row.country = getCountry(row.warehouseId);
			row.productId = isUploadNumCol(ws, 1, i);
			row.tpnb = isUploadNumCol(ws, 2, i);
			row.tpnbDescription = null;
			row.productDetailId = null;
			row.productDescription = isUploadStringCol(ws, 3, i);;
			row.created = isUploadCharCol(ws, 4, i);
			row.deliveryDate = isUploadCharCol(ws, 5, i);
			row.units = null;
			row.originalVol = null;
			row.amendVol = isUploadColVolume(ws, 6, i);
			row.status = "new";
			row.countryComment = null;
			row.userComment = null;
			row.fileName = fileName;
			row.errors = [];
			row.warnings = [];
			acData.push(row);
		}
	}
	console.log({ acData });
	return acData;
}


function getAmendmentSupplierRows(ws, fileId, fileName) {
	console.log('================= getAmendmentSupplierRows ==============');
	console.log({ fileName });
	console.log({ fileId });
	console.log({ ws });

	var uploadErrMap = new Map(),
		firstRow = 2,
		amSupplierLength = XLSXUtils.decode_range(ws['!ref']).e.r + 1 - firstRow,
		supplierId = isUploadColSupplierId(ws, 3, 0, uploadErrMap), //ws['D1'].v,
		week = isUploadColWeek(ws, 1, 0, uploadErrMap), //ws['B1'].v,
		amSupplierData = [],
		emptyCount = 0;

	console.log({ amSupplierLength });
	console.log({ week });
	console.log({ supplierId });
	// Reading AmendmentSupplier rows
	for (let i = firstRow; i <= amSupplierLength + firstRow - 1; i++) {
		let row = {};
		// Break on empty row - do not read milion empty rows.
		if (isFieldEmpty(ws, 0, i,)) {
			emptyCount++;
			if (emptyCount > 5) {
				break
			}
		}
		row.id = fileId + '-' + i;
		row.fileRowNo = i;
		row.week = week;
		row.year = null;
		row.supplierId = supplierId;
		row.warehouseId = isUploadColWarehouse(ws, 0, i, uploadErrMap);
		row.country = getCountry(row.warehouseId);
		row.productId = isUploadNumCol(ws, 1, i);
		row.tpnb = isUploadNumCol(ws, 2, i);
		row.createdAmend = isUploadCharCol(ws, 4, i);
		row.deliveryDate = isUploadCharCol(ws, 5, i);
		row.requestedVol = isUploadColVolume(ws, 6, i);
		row.confirmedVol = isUploadColVolume(ws, 7, i);
		row.supplierComment = isUploadCharCol(ws, 8, i);
		row.applyStatus = 'not_applied';

		row.fileName = fileName;
		row.errors = uploadErrMap;
		amSupplierData.push(row);

	}
	console.log("amSupplierData", { amSupplierData });
	return amSupplierData;
}


function uploadAmendmentCountry(ws, fileId, fileName) {
	console.log('================= uploadAmendmentCountry ==============');
	console.log('Filename = ' + fileName);
	console.log({ ws });
	var qsData = [];
	return qsData;
}
