import Api from "../services/Api";

// Method to get list of Product Delegation
export const getProductDelegation = async (fromUser, toUser, productId, id, country, type, status) => {
    try {
      const params = new URLSearchParams();
      if (fromUser) params.append('fromUser', fromUser);
      if (toUser) params.append('toUser', toUser);
      if (productId) params.append('productId', productId);
      if (type) params.append('type', type);
      if (country) params.append('country', country);
      if (status) params.append('status', status);
      if (id) params.append('id', id);

      console.log('getProductDelegation params:', params.toString());

      const response = await Api.get("/delegate/product", params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Create new Product Delegation
export const createProductDelegation = async (data) => {
  try {
    const response = await Api.post("/delegate/product", data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Update/Revoke Product delegation
export const revokeProductDelegation = async (data) => {
    console.log('updateProduct');
    const status = { "status": 0};
    try {
      const params = new URLSearchParams();
      params.append('id', data?.id);

      const response = await Api.put("/delegate/product", params, status);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Delete Product
export const deleteProduct = async (id) => {
    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const response = await Api.delete("/product/", params);
      console.log({response});

      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.error(error);
      console.error(error.response.data.payload[0].includes('ORA-02292'));
      if (error.response.data.payload[0].includes('ORA-02292')) {console.error('CHLD RECORDS FOUND');}
      throw new Error(error.message, {cause: error});
    }
};

// Method to get list of ProductDetails
export const getProductDetail = async (id, productId, country, tpnb, ean, description, descriptionEn, buyer, technicalManager, supplierName, status, autobuy) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (productId) params.append('productId', productId);
    if (country) params.append('country', country);
    if (tpnb) params.append('tpnb', tpnb);
    if (ean) params.append('ean', ean);
    if (description) params.append('description', description);
    if (descriptionEn) params.append('description', descriptionEn);
    if (buyer) params.append('buyer', buyer);
    if (technicalManager) params.append('technicalManager', technicalManager);
    if (supplierName) params.append('supplierName', supplierName);
    if (status != null && ((status == 0) || (status == 1))) params.append('status', status);
    if (autobuy) params.append('status', autobuy);

    const response = await Api.get("/product/detail", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Create new ProductDetail
export const createProductDetail = async (data) => {
  try {
    const response = await Api.post("/product/detail", data);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Update ProductDetail data
export const updateProductDetail = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/product/detail", params, data);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Delete ProductDetail
export const deleteProductDetail = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/product/detail", params);
    console.log({response});

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, {cause: error});
  }
};

// Method to get list of Product Suppliers
export const getProductSupplier = async (id, productId, country, supplierId, supplierName, supplierLinkId, rmsSupplierNo, rmsSupplierName, status) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (productId) params.append('productId', productId);
    if (country) params.append('country', country);
    if (supplierId) params.append('supplierId', supplierId);
    if (supplierName) params.append('supplierName', supplierName);
    if (supplierLinkId) params.append('supplierLinkId', supplierLinkId);
    if (rmsSupplierNo) params.append('rmsSupplierNo', rmsSupplierNo);
    if (rmsSupplierName) params.append('rmsSupplierName', rmsSupplierName);
    if (status != null && ((status == 0) || (status == 1))) params.append('status', status);

    const response = await Api.get("/product/supplier", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Create new ProductSupplier
export const createProductSupplier = async (data) => {
  try {
    const response = await Api.post("/product/supplier", data);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Update ProductSupplier data
export const updateProductSupplier = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/product/supplier", params, data);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Delete ProductSupplier
export const deleteProductSupplier = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/product/supplier", params);
    console.log({response});

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, {cause: error});
  }
};


// Method to get list of ProductDetails
export const getProductHierarchy = async (id, divCode, catCode, secCode, clsCode) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (divCode) params.append('divCode', divCode);
    if (catCode) params.append('catCode', catCode);
    if (secCode) params.append('secCode', secCode);
    if (clsCode) params.append('clsCode', clsCode);

    const response = await Api.get("/product/hierarchy", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Method to get list of User Delegation
export const getUserDelegation = async (fromUser, toUser, dateFrom, dateTo) => {
  try {
    const params = new URLSearchParams();
    if (fromUser) params.append('fromUser', fromUser);
    if (toUser) params.append('toUser', toUser);
    if (dateFrom) params.append('dateFrom', dateFrom);
    if (dateTo) params.append('dateTo', dateTo);

    console.log('getUserDelegation params:', params.toString());
    
    const response = await Api.get("/delegate/user", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

// Create new Product Delegation
export const createUserDelegation = async (data) => {
  try {
    const response = await Api.post("/delegate/user", data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Delete deleteUserDelegation
export const deleteUserDelegation = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/delegate/user", params);
    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to update User Delegation
export const updateUserDelegation = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    console.log('getUserDelegation params:', params.toString());

    const response = await Api.put("/delegate/user", params, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};