<script lang="ts">
    import { fullDcList } from "../../../stores/AppConfig";

    import Paper from "@smui/paper";
    import IconButton, { Icon } from "@smui/icon-button";
    import { Label } from "@smui/button";
    import { mdiFilterVariant, mdiCloseCircleOutline } from "@mdi/js";
    import MenuSurface from "@smui/menu-surface";
    import FormField from "@smui/form-field";
    import Checkbox from "@smui/checkbox";
    import Select, { Option } from "@smui/select";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    export let title = $_("filter");
    export let selection = [];
    export let countrySelection = [];
    export let size = 20;
    export let countryFilter = [];
    export let multiselect = false;

    let surface: MenuSurface;

    let filterValue;

    const markFullySelectedCountries = () => {
        {
            // Add country when all dc's selected
            $fullDcList
                .filter((c) => c.type == "COUNTRY")
                .forEach((c) => {
                    let countryDcCount = $fullDcList.filter((x) => x.type == "LOCAL" && x.country == c.country).length;
                    let selectedCount = selection.filter((x) => x.country == c.country).length;
                    console.log("counts", countryDcCount, selectedCount);
                    if (!countrySelection.map((x) => x.country).includes(c.country)) {
                        if (countryDcCount == selectedCount) {
                            // Add country
                            countrySelection.push($fullDcList.filter((x) => x.type == "COUNTRY" && x.country == c.country)?.at(0));
                            countrySelection = countrySelection;
                            console.log({ countrySelection });
                        }
                    } else {
                        if (countryDcCount != selectedCount) {
                            // Remove country
                            countrySelection = [...countrySelection.filter((x) => x.country != c.country)];
                            console.log({ countrySelection });
                        }
                    }
                });
        }
    };

    $: if (selection || !selection) {
        console.log({ selection });
        if (Array.isArray(selection) && selection.length > 0) {
            filterValue = selection
                .sort((x, y) => (x.id > y.id ? 1 : -1))
                .map((x) => x.code)
                .join(", ");
            if (surface && !surface.isOpen()) {
                markFullySelectedCountries();
            }
        } else {
            filterValue = null;
        }

        // console.log("filterValue: ", filterValue);
    }

    $: {
        console.log("==== FullDCFilterBar ====");
        console.log({ countryFilter });
        console.log({ selection });
        console.log({ countrySelection });
        console.log({ filterValue });
        console.log({ surface });
        // console.log(surface.isOpen());
    }
</script>

<div class="">
    {#if !multiselect}
        <div class="select-cell">
            <Select
                variant="outlined"
                bind:value={selection}
                data-qa="full-dc-select"
                class="white tescoblue-text small custom-icon with-parameter w-inherit"
                key={(option) => `${option ? option.id : ""}`}
                label={title}
                withLeadingIcon={selection}
                menu$class="mdc-menu-surface--fixed with-parameter__menu"
            >
                <Option value="" />
                {#key selection}
                    <!--force list recreation to properly set default option-->
                    {#each $fullDcList as option}
                        {#if countryFilter.length == 0 || countryFilter.includes(option.country)}
                            <Option value={option.id}><span class="pl-4">{option.name}</span></Option>
                        {/if}
                    {/each}
                {/key}
            </Select>
        </div>
    {:else}
        <!--Multiselect-->
        <Paper class="filter-bar" elevation={0} style="width: {size}rem">
            <IconButton
                data-qa="full-dc-multiselect-set-filter"
                class="tescoblue white-text prefix tescoblue-border-thin"
                on:click={() => surface.setOpen(true)}
                size="button"
                title={$_("set_filter")}
            >
                <Icon tag="svg" viewBox="0 0 24 24" class="pl-1" size="mini">
                    <path fill="currentColor" d={mdiFilterVariant} />
                </Icon>
            </IconButton>
            <div
                class="value pa-0 ma-0 tescoblue-border-thin {selection && selection.length > 0 ? 'black-text' : 'grey-text text-darken-2'} px-1"
                role="button"
                tabindex="0"
                on:click={() => surface.setOpen(true)}
                on:keydown={(e) => e.key === "Enter" && surface.setOpen(true)}
                title={filterValue ? [title, filterValue].join(": ") : title}
            >
                {filterValue ? filterValue : title}
            </div>
            <IconButton
                data-qa="full-dc-multiselect-clear-filter"
                class="tescoblue {filterValue === '' ? 'tescoblue-text' : 'white-text'}  suffix tescoblue-border-thin"
                on:click={() => {
                    countrySelection = [];
                    selection = [];
                }}
                size="button"
                disabled={filterValue === ""}
                title={$_("clear_filter")}
            >
                <Icon tag="svg" viewBox="0 0 24 24" class="pr-1" size="mini">
                    <path fill="currentColor" d={mdiCloseCircleOutline} />
                </Icon>
            </IconButton>
        </Paper>
        <MenuSurface bind:this={surface} anchorCorner="TOP_LEFT" class="tescoblue-border-thin" style="min-width: {size}rem">
            <Flex direction="column" align="start">
                <div class="tescoblue white-text w-100 pa-2 bs-bb fs-1rem">
                    <Label>{title}</Label>
                </div>
                <Flex direction="column" align="start" class="pa-2 w-100 bs-bb">
                    {#each $fullDcList as option (option.id)}
                        {#if countryFilter.length == 0 || countryFilter.includes(option.country)}
                            {#if option.type != "LOCAL"}
                                <FormField class="w-100 hover">
                                    <Checkbox
                                        data-qa="full-dc-multiselect-local-checkbox"
                                        bind:group={countrySelection}
                                        value={option}
                                        on:change={(e) => {
                                            console.log("CB-COUNTRY", { e });
                                            console.log("CB-COUNTRY", { selection });
                                            console.log("CB-COUNTRY", { countrySelection });
                                            console.log("CB-COUNTRY", { option });
                                            // Select all DCs in country
                                            if (countrySelection.map((x) => x.id).includes(option.id)) {
                                                selection = [
                                                    ...selection.filter((x) => x.type == "LOCAL" && x.country != option.country),
                                                    ...$fullDcList.filter((x) => x.type == "LOCAL" && x.country == option.country),
                                                ];
                                            } else {
                                                // Unselect all DCs in country
                                                selection = [...selection.filter((x) => x.type == "LOCAL" && x.country != option.country)];
                                            }
                                        }}
                                    />
                                    <span class="font-weight-bold tescoblue-text">{option.name} </span></FormField
                                >
                            {:else}
                                <FormField class="w-100 hover">
                                    <Checkbox
                                        data-qa="full-dc-multiselect-checkbox"
                                        bind:group={selection}
                                        value={option}
                                        class="ml-4"
                                        on:change={(e) => {
                                            console.log("CB-DC", { e });
                                            console.log("CB-DC", { selection });
                                            console.log("CB-DC", { countrySelection });
                                            console.log("CB-DC", { option });

                                            // Remove country from selection when dc was unselected
                                            if (!selection.map((x) => x.id).includes(option.id)) {
                                                countrySelection = [...countrySelection.filter((x) => x.country != option.country)];
                                            } else {
                                                // Add country when all dc's selected
                                                let countryDcCount = $fullDcList.filter((x) => x.type == "LOCAL" && x.country == option.country).length;
                                                let selectedCount = selection.filter((x) => x.country == option.country).length;
                                                console.log("counts", countryDcCount, selectedCount);
                                                if (countryDcCount == selectedCount) {
                                                    countrySelection.push($fullDcList.filter((x) => x.type == "COUNTRY" && x.country == option.country)?.at(0));
                                                    countrySelection = countrySelection;
                                                    console.log({ countrySelection });
                                                }
                                            }
                                        }}
                                    />
                                    <span class="">{option.code} - {option.name} </span></FormField
                                >
                            {/if}
                        {/if}
                    {/each}
                </Flex>
            </Flex>
        </MenuSurface>
    {/if}
</div>

<style>
    .value {
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 34px;
        line-height: 2rem;
        white-space: nowrap;
    }
</style>
