<script lang="ts">
  import { getProduct as apiGetProduct, updateProductLogistics as apiUpdateProductLogistics } from "../../../api/Product";
  import { createAutobuy as apiCreateAutobuy, updateAutobuy as apiUpdateAutobuy, deleteAutobuy as apiDeleteAutobuy } from "../../../api/Autobuy";
  import { updateQuoteSheet as apiUpdateQuoteSheet } from "../../../api/QuoteSheet";
  import { updatePurchaseLogistics as apiUpdatePurchaseLogistics, adjustPurchaseLogistics as apiAdjustPurchaseLogistics } from "../../../api/Purchase";
  import { snackbarSuccess, exchangeRates, selectedWeek } from "../../../stores/AppStatus";
  import { countries, unitList, deliveryTypes, dcList, appParameters } from "../../../stores/AppConfig";
  import { handleApiError } from "../lib/errorHandler";
  import { formatVolume } from "../lib/Functions";

  import PSQuoteSheetsTable from "./PSQuoteSheetsTable.svelte";
  import PurchaseRow from "./PSPurchasePopupPurchaseRow.svelte";
  import PSPurchaseFCStatusPopup from "./PSPurchaseFCStatusPopup.svelte";
  import PSSaveAutofillParticipationPopup from "./PSSaveAutofillParticipationPopup.svelte";
  // Edit item dialog
  import PSQuoteSheetsRowEditPopup from "./PSQuoteSheetsRowEditPopup.svelte";

  import InfoDialog from "../../dialogs/InfoDialog.svelte";

  import Dialog, { Title, Content } from "@smui/dialog";
  import Button from "@smui/button";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import FormField from "@smui/form-field";
  import Textfield from "@smui/textfield";
  import { Label } from "@smui/common";
  import Radio from "@smui/radio";
  import FullscreenLoader from "../../elementary/FullscreenLoader.svelte";

  import { _ } from "svelte-i18n";
  import Flex from "svelte-flex";

  import { createEventDispatcher } from "svelte";
  import { mdiCartArrowDown, mdiConsoleLine, mdiConsoleNetworkOutline } from "@mdi/js";
  import { Icon } from "@smui/icon-button";

  // Base variables
  export let open;
  export let type;
  export let scope;
  export let data;
  export let title;
  export let headerColors = "tescoblue white-text";
  export let qsRows;
  export let mergeRows;
  export let newFCStatus;
  export let selectedPurchaseDiffReason;
  export let purchaseDiffComment = "";
  export let autofillData = [];

  let initialized = false;
  let big = true;

  // Variables to save
  let selectedDC = {};
  let prevSelectedDC;
  let selectedCountry = {};
  let selectedUnits;
  let selectedDeliveryType;
  let cartonLogistics;
  let palletLogistics;
  let participation;
  let regularPrice;
  let regularPriceEur;
  let promoPrice;
  let promoPriceEur;
  let remainingVolume;
  let originalRemainingVolume;
  let d1Vol;
  let d2Vol;
  let d3Vol;
  let d4Vol;
  let d5Vol;
  let d6Vol;
  let d7Vol;
  let flexVol;
  let totalVol;
  let productData;
  // let productDetailData;

  let supplierList = [];
  let bckMergeRows = [];
  let bckSplitRows = [];
  let workQsRows = [];
  let fcRow = {};

  let diffLogDialogOpen = false;
  let diffLogisticsMessage = "";

  let displayUnits = [
    { id: 0, name: "kg", disp_name: $_("kg") },
    { id: 1, name: "cases", disp_name: $_("cases") },
    { id: 2, name: "pallets", disp_name: $_("pallets") },
  ];

  let purchaseForecastRow = {
    d1: 0,
    d2: 0,
    d3: 0,
    d4: 0,
    d5: 0,
    d6: 0,
    d7: 0,
    flex: 0,
    total: 0,
  };
  let purchaseAlreadyRow = {
    d1: 0,
    d2: 0,
    d3: 0,
    d4: 0,
    d5: 0,
    d6: 0,
    d7: 0,
    flex: 0,
    total: 0,
  };
  let diff = {
    d1: 0,
    d2: 0,
    d3: 0,
    d4: 0,
    d5: 0,
    d6: 0,
    d7: 0,
    flex: 0,
    total: 0,
  };
  let item = {
    d1: 0,
    d2: 0,
    d3: 0,
    d4: 0,
    d5: 0,
    d6: 0,
    d7: 0,
    flex: 0,
    total: 0,
  };
  let selectedDisplayUnit = "kg";
  let prevSelectedDisplayUnit = "kg";
  let unitConversionRatio = 1;
  let purchasedSuppliers = [];
  let proposedPurchaseVolume = [];

  let enableSaveButton = false;
  let enableSaveAutofillButton = false;
  let fcReasonDialog = false;
  let autofillBtnDisabled = false;
  let autofillFunctionDisabled = false;
  let showAutofillSaveConfirmationDialogOpen = false;

  let qsEditDialogOpen = false;
  let qsEditRow = {};

  let prevPurchBlockHeader = "xxxxx";

  let scopeData = {};

  // Loader...
  let apiCallProcessing = false;
  let apiCallProcessingMessage = "";

  const dispatch = createEventDispatcher();

  $: rowDataInvalid = mergeRows
    .filter((r) => (scope == "dc" ? r.warehouseId == selectedDC?.id : r.country == selectedCountry?.id?.toUpperCase()) && r.rowType == "PURCHASE")
    .map((x) => x.rowState)
    .includes("invalid");

  const resetPopup = () => {
    console.log("--> resetPopup() <--");
    initialized = false;
    prevSelectedDC = null;
    selectedDC = {};
    selectedCountry = {};
    selectedDeliveryType = {};
    scopeData = {};
    purchaseForecastRow = {
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      d5: 0,
      d6: 0,
      d7: 0,
      flex: 0,
      total: 0,
    };
    purchaseAlreadyRow = {
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      d5: 0,
      d6: 0,
      d7: 0,
      flex: 0,
      total: 0,
    };
    diff = {
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      d5: 0,
      d6: 0,
      d7: 0,
      flex: 0,
      total: 0,
    };
    item = {
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      d5: 0,
      d6: 0,
      d7: 0,
      flex: 0,
      total: 0,
    };
    selectedDisplayUnit = "kg";
    prevSelectedDisplayUnit = "kg";
    purchasedSuppliers = [];
    proposedPurchaseVolume = [];
    workQsRows = [];
    enableSaveButton = false;
  };

  const revertChanges = () => {
    console.log("--> revertChanges() <--");
    let ret = [];
    mergeRows
      .filter((x) => x.rowType != "PURCHASE")
      .forEach((r) => {
        ret.push(r);
        if (r.rowType == "FORECAST") {
          bckMergeRows
            .filter((b) => b.forecastId == r.forecastId)
            .sort((a, b) => (a.supplierId > b.supplierId ? 1 : -1))
            .forEach((p) => ret.push(p));
        }
      });

    mergeRows = [...ret];
  };

  const differentPurchaseLogistics = (logisticsParam, fc, purchase) => {
    console.log("differentPurchaseLogistics()", { logisticsParam }, { fc }, { purchase });
    if (
      purchase?.cartonLogistics &&
      purchase?.palletLogistics &&
      ((["ALL", "CART"].includes(logisticsParam) && fc.cartonLogistics != purchase?.cartonLogistics) ||
        (["ALL", "PAL"].includes(logisticsParam) && fc.palletLogistics != purchase?.palletLogistics))
    ) {
      return true;
    }
    return false;
  };

  const getFcRow = () => {
    let fcRow = { forecastId: [] };
    if (scope == "dc") {
      fcRow = {
        ...mergeRows.filter((x) => x.rowType == "FORECAST" && x.warehouseId == selectedDC.id).at(0),
      };
      fcRow.forecastId = [fcRow.forecastId];
    } else {
      fcRow = {
        forecastId: [],
        canBuy: 0,
        d1Vol: 0,
        d2Vol: 0,
        d3Vol: 0,
        d4Vol: 0,
        d5Vol: 0,
        d6Vol: 0,
        d7Vol: 0,
        flexVol: 0,
        totalVol: 0,
        purchasedVol: 0,
      };
      mergeRows
        .filter((x) => x.rowType == "FORECAST" && x.country == selectedCountry.id.toUpperCase())
        .forEach((f) => {
          fcRow.forecastId.push(f.forecastId);
          fcRow.canBuy = f.canBuy ? f.canBuy : fc.canBuy;
          fcRow.d1Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.d2Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.d3Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.d4Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.d5Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.d6Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.d7Vol = fcRow.d1Vol + (f?.d1Vol || 0);
          fcRow.flexVol = fcRow.flexVol + (f?.flexVol || 0);
          fcRow.totalVol = fcRow.totalVol + (f?.totalVol || 0);
          fcRow.purchasedVol = fcRow.purchasedVol + (f?.purchasedVol || 0);
        });
      console.log({ fcRow });
    }
    return fcRow;
  };

  const handlePurchaseEvents = (event) => {
    console.log("======================= handlePurchaseEvents() =======================", scope, event.detail.purchaseType);
    let newPurchaseRows = [];
    let fcRow = getFcRow();

    let purchasedVol = mergeRows
      .filter((x) => x.rowType == "PURCHASE" && x.productId == data.productId && fcRow.forecastId.includes(x.forecastId))
      .reduce((total, val) => (total += val.totalVol), 0);
    let firstPurchaseRow = mergeRows.filter((x) => x.rowType == "PURCHASE" && x.country == selectedDC.country && x.productId == data.productId).at(0);
    let qsRowsCount = qsRows.filter((x) => x.country == selectedDC.country).length;
    let mode = "add";
    let logisticsParam = $appParameters["logistics_equal_" + selectedDC.country?.toLowerCase()]?.value;

    console.log("purchasedVol:", purchasedVol);
    console.log({ firstPurchaseRow });

    // Clicked to basket icon on QS row
    if (event.detail.purchaseType == "row") {
      if (!differentPurchaseLogistics(logisticsParam, event.detail, firstPurchaseRow)) {
        event.detail.forecastId = fcRow.forecastId.at(0);
        event.detail.fcRow = { ...fcRow };
        event.detail.productDetailId = data.productDetailId;
        event.detail.tpnb = data.tpnb;
        event.detail.warehouseId = selectedDC.id;
        event.detail.warehouseCode = selectedDC.code;
        event.detail.qsRowsCount = qsRowsCount;
        event.detail.participation = Math.max(0, autofillFunctionDisabled ? calcParticipation(event.detail.fcRow.forecastId) : event.detail.participation);
        console.log("purchasedVol = " + purchasedVol);
        console.log("fcRow.purchasedVol = " + fcRow.purchasedVol);
        console.log({ fcRow });
        event.detail.proposedVolume = proposePurchaseVolume(fcRow.totalVol, purchasedVol, -1, event.detail.participation, autofillFunctionDisabled ? false : true);
        console.log("on:newPurchase", { event }, { fcRow });
        newPurchaseRows.push(preparePurchaseRow(event.detail));
      } else {
        console.log("Different logistics!");
        diffLogisticsMessage = $_("warn_incorrect_logistics", {
          values: {
            carton: firstPurchaseRow.cartonLogistics,
            pallet: firstPurchaseRow.palletLogistics,
          },
        });
        diffLogDialogOpen = true;
      }
    } else if (event.detail.purchaseType == "autofill") {
      // Clicked to Autofill button
      mode = "replace";
      // Check logistics
      let logsCount = [
        ...new Set(
          qsRows
            .filter((x) => x.country == selectedDC.country)
            .map((x) => [["ALL", "CART"].includes(logisticsParam) ? x.cartonLogistics : "", ["ALL", "PAL"].includes(logisticsParam) ? x.palletLogistics : ""].join("/")),
        ),
      ].length;
      if (logsCount == 1) {
        qsRows
          .filter((x) => x.country == selectedDC.country)
          .sort((a, b) => (a.participation > b.participation ? -1 : 1))
          .forEach((qs) => {
            event.detail = { ...qs };
            event.detail.forecastId = fcRow.forecastId.at(0);
            event.detail.fcRow = { ...fcRow };
            event.detail.productDetailId = data.productDetailId;
            event.detail.tpnb = data.tpnb;
            event.detail.warehouseId = selectedDC.id;
            event.detail.warehouseCode = selectedDC.code;
            event.detail.qsRowsCount = qsRowsCount;
            console.log({ fcRow });
            event.detail.participation = Math.max(0, autofillFunctionDisabled ? calcParticipation(event.detail.fcRow.forecastId) : qs.participation);
            // Do not limit proposed volume to QuoteSheet volume anymore
            event.detail.proposedVolume = proposePurchaseVolume(fcRow.totalVol, purchasedVol, -1 /*event.detail.remainingVolume*/, event.detail.participation, false);
            console.log("on:autofillPurchase", { event }, { fcRow });
            newPurchaseRows.push(preparePurchaseRow(event.detail));
          });
      } else {
        console.log("Different logistics for autofill!");
        diffLogisticsMessage = $_("warn_incorrect_sups_logistics");
        diffLogDialogOpen = true;
      }
    }

    if (newPurchaseRows.length > 0) {
      updatePurchaseRowsList(newPurchaseRows, mode);
    }
  };

  const preparePurchaseRow = (d) => {
    console.log("===================== preparePurchaseRow =========================", d);
    let dcDistRatio = d.fcRow.totalVol > 0 ? d.proposedVolume / d.fcRow.totalVol : 1;
    let newPurchaseRow = {
      country: d.country,
      warehouseId: d.warehouseId,
      warehouseCode: d.warehouseCode,
      week: d.week,
      year: d.year,
      id: "p-add-" + d.id,
      forecastId: d.forecastId,
      canBuy: d.fcRow.canBuy,
      purchaseId: null,
      quoteSheetId: d.qsId,
      rowType: "PURCHASE",
      currency: d.currency,
      supplierId: d.supplierId,
      supplierName: d.supplierName,
      productDetailId: d.productDetailId,
      productId: d.productId,
      tpnb: d.tpnb,
      cartonLogistics: d.cartonLogistics,
      palletLogistics: d.palletLogistics,
      units: d.units,
      variety: d.variety,
      countryOfOrigin: d.countryOfOrigin,
      price: d.price,
      priceEur: d.priceEur,
      pricePromo: d.pricePromo,
      pricePromoEur: d.pricePromoEur,
      autobuy: false,
      participation: d.participation,
      d1Vol: Math.round(d.fcRow?.d1Vol * dcDistRatio),
      d1AmendVol: null,
      d1Promo: d.d1Promo || false,
      d2Vol: Math.round(d.fcRow?.d2Vol * dcDistRatio),
      d2AmendVol: null,
      d2Promo: d.d2Promo || false,
      d3Vol: Math.round(d.fcRow?.d3Vol * dcDistRatio),
      d3AmendVol: null,
      d3Promo: d.d3Promo || false,
      d4Vol: Math.round(d.fcRow?.d4Vol * dcDistRatio),
      d4AmendVol: null,
      d4Promo: d.d4Promo || false,
      d5Vol: Math.round(d.fcRow?.d5Vol * dcDistRatio),
      d5AmendVol: null,
      d5Promo: d.d5Promo || false,
      d6Vol: Math.round(d.fcRow?.d6Vol * dcDistRatio),
      d6AmendVol: null,
      d6Promo: d.d6Promo || false,
      d7Vol: Math.round(d.fcRow?.d7Vol * dcDistRatio),
      d7AmendVol: null,
      d7Promo: d.d7Promo || false,
      flexVol: Math.round(d.fcRow?.flexVol * dcDistRatio),
      totalVol: 0,
      deliveryType: "direct",
      rowState: "add",
      rowScope: scope,
      status: "prepared",
      deleted: false,
      userComment: null,
    };

    // Calculate total volume
    newPurchaseRow.totalVol =
      newPurchaseRow.d1Vol +
      newPurchaseRow.d2Vol +
      newPurchaseRow.d3Vol +
      newPurchaseRow.d4Vol +
      newPurchaseRow.d5Vol +
      newPurchaseRow.d6Vol +
      newPurchaseRow.d7Vol +
      newPurchaseRow.flexVol;

    return newPurchaseRow;
  };

  const updatePurchaseRowsList = (newRows, mode) => {
    console.log("updatePurchaseRowsList()", { newRows }, { mode });

    if (newRows.length == 0) {
      return;
    }

    let fcIdList = [...new Set(newRows.flatMap((x) => x.forecastId))];

    // Put new row to correct spot in array
    let ret = [];
    let purchaseRows = [];

    if (mode == "add") {
      purchaseRows = mergeRows
        .filter((b) => b.rowType == "PURCHASE" && fcIdList.includes(b.forecastId))
        .concat(newRows)
        .sort((a, b) => (a.supplierId > b.supplierId ? 1 : -1));
    } else {
      purchaseRows = newRows.sort((a, b) => (a.supplierId > b.supplierId ? 1 : -1));
    }

    mergeRows
      .filter((b) => b.rowType != "PURCHASE" || (b.rowType == "PURCHASE" && !fcIdList.includes(b.forecastId)))
      .forEach((x) => {
        ret.push(x);
        if (fcIdList.includes(x.forecastId)) {
          ret = ret.concat(purchaseRows.filter((f) => f.forecastId == x.forecastId));
        }
      });

    mergeRows = ret;

    // Update list of bought
    newRows.forEach((s) => purchasedSuppliers.push({ supplierId: s.supplierId }));
    purchasedSuppliers = purchasedSuppliers;

    handlePurchaseTableVolumeChange();

    proposedPurchaseVolume = purchaseRows
      .filter((x) => x.rowState == "add")
      .map((x) => {
        return {
          quoteSheetId: x.quoteSheetId,
          supplierId: x.supplierId,
          totalVol: x.totalVol,
        };
      });
  };

  const handlePurchaseTableVolumeChange = (event) => {
    console.log("handlePurchaseTableVolumeChange", { event });
    sumPurchaseVolume();
    calculateDifferences(d1Vol, d2Vol, d3Vol, d4Vol, d5Vol, d6Vol, d7Vol, flexVol);
    recalcSupplierRemainingVolume();
  };

  const handleParticipationChange = (event) => {
    console.log("===================== handleParticipationChange ====================", { event });
    let d = event.detail;
    let fcRow = getFcRow();
    let proposedVolume = proposePurchaseVolume(fcRow.totalVol, 0, -1 /*event.detail.remainingVolume*/, d.participation, true);
    let dcDistRatio = fcRow.totalVol > 0 ? proposedVolume / fcRow.totalVol : 1;
    let purchaseRow = mergeRows.filter((r) => r.rowType == "PURCHASE" && r.supplierId == d.supplierId && r.warehouseId == d.warehouseId).at(0);

    purchaseRow.participation = d.participation;
    purchaseRow.d1Vol = Math.round(fcRow?.d1Vol * dcDistRatio);
    purchaseRow.d2Vol = Math.round(fcRow?.d2Vol * dcDistRatio);
    purchaseRow.d3Vol = Math.round(fcRow?.d3Vol * dcDistRatio);
    purchaseRow.d4Vol = Math.round(fcRow?.d4Vol * dcDistRatio);
    purchaseRow.d5Vol = Math.round(fcRow?.d5Vol * dcDistRatio);
    purchaseRow.d6Vol = Math.round(fcRow?.d6Vol * dcDistRatio);
    purchaseRow.d7Vol = Math.round(fcRow?.d7Vol * dcDistRatio);
    purchaseRow.flexVol = Math.round(fcRow?.flexVol * dcDistRatio);
    purchaseRow.rowState = purchaseRow.rowState == "origin" ? "updated" : purchaseRow.rowState;

    mergeRows = mergeRows;

    sumPurchaseVolume();
    calculateDifferences(d1Vol, d2Vol, d3Vol, d4Vol, d5Vol, d6Vol, d7Vol, flexVol);
    recalcSupplierRemainingVolume();
    enableSaveAutofillButton = true;
  };

  function calculateDifferences(d1, d2, d3, d4, d5, d6, d7, flex) {
    console.log("calculateDifferences");

    diff.d1 = Number(purchaseForecastRow.d1) - Number(purchaseAlreadyRow.d1) - d1;
    diff.d2 = Number(purchaseForecastRow.d2) - Number(purchaseAlreadyRow.d2) - d2;
    diff.d3 = Number(purchaseForecastRow.d3) - Number(purchaseAlreadyRow.d3) - d3;
    diff.d4 = Number(purchaseForecastRow.d4) - Number(purchaseAlreadyRow.d4) - d4;
    diff.d5 = Number(purchaseForecastRow.d5) - Number(purchaseAlreadyRow.d5) - d5;
    diff.d6 = Number(purchaseForecastRow.d6) - Number(purchaseAlreadyRow.d6) - d6;
    diff.d7 = Number(purchaseForecastRow.d7) - Number(purchaseAlreadyRow.d7) - d7;
    diff.flex = Number(purchaseForecastRow.flex) - Number(purchaseAlreadyRow.flex) - flex;
    diff.total = Number(purchaseForecastRow.total) - Number(purchaseAlreadyRow.total) - totalVol;

    remainingVolume = originalRemainingVolume - d1 - d2 - d3 - d4 - d5 - d6 - d7;
    remainingVolume = remainingVolume < 0 ? 0 : remainingVolume;

    console.log({ purchaseForecastRow }, { d1 }, { purchaseAlreadyRow }, { diff });
  }

  function recalcSupplierRemainingVolume() {
    workQsRows.forEach((qs) => {
      if (qs.country == selectedDC.country) {
        qs.remainingVolume = qs.volume - mergeRows.filter((x) => x.rowType == "PURCHASE" && x.supplierId == qs.supplierId).reduce((acc, val) => acc + val.totalVol, 0);
        if (qs.remainingVolume < 0) {
          qs.remainingVolume = 0;
        }
      }
    });

    workQsRows = workQsRows;
    console.log("recalcSupplierRemainingVolume()", { workQsRows });
  }

  function handleDisplayUnitsRadioChange() {
    console.log("handleDisplayUnitsRadioChange");
    let newUnit = [1, productData?.cartonLogistics, productData?.palletLogistics].at(displayUnits.filter((u) => u.name == selectedDisplayUnit).at(0)?.id);

    unitConversionRatio = 1 / newUnit;
  }

  function purchasePopupInitData() {
    console.log("======================== purchasePopupInitData() ======================", { data });
    if (data) {
      if (scope == "dc") {
        if (data.warehouseId) {
          selectedDC = $dcList.filter((dc) => dc.id == data.warehouseId).at(0);
          initForecastRow();
        }
      } else {
        if (data.country) {
          selectedCountry = $countries.filter((c) => c.id.toUpperCase() == data.country).at(0);
          initForecastRow();
        }
      }

      if (data.deliveryType) {
        selectedDeliveryType = $deliveryTypes.filter((dt) => dt.id == data.deliveryType).at(0);
      }

      if (data.units) {
        selectedUnits = $unitList.filter((u) => u.code == data.units).at(0);
      }

      if (type == "add") {
        cartonLogistics = data.cartonLogistics;
        palletLogistics = data.palletLogistics;
        participation = 0;
        regularPrice = data.offeredPrice;
        regularPriceEur = data.offeredPriceEur;
        promoPrice = data.offeredPrice;
        promoPriceEur = data.offeredPriceEur;
        remainingVolume = data.remainingVolume;
        originalRemainingVolume = data.remainingVolume;
      } else {
        cartonLogistics = data.cartonLogistics;
        palletLogistics = data.palletLogistics;
        participation = data.participation;
        regularPrice = data.price;
        regularPriceEur = data.priceEur;
        promoPrice = data.price;
        promoPriceEur = data.priceEur;
        remainingVolume = data.remainingVolume;
        originalRemainingVolume = data.remainingVolume;
      }
      console.log({ selectedDC });
      console.log({ selectedDeliveryType });
      console.log({ selectedUnits });

      supplierList = qsRows.map((x) => {
        return { id: x.supplierId, name: x.supplierName, country: x.country };
      });

      console.log({ supplierList });
    }
  }

  function sumPurchaseVolume() {
    d1Vol = d2Vol = d3Vol = d4Vol = d5Vol = d6Vol = d7Vol = flexVol = 0;

    mergeRows
      .filter((x) => (scope == "dc" ? x.warehouseId == selectedDC.id : x.country == selectedCountry.id.toUpperCase()) && x.rowType == "PURCHASE" && !x?.deleted)
      .forEach((r) => {
        d1Vol += Number(r.d1Vol);
        d2Vol += Number(r.d2Vol);
        d3Vol += Number(r.d3Vol);
        d4Vol += Number(r.d4Vol);
        d5Vol += Number(r.d5Vol);
        d6Vol += Number(r.d6Vol);
        d7Vol += Number(r.d7Vol);
        flexVol += Number(r.flexVol);
      });

    totalVol = d1Vol + d2Vol + d3Vol + d4Vol + d5Vol + d6Vol + d7Vol + flexVol;

    if (initialized && totalVol > 0) {
      enableSaveButton = true;
    }

    // console.log({mergeRows}, {d1Vol}, {d2Vol}, {d3Vol}, {d4Vol}, {d5Vol}, {d6Vol}, {d7Vol}, {flexVol}, {totalVol});
  }

  const handleQSEditConfirm = (e) => {
    console.log("handleQSEditConfirm()", { e });
    saveQSChange(e.detail);
  };

  const getProductData = async () => {
    if (!data.productId) {
      return;
    }

    console.log("getProductData()", data.productId);
    try {
      let ret = await apiGetProduct(data.productId);
      if (ret) {
        productData = ret.at(0);
      }
      console.log({ productData });
    } catch (error) {
      console.log({ error });
      error.loc = "getProductData";
      if (!handleApiError($_("get_data_failed"), error)) throw error;
    }
  };

  const initForecastRow = () => {
    let fcRow = getFcRow();

    if (fcRow) {
      // console.log({fcRow});
      purchaseForecastRow = {
        d1: fcRow.d1Vol,
        d2: fcRow.d2Vol,
        d3: fcRow.d3Vol,
        d4: fcRow.d4Vol,
        d5: fcRow.d5Vol,
        d6: fcRow.d6Vol,
        d7: fcRow.d7Vol,
        flex: fcRow.flexVol,
        total: fcRow.totalVol,
      };
    }
    handlePurchaseTableVolumeChange();
    console.log({ purchaseForecastRow });
  };

  const prepareDCRow = (cpRow, fcRow, id, fcTotal) => {
    console.log("prepareDCRow", { cpRow }, { fcRow });
    let inputData = {};
    let qsRow = qsRows.filter((x) => x.id == cpRow.quoteSheetId).at(0);
    inputData = {
      ...cpRow,
      qsId: cpRow.quoteSheetId,
      id: [cpRow.id.split("-").at(2), id].join("."),
      warehouseCode: fcRow.warehouseCode,
      warehouseId: fcRow.warehouseId,
      forecastId: fcRow.forecastId,
      fcRow: { ...fcRow },
      qsId: cpRow.quoteSheetId,
      qsRowsCount: qsRows.filter((x) => x.country == selectedDC.country).length,
      proposedVolume: fcTotal > 0 ? (fcRow.totalVol / fcTotal) * cpRow.totalVol : 0,
    };
    return preparePurchaseRow(inputData);
  };

  const backupCountryRows = () => {
    bckSplitRows = [];
    mergeRows.forEach((x) => {
      if (x.rowType == "PURCHASE") {
        bckSplitRows.push({ ...x });
        if (!x.rowState) {
          x.rowState = "origin";
        }
      }
    });
  };
  const reverseCountrySplit = () => {
    console.log("--> reverseCountrySplit() <--");
    let ret = [];
    mergeRows
      .filter((x) => x.rowType != "PURCHASE")
      .forEach((r) => {
        ret.push(r);
        if (r.rowType == "FORECAST") {
          bckSplitRows
            .filter((b) => b.forecastId == r.forecastId)
            .sort((a, b) => (a.supplierId > b.supplierId ? 1 : -1))
            .forEach((p) => ret.push(p));
        }
      });

    mergeRows = [...ret];
  };

  const splitCountryPurchaseRows = () => {
    console.log("splitCountryPurchaseRows");
    backupCountryRows();
    let fcRows = mergeRows.filter((x) => x.rowType == "FORECAST" && x.country == selectedCountry.id.toUpperCase());
    let fcTotal = fcRows.map((x) => x.totalVol).reduce((acc, val) => acc + val, 0);
    let newPurchaseRows = [];
    let id = 1;
    // console.log({fcRows}, {mergeRows});

    // Prepare new rows
    mergeRows
      .filter((x) => x.country == selectedCountry.id.toUpperCase() && x.rowType == "PURCHASE" && x.rowState == "add" && !x.deleted && x.rowScope == scope && !x.warehouseId)
      .forEach((cpRow) => {
        console.log({ ...cpRow });
        fcRows.forEach((fcRow) => {
          console.log({ ...fcRow });
          console.log("Call prepareDCRow");
          newPurchaseRows.push(prepareDCRow(cpRow, fcRow, id, fcTotal));
          id++;
        });
        console.log(newPurchaseRows);
      });

    // Remove Country purchase rows and add newly creaded dc rows
    mergeRows = mergeRows.filter(
      (x) => !(x.country == selectedCountry.id.toUpperCase() && x.rowType == "PURCHASE" && x.rowState == "add" && x.rowScope == scope && !x.warehouseId),
    );
    updatePurchaseRowsList(newPurchaseRows, "add");
  };

  const handlePopupSaveAutofillButton = () => {
    console.log("handlePopupSaveAutofillButton()");
    showAutofillSaveConfirmationDialogOpen = true;
  };

  const handlePopupSaveButton = (init) => {
    console.log("handlePopupSaveButton()", init, { data }, scope);
    if (scope == "dc") {
      scopeData = { ...data };
      savePurchaseChanges(data.forecastId, true);
    } else {
      let fcRows = [];

      if (init) {
        scopeData = { ...data };
        splitCountryPurchaseRows();
        fcRows = mergeRows.filter((x) => x.rowType == "FORECAST" && x.country == selectedCountry.id.toUpperCase()).sort((a, b) => (a.forecastId > b.forecastId ? 1 : -1));
      } else {
        fcRows = mergeRows
          .filter((x) => x.rowType == "FORECAST" && x.country == scopeData.country && x.forecastId > scopeData.forecastId)
          .sort((a, b) => (a.forecastId > b.forecastId ? 1 : -1));
      }

      console.log({ ...fcRows });
      fcRow = fcRows.at(0);
      scopeData = { ...fcRow };
      scopeData.closeDialog = fcRows.length == 1;
      console.log("savePurchaseChanges", fcRow.forecastId, scopeData.closeDialog, { ...scopeData }, { ...fcRow });
      if (init) {
        newFCStatus = "purchased";
        savePurchaseChanges(fcRow.forecastId, scopeData.closeDialog);
      } else {
        // do not request reasonCode again
        if (!newFCStatus) {
          newFCStatus = "purchased";
        }
        fcReasonDialogConfirmed(scopeData.closeDialog);
      }
    }
  };

  const savePurchaseChanges = (forecastId, closeDialog) => {
    console.log("savePurchaseChanges()", { forecastId }, { closeDialog });
    let fcTotalVol = mergeRows.filter((x) => x.rowType == "FORECAST" && x.forecastId == forecastId).at(0).totalVol;

    let oldFCData = mergeRows
      .filter((x) => x.rowType == "FORECAST" && x.forecastId == forecastId)
      .map((x) => {
        return {
          status: x.status,
          reasonCode: x?.reasonCode || "",
          userComment: x?.userComment || "",
        };
      })
      .at(0);

    // Re-enable Rejected purchase rows
    if (oldFCData?.status == "rejected") {
      mergeRows.forEach((x) => {
        if (x.rowType == "PURCHASE" && x.forecastId == forecastId && x.status == "rejected" && !x?.deleted) {
          x.status = "prepared";
          x.rowState = x.rowState == "origin" ? "updated" : x.rowState;
        }
      });

      mergeRows = mergeRows;
    }

    const purchasedRowsCount = mergeRows.filter((x) => x.rowType == "PURCHASE" && x.forecastId == forecastId && !x?.deleted).length;

    if (diff.total < 0 || (diff.total > 0 && (diff.total != fcTotalVol || purchasedRowsCount > 0))) {
      newFCStatus = diff.total > 0 ? "purchased_less" : "purchased_more";

      if (oldFCData?.status != newFCStatus) {
        selectedPurchaseDiffReason = null;
        purchaseDiffComment = "";
      } else {
        selectedPurchaseDiffReason = oldFCData.reasonCode;
        purchaseDiffComment = oldFCData?.userComment;
      }
      fcReasonDialog = true;
    } else {
      newFCStatus = diff.total > 0 && diff.total == fcTotalVol ? "new" : "purchased";
      selectedPurchaseDiffReason = null;
      purchaseDiffComment = "";
      fcReasonDialogConfirmed(closeDialog);
      open = false;
    }
  };

  const fcReasonDialogConfirmed = async (closeDialog) => {
    console.log("fcReasonDialogConfirmed()");
    dispatch("purchasePopupSave", {
      data: scopeData,
      status: newFCStatus,
      reasonCode: selectedPurchaseDiffReason,
      userComment: purchaseDiffComment,
    });
    if (closeDialog) {
      fcReasonDialog = false;
      resetPopup();
    } else {
      if (scope == "country") {
        handlePopupSaveButton(false);
      }
    }
  };

  // TODO: split to library - copied from PurchaseScreen
  const convertToEur = (value, currency) => {
    let retValue = null;

    if (currency && value) {
      currency = String(currency).toUpperCase();
      if (currency == "EUR") {
        retValue = value;
      } else {
        let exRate = $exchangeRates.filter((x) => x.code == currency).at(0)?.valueInEur;
        console.log(exRate);
        if (exRate && exRate > 0) {
          retValue = (value / exRate).toFixed(3);
        }
      }
    }

    return retValue;
  };

  const saveQSChange = async (item) => {
    console.log("=== saveQSChange ===");
    console.log({ item });

    let country = item.country;
    let countryLocal = country[0].toUpperCase() + country[1].toLowerCase();
    // if (priceType == 'normal' && item.priceNew) {
    //   item["price"+countryLocal] = Number(item.priceNew);
    // } else if (priceType == 'promo') {
    //   item["pricePromo"+countryLocal] = Number(item.pricePromoNew);
    // } else {
    //   console.log("Error: unknown priceType - "+priceType);
    //   return;
    // }

    try {
      if (item.applyChangeToProduct) {
        console.log("Apply logistics change to Product");
        const productRet = await apiUpdateProductLogistics({
          id: productData.id,
          cartonLogistics: item.cartonLogistics,
          palletLogistics: item.palletLogistics,
        });
        console.log({ productRet });
        productData.cartonLogistics = item.cartonLogistics;
        productData.palletLogistics = item.palletLogistics;
      }

      if (item.applyChangeToOthers) {
        console.log("Apply logistics change to all country Purchases");

        // Purchases update
        let promises = [];
        mergeRows
          .filter((x) => x.rowType == "PURCHASE" && x.rowState != "add" && x.country == country)
          .forEach((p) => {
            promises.push(
              apiUpdatePurchaseLogistics({
                id: p.purchaseId,
                productId: p.productId,
                cartonLogistics: item.cartonLogistics,
                palletLogistics: item.palletLogistics,
              }),
            );
          });

        if (promises.length > 0) {
          const purchResponses = await Promise.all(promises);
          console.log({ purchResponses });
        }

        mergeRows.forEach((p) => {
          if (p.rowType == "PURCHASE" && p.country == country) {
            p.cartonLogistics = item.cartonLogistics;
            p.palletLogistics = item.palletLogistics;
          }
        });

        mergeRows = mergeRows;
      }

      // Convert decimal coma to dot
      function getNumber(value) {
        console.log("getNumber()", { value });
        if (typeof value == "string") {
          return Number(value.replace(",", "."));
        }

        return Number(value);
      }

      item["price" + countryLocal] = getNumber(item["price" + countryLocal]);
      item["pricePromo" + countryLocal] = getNumber(item["pricePromo" + countryLocal]);

      // Update other QS rows
      const adjResponse = await apiAdjustPurchaseLogistics({
        productId: productData.id,
        year: $selectedWeek.tesco_year,
        week: $selectedWeek.tesco_week,
        country: item.country,
        buyerCartonLogistics: getNumber(item.cartonLogistics),
        buyerPalletLogistics: getNumber(item.palletLogistics),
      });

      console.log({ adjResponse });
      if (adjResponse) {
        qsRows.forEach((i) => {
          if (i.country == item.country) {
            i.cartonLogistics = item.cartonLogistics;
            i.palletLogistics = item.palletLogistics;
          }
        });
      }

      const ret = await apiUpdateQuoteSheet({ ...item, id: item.qsId });
      if (ret) {
        console.log({ ret });
        $snackbarSuccess.text = $_("quotesheet_updated");
        $snackbarSuccess.element && $snackbarSuccess.element.open();

        qsRows.forEach((i) => {
          if (i.id == item.id) {
            i.variety = item.variety;
            i.countryOfOrigin = item.countryOfOrigin;
            i["price" + countryLocal] = item["price" + countryLocal];
            i["pricePromo" + countryLocal] = item["pricePromo" + countryLocal] ? ["pricePromo" + countryLocal] : "";
          }
        });

        qsRows = qsRows;

        workQsRows.forEach((i) => {
          if (i.id == item.id) {
            i.variety = item.variety;
            i.countryOfOrigin = item.countryOfOrigin;
            i.cartonLogistics = item.cartonLogistics;
            i.palletLogistics = item.palletLogistics;
            i["price" + countryLocal] = item["price" + countryLocal];
            i["pricePromo" + countryLocal] = item["pricePromo" + countryLocal];
            i.price = item["price" + countryLocal];
            i.pricePromo = item["pricePromo" + countryLocal] ? item["pricePromo" + countryLocal] : "";
            i.priceEur = convertToEur(i.price, i.currency);
            i.pricePromoEur = item["pricePromo" + countryLocal] ? convertToEur(i.pricePromo, i.currency) : "";
          }
        });
        workQsRows = workQsRows;
        qsEditDialogOpen = false;

        dispatch("qsRowUpdated", { country: countryLocal, item: item });
      }
    } catch (error) {
      console.log({ error });
      error.loc = "saveQSChange";
      qsEditDialogOpen = false;

      if (!handleApiError($_("update_failed_plain"), error, "non-fatal")) throw error;
    }
  };

  // Re-calculate participation if needed (missing or zero)
  const calcParticipation = (forecastId) => {
    console.log("========================= calcParticipation ======================", forecastId);
    let purchasedParticipation = mergeRows
      .filter((x) => x.rowType == "PURCHASE" && x.productId == data.productId && forecastId.includes(x.forecastId))
      .reduce((total, val) => (total += val.participation), 0);
    console.log({ purchasedParticipation });
    return 100 - purchasedParticipation;
  };

  // Calculate proposed purchase volume
  const proposePurchaseVolume = (fcVolume, purchasedVolume, qsVolume, particip, manParticInd) => {
    console.log(
      "========================= proposePurchaseVolume ====================== fcVolume:",
      fcVolume,
      "purchasedVolume:",
      purchasedVolume,
      "qsVolume:",
      qsVolume,
      "particip:",
      particip,
      "manParticInd: ",
      manParticInd,
      "autofillFunctionDisabled: ",
      autofillFunctionDisabled,
    );
    //console.log("fcVolume:", fcVolume, "qsVolume:", qsVolume, "particip:", particip, "manParticInd: ", manParticInd, "autofillFunctionDisabled: ", autofillFunctionDisabled);
    let participation, proposedVolume;
    particip = Math.max(particip, 0);
    if (autofillFunctionDisabled) {
      if (!manParticInd) {
        participation = 100;
      } else {
        participation = particip;
      }
      proposedVolume = ((fcVolume - purchasedVolume) * participation) / 100;
    } else {
      participation = particip;
      if (manParticInd) {
        proposedVolume = (fcVolume * participation) / 100;
      } else {
        proposedVolume = ((fcVolume - purchasedVolume) * participation) / 100;
      }
    }
    //console.log("proposePurchaseVolume - participation:", participation, "particip:", particip, "proposedVolume:", proposedVolume);
    if (qsVolume < 0) {
      return Math.max(proposedVolume, 0);
    }
    return proposedVolume < 0 ? 0 : Math.min(qsVolume, proposedVolume);
  };

  // Popup initialization
  $: if (open && !initialized) {
    console.log("Purchase Popup Initialization");

    // if (autobuy) {}

    resetPopup();

    bckMergeRows = [];
    workQsRows = [...qsRows];

    mergeRows.forEach((x) => {
      if (x.rowType == "PURCHASE") {
        bckMergeRows.push({ ...x });
        if (!x.rowState) {
          x.rowState = "origin";
        }
      }
    });

    console.log({ bckMergeRows });

    purchasedSuppliers = mergeRows
      .filter((x) => x.rowType == "PURCHASE" && (scope == "dc" ? x.warehouseId == data.warehouseId : x.country == data.country))
      .map((x) => {
        return { supplierId: x.supplierId };
      });

    console.log({ purchasedSuppliers });

    purchasePopupInitData();
    handlePurchaseTableVolumeChange();

    initialized = true;
    // calculateDifferences(d1Vol, d2Vol, d3Vol, d4Vol, d5Vol, d6Vol, d7Vol, flexVol);
  } else if (!open && initialized) {
    console.log("Already initialized: ", { open }, { initialized });
    initialized = false;
  } /*else {
    console.log("Initialization failed: ", {open}, {initialized})
  }*/

  const showPurchaseBlockHeader = (dc) => {
    if (dc != prevPurchBlockHeader || (prevPurchBlockHeader && dc === null)) {
      prevPurchBlockHeader = dc;
      return true;
    }
    return false;
  };

  const autofillSuppliersFound = (qsSuppliers, afSuppliers) => {
    //console.log("================== autofillSuppliersFound ===============", qsSuppliers, afSuppliers);
    return afSuppliers.every(
      (
        (i) => (v) =>
          (i = qsSuppliers.indexOf(v, i) + 1)
      )(0),
    );
  };

  const saveAutofillData = async () => {
    console.log("================== saveAutofillData ===============");

    apiCallProcessing = true;
    apiCallProcessingMessage = $_("updating_please_wait");

    let saveAutofillList = [...autofillData];
    saveAutofillList.forEach((a) => {
      a.rowType = "delete";
      a.changed = true;
    });
    let newAutofillList = [...mergeRows]
      .filter((x) => x.rowType == "PURCHASE" && !x?.deleted && x.participation != 0 && (scope == "dc" ? x.warehouseId == data.warehouseId : x.country == data.country))
      .map((x) => {
        return {
          id: null,
          country: x.country,
          supplierId: x.supplierId,
          productId: x.productId,
          participation: x.participation,
          type: "AF",
          rowType: "new",
          changed: true,
        };
      });

    newAutofillList.forEach((n) => {
      if ([...saveAutofillList].map((x) => x.supplierId).includes(n.supplierId)) {
        // Supplier exists already => Check participation
        let oldRecord = [...saveAutofillList].filter((o) => o.supplierId == n.supplierId).at(0);
        if (n.participation == oldRecord.participation) {
          // Participation is the same => No change
          oldRecord.rowType = "unchanged";
          oldRecord.changed = false;
        } else {
          // Participation differs => Update
          oldRecord.participation = n.participation;
          oldRecord.rowType = "update";
        }
      } else {
        // Supplier doesn\'t exists already => Create
        saveAutofillList.push(n);
      }
    });

    console.log({ saveAutofillList });

    let promises = [];
    try {
      saveAutofillList.forEach((x) => {
        if (x.changed) {
          if (x.rowType == "new") {
            promises.push(apiCreateAutobuy(x));
          } else if (x.rowType == "delete") {
            promises.push(apiDeleteAutobuy(x.id));
          } else if (x.rowType == "update") {
            promises.push(apiUpdateAutobuy(x));
          }
        }
      });

      await Promise.all(promises);

      dispatch("autofillSetupSave");
      enableSaveAutofillButton = false;
      apiCallProcessing = false;
      $snackbarSuccess.text = $_("changes_saved");
      $snackbarSuccess.element && $snackbarSuccess.element.open();
    } catch (error) {
      apiCallProcessing = false;
      console.log({ error });
      error.loc = "PurchasePopup:saveAutofillData";
      if (!handleApiError($_("update_failed_plain"), error)) throw error;
    }
  };

  $: if (data) {
    getProductData();
  }

  $: {
    console.log("----- PSPurchasePopup -----");
    console.log({ data });
    console.log({ type });
    console.log({ scope });
    console.log({ qsRows });
    console.log({ mergeRows });
    console.log({ bckSplitRows });
    console.log({ diff });
    console.log({ productData });
    console.log({ selectedDC });
    console.log({ selectedCountry });
    console.log({ autofillData });
    console.log({ workQsRows });
    workQsRows = workQsRows;
  }

  $: {
    let sups = [...supplierList]
      .filter((s) => s.country == data.country)
      .sort((x, y) => (x.id > y.id ? 1 : -1))
      .map((t) => t.id);

    let supsDuplicates = [...sups].filter((item, index) => sups.indexOf(item) !== index);

    let autofills = [...autofillData]
      .filter((r) => r.country == data.country)
      .sort((a, b) => (a.supplierId > b.supplierId ? 1 : -1))
      .map((q) => q.supplierId);

    console.log({ mergeRows });
    console.log({ selectedDC });

    let allreadyPurchasedRowsCount = 0; // Default to 0
    if (selectedCountry?.id || selectedDC) {
      allreadyPurchasedRowsCount = [...mergeRows].filter(
        (r) => (scope === "dc" && selectedDC ? r.warehouseId === selectedDC.id : selectedCountry?.id && r.country === selectedCountry.id.toUpperCase()) && r.rowType === "PURCHASE",
      ).length;
    }

    console.log({ allreadyPurchasedRowsCount });

    autofillFunctionDisabled = autofillData.length === 0 || !autofillSuppliersFound(sups, autofills) || supsDuplicates.length > 0;

    autofillBtnDisabled = autofillFunctionDisabled || allreadyPurchasedRowsCount > 0;

    console.log({ autofillFunctionDisabled });
    console.log({ autofillBtnDisabled });
  }
</script>

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style={big ? "width: unset; max-width: calc(100vw - 1rem);" : ""}
>
  <Title id="data-changed-title" class={headerColors}>
    {title}
    <span class="ml-4 font-weight-bold grey-text text-lighten-1">{$_(scope)}:</span>
    {scope == "country" ? selectedCountry?.id?.toUpperCase() : selectedDC?.code}
    <span class="pl-4 font-weight-bold grey-text text-lighten-1">{$_("product")}:</span>
    {data ? data.productId + " - " + data.descriptionEn : ""}
    <span class="pl-4 font-weight-bold grey-text text-lighten-1">{$_("country")}:</span>
    {data ? data.country : ""}
    <span class="pl-4 font-weight-bold grey-text text-lighten-1">{$_("cases")}:</span>
    {data && productData ? productData?.cartonLogistics : ""}
    <span class="pl-4 font-weight-bold grey-text text-lighten-1">{$_("pallets")}:</span>
    {data && productData ? productData?.palletLogistics : ""}
  </Title>

  <Content id="data-changed-content" class="mt-4">
    {#if initialized}
      <Flex direction="row" justify="between" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100 pt-2">
        <Flex direction="row" justify="start" align="center" class="gap-2">
          <!-- DC -->
          {#if scope == "dc" && selectedDC}
            <Textfield variant="outlined" disabled label={$_("dc")} class="white small" style="min-width:300px" value={[selectedDC.code, selectedDC.name].join(" - ")}></Textfield>
          {/if}
          {#if scope == "country" && selectedCountry}
            <Textfield
              variant="outlined"
              disabled
              label={$_("country")}
              class="white small"
              style="min-width:300px"
              value={[selectedCountry.id.toUpperCase(), $_(selectedCountry.name)].join(" - ")}
            ></Textfield>
          {/if}
          <!-- Delivery Type -->
          <Textfield variant="outlined" disabled label={$_("units")} class="white small" style="min-width:300px" value={selectedUnits.description} input$emptyValueUndefined
          ></Textfield>

          <div>
            {#each displayUnits as du}
              <FormField>
                <Radio bind:group={selectedDisplayUnit} value={du.name} on:change={handleDisplayUnitsRadioChange} />
                <span slot="label">
                  {du.disp_name}
                </span>
              </FormField>
            {/each}
          </div>
        </Flex>

        <Flex>
          <Button
            class="mr-4 pl-2 pr-4 {autofillBtnDisabled ? 'grey grey-text' : 'tescoblue tescoblue-text'} outlined"
            on:click={(e) => {
              handlePurchaseEvents({ detail: { purchaseType: "autofill" } });
            }}
            disabled={autofillBtnDisabled}
          >
            <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
              <path fill="currentColor" d={mdiCartArrowDown} />
            </Icon>
            {$_("autofill")}
          </Button>
          <Button
            action="save_autofill"
            class="pl-4 pr-4 {enableSaveAutofillButton && !rowDataInvalid ? 'tescoblue white-text' : 'grey white-text'}"
            disabled={!enableSaveAutofillButton || rowDataInvalid}
            on:click={() => {
              handlePopupSaveAutofillButton();
            }}
          >
            <!--
            <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
              <path fill="currentColor" d={mdiCartArrowDown} />
            </Icon> 
-->
            {$_("save_autofill_paricipations")}
          </Button>
        </Flex>
      </Flex>

      {#if 1 == 2}
        <Flex direction="row" justify="between" align="center" class="flex-nowrap gap-05 w-100 gray lighten-4 h-100 mt-3 pt-2">
          <Flex direction="row" justify="start" align="center" class="gap-2 flex-0">
            <!-- Carton logistics -->
            <Textfield
              variant="outlined"
              bind:value={cartonLogistics}
              maxlength="50"
              placeholder=""
              title={$_("dc")}
              class="white w-100 small flex-0"
              input$class="mnw-100"
              input$emptyValueUndefined
              size="40"
              label={$_("carton_logistics")}
            ></Textfield>

            <!-- Pallet logistics -->
            <Textfield
              variant="outlined"
              bind:value={palletLogistics}
              maxlength="50"
              placeholder=""
              title={$_("dc")}
              class="white w-100 small flex-0"
              input$class="mnw-100"
              input$emptyValueUndefined
              size="40"
              label={$_("pallet_logistics")}
            ></Textfield>

            <!-- Participation -->
            <Textfield
              variant="outlined"
              bind:value={participation}
              maxlength="50"
              placeholder=""
              title={$_("dc")}
              class="white w-100 small flex-0"
              input$class="mnw-100"
              input$emptyValueUndefined
              size="40"
              label={$_("participation")}
            ></Textfield>

            <!-- Regular price EUR -->
            <Textfield
              variant="outlined"
              bind:value={regularPriceEur}
              maxlength="50"
              placeholder=""
              title={$_("dc")}
              class="white w-100 small flex-0"
              input$class="mnw-100"
              input$emptyValueUndefined
              size="40"
              label={$_("regular_price_eur")}
            ></Textfield>

            <!-- Promo Price EUR -->
            <Textfield
              variant="outlined"
              bind:value={promoPriceEur}
              maxlength="50"
              placeholder=""
              title={$_("dc")}
              class="white w-100 small flex-0"
              input$class="mnw-100"
              size="40"
              label={$_("promo_price_eur")}
              input$emptyValueUndefined
            ></Textfield>
          </Flex>
          <Flex direction="row" justify="start" align="center" class="gap-1 flex-nowrap">
            <Label>{$_("supplier_remaining_volume")}:</Label>
            <Label class="tescoblue-text">{formatVolume(remainingVolume)}</Label>
          </Flex>
        </Flex>
      {/if}

      <PSQuoteSheetsTable
        items={workQsRows}
        product={productData}
        {purchasedSuppliers}
        {proposedPurchaseVolume}
        country={[scope == "dc" ? selectedDC.country : selectedCountry.id.toUpperCase()]}
        on:newPurchase={(e) => {
          e.detail.purchaseType = "row";
          handlePurchaseEvents(e);
        }}
        on:editQuoteSheetRow={(e) => {
          console.log("on:editQuoteSheetRow");
          qsEditRow = { ...e.detail };
          console.log({ qsEditRow });
          qsEditDialogOpen = true;
          //handleQSEditConfirm(e);
        }}
      />

      <DataTable sortable table$aria-label="Purchase rows table" class="w-100 mt-2 tescoblue-border-thin">
        <Head>
          <Row>
            <Cell columnId="type" class="datatable-header text-center">
              <Label></Label>
            </Cell>
            <Cell numeric columnId="participation" class="datatable-header text-center" style="width: 4rem;">
              <Label>{$_("participation")}</Label>
            </Cell>
            <Cell numeric columnId="d1" class="datatable-header text-center">
              <Label>{$_("d1_short")}</Label>
            </Cell>
            <Cell numeric columnId="d2" class="datatable-header text-center">
              <Label>{$_("d2_short")}</Label>
            </Cell>
            <Cell numeric columnId="d3" class="datatable-header text-center">
              <Label>{$_("d3_short")}</Label>
            </Cell>
            <Cell numeric columnId="d4" class="datatable-header text-center">
              <Label>{$_("d4_short")}</Label>
            </Cell>
            <Cell numeric columnId="d5" class="datatable-header text-center">
              <Label>{$_("d5_short")}</Label>
            </Cell>
            <Cell numeric columnId="d6" class="datatable-header text-center">
              <Label>{$_("d6_short")}</Label>
            </Cell>
            <Cell numeric columnId="d7" class="datatable-header text-center">
              <Label>{$_("d7_short")}</Label>
            </Cell>
            <Cell numeric columnId="flex" class="datatable-header text-center">
              <Label>{$_("flex")}</Label>
            </Cell>
            <Cell numeric columnId="total" class="datatable-header text-center">
              <Label>{$_("total")}</Label>
            </Cell>
          </Row>
        </Head>
        <Body>
          {#if data && (scope == "dc" ? selectedDC : selectedCountry)}
            <!-- Forecast row -->
            <Row class="small background-unset">
              <Cell class="datatable-header right-splitter-main">{$_("requested_volume")}</Cell>
              <Cell class="tescoblue-border-right-thin req-vol-cell"></Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d1 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d2 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d3 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d4 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d5 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d6 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.d7 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin req-vol-cell">{formatVolume(purchaseForecastRow.flex * unitConversionRatio)}</Cell>
              <Cell numeric class="text-bold req-vol-cell">{formatVolume(purchaseForecastRow.total * unitConversionRatio)}</Cell>
            </Row>
            <!-- Already purchased -->
            <!--        <Row class="small background-unset">
              <Cell class="datatable-header">{$_('already_purchased')}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d1 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d2 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d3 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d4 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d5 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d6 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.d7 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin">{formatVolume(purchaseAlreadyRow.flex * unitConversionRatio)}</Cell>
              <Cell numeric class="text-bold">{formatVolume(purchaseAlreadyRow.total * unitConversionRatio)}</Cell>
            </Row>-->
            {#each mergeRows
              .filter((r) => (scope == "dc" ? r.warehouseId == selectedDC.id : r.country == selectedCountry.id.toUpperCase()) && r.rowType == "PURCHASE")
              .sort((a, b) => ([a.warehouseCode, a.supplierName.toLowerCase()].join("+") > [b.warehouseCode, b.supplierName.toLowerCase()].join("+") ? 1 : -1)) as row}
              {#if showPurchaseBlockHeader(row.warehouseCode)}
                <Row class="x-small background-unset">
                  <Cell class="font-weight-bold fs-07rem blue white-text dc-header-cell">{row.warehouseCode ? row.warehouseCode : row.country}</Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="tescoblue-border-right-thin dc-header-cell"></Cell>
                  <Cell class="dc-header-cell"></Cell>
                </Row>
              {/if}
              <!-- Purchase row -->
              <PurchaseRow
                bind:row
                conversionRatio={unitConversionRatio}
                on:promoFlagChange={() => {
                  console.log("on:promoFlagChange");
                  enableSaveButton = true;
                }}
                on:purchaseTableVolumeChange={() => {
                  handlePurchaseTableVolumeChange();
                  enableSaveButton = true;
                }}
                on:participationChanged={handleParticipationChange}
              />
            {/each}
            <!-- Differences row -->
            <Row class="small background-unset">
              <Cell class="datatable-header">{$_("difference")}</Cell>
              <Cell class="tescoblue-border-right-thin text-bold"></Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d1 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d1 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d2 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d2 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d3 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d3 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d4 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d4 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d5 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d5 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d6 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d6 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.d7 != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.d7 * unitConversionRatio)}</Cell>
              <Cell numeric class="tescoblue-border-right-thin text-bold {diff.flex != 0 ? 'tescored-text' : ''}">{formatVolume(-diff.flex * unitConversionRatio)}</Cell>
              <Cell numeric class="text-bold {diff.total != 0 ? 'diff-vol-cell tescored-text' : ''}">{formatVolume(-diff.total * unitConversionRatio)}</Cell>
            </Row>
          {:else}
            <Row>
              <Cell colspan="11">$_('loading_data)</Cell>
            </Row>
          {/if}
        </Body>
      </DataTable>
    {/if}
  </Content>

  <div class="mdc-dialog__actions gap-1 pr-4 mr-4">
    <Button
      action="close"
      class="tescored tescored-text outlined"
      on:click={() => {
        revertChanges();
        resetPopup();
        dispatch("purchasePopupCancel");
      }}
    >
      <Label>{$_("cancel")}</Label>
    </Button>
    <Button
      action="save"
      default
      defaultAction
      class={enableSaveButton && !rowDataInvalid ? "tescoblue white-text" : "grey white-text"}
      disabled={!enableSaveButton || rowDataInvalid}
      on:click={() => {
        handlePopupSaveButton(true);
      }}
    >
      <Label>{$_("save")}</Label>
    </Button>
  </div>

  <PSPurchaseFCStatusPopup
    bind:open={fcReasonDialog}
    insideDialog={true}
    fcStatus={newFCStatus}
    bind:selectedPurchaseDiffReason
    bind:purchaseDiffComment
    on:cancelled={(e) => {
      if (scope == "country") {
        reverseCountrySplit();
      }
      dispatch("cancelled");
    }}
    on:confirmed={(e) => {
      if (scope == "dc") {
        fcReasonDialogConfirmed(true);
      } else {
        console.log("PSPurchaseFCStatusPopup()", { e }, { scopeData });
        fcReasonDialogConfirmed(scopeData?.closeDialog);
      }
    }}
  />

  <PSSaveAutofillParticipationPopup
    bind:open={showAutofillSaveConfirmationDialogOpen}
    title={$_("save_autofill_data_title")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("save")}
    big={false}
    headerColors="tescoblue white-text"
    on:cancelled={() => {
      dispatch("cancelled");
    }}
    on:confirmed={saveAutofillData}
  />

  {#key qsEditRow}
    <PSQuoteSheetsRowEditPopup
      bind:open={qsEditDialogOpen}
      insideDialog={true}
      item={qsEditRow}
      product={productData}
      on:reject={() => {
        qsEditDialogOpen = false;
      }}
      on:confirmed={handleQSEditConfirm}
    ></PSQuoteSheetsRowEditPopup>
  {/key}

  <InfoDialog
    open={diffLogDialogOpen}
    title={$_("different_logistics")}
    btnText={$_("close")}
    big={false}
    headerColors="tescored white-text"
    on:close={() => {
      diffLogDialogOpen = false;
    }}
  >
    {@html diffLogisticsMessage}
  </InfoDialog>
</Dialog>

<style>
</style>
