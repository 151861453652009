<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import DataTable, { Head, Body, Row, Cell, Label, Pagination } from "@smui/data-table";
    import Select, { Option } from "@smui/select";
    import IconButton, { Icon } from "@smui/icon-button";
    import CircularProgress from "@smui/circular-progress";
    import Button from "@smui/button";
    import { mdiMicrosoftExcel } from "@mdi/js";

    export let open = false;
    export let itemHistoryData = [];
    export let loadingHistoryData = false;

    const dispatch = createEventDispatcher();

    // Paging
    let rowsPerPage = 10;
    let currentPage = 0;

    // Define table head
    let tableHead = [
        { columnId: "actionTime", label: "action_time", numeric: false, extraClasses: "right-splitter" },
        { columnId: "operation", label: "operation", numeric: false, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "user", label: "user", numeric: false, extraClasses: "right-splitter" },
        { columnId: "roundNo", label: "round_no", numeric: false, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "supplierId", label: "supplier_no", numeric: true, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "requestedVol", label: "req_volume_cases", numeric: true, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "supplierConfirmedVolume", label: "supp_conf_vol", numeric: true, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "timeLimit", label: "time_limit", numeric: true, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "deadline", label: "supp_deadline", numeric: false, extraClasses: "right-splitter" },
        { columnId: "status", label: "status", numeric: false, extraClasses: "right-splitter" },
        { columnId: "applyStatus", label: "applyStatus", numeric: false, extraClasses: "right-splitter" },
        { columnId: "userComment", label: "user_comment", numeric: false, extraClasses: "wrap-spaces right-splitter" },
        { columnId: "downloadTemplate", label: "file", numeric: false, extraClasses: "wrap-spaces" },
    ];

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, itemHistoryData.length);
    $: slice = itemHistoryData.slice(start, end);
    $: lastPage = Math.max(Math.ceil(itemHistoryData.length / rowsPerPage) - 1, 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }
</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="sa-history-title"
    aria-describedby="sa-history-content"
    class="pa-2"
    surface$style="width: 1900px; max-width: calc(100vw - 32px)"
>
    <Title id="sa-history-title" class="tescoblue white-text">
        {$_("country")}: {itemHistoryData[0]?.country} | {$_("dc")}: {itemHistoryData[0]?.warehouseCode} | {$_("tpn_en")}: {itemHistoryData[0]?.productId} | {$_("tpnb")}: {itemHistoryData[0]
            ?.tpnb} | {$_("delivery_date")}: {itemHistoryData[0]?.deliveryDate}
    </Title>
    <Content id="sa-history-content">
        <DataTable table$aria-label="Audit Data" class="report" style="width: 100%; max-height: 550px;">
            <Head>
                <Row>
                    {#each tableHead as head}
                        <Cell class="datatable-header {head.extraClasses}"><Label>{$_(head.label)}</Label></Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#if loadingHistoryData}
                    <Row>
                        <Cell colspan={tableHead.length} style="text-align: center;">
                            <CircularProgress indeterminate style="height: 32px; width: 32px; margin: auto" aria-label="Data is being loaded..." />
                        </Cell>
                    </Row>
                {:else}
                    {#each slice as item}
                        <Row>
                            <Cell class="text-center right-splitter-sub">{item.actionTimestamp ?? ""}</Cell>
                            <Cell class="text-center right-splitter-sub">{item.actionType ?? ""}</Cell>
                            <Cell class="text-center right-splitter-sub">{item.userName ?? ""}</Cell>
                            <Cell class="text-center right-splitter-sub">{item.roundNo ?? ""}</Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newSupplierId}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{item.supplierId ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{item.newSupplierId}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newRequestedVolume}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{item.requestedVolume ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{item.newRequestedVolume}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newConfirmedVolume}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{item.confirmedVolume ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{item.newConfirmedVolume}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newTimeLimit}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{item.timeLimit ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{item.newTimeLimit}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center right-splitter-sub">{item.newDeadline ?? ""}</Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newStatus}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{$_(item.status) ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{$_(item.newStatus) ?? ""}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newApplyStatus}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{$_(item.applyStatus) ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{$_(item.newApplyStatus) ?? ""}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center right-splitter-sub">
                                {#if item.newUserComment}
                                    <Button class="custom-button" disabled>
                                        <Label class="font-weight-regular">{item.userComment ?? ""}</Label>
                                        <Icon class="material-icons tescoblue-text">arrow_forward</Icon>
                                        <Label class="font-weight-regular">{item.newUserComment}</Label>
                                    </Button>
                                {/if}
                            </Cell>
                            <Cell class="text-center">
                                {#if item.newFileUuid}
                                    <IconButton title={$_("downloadTemplate")} size="button" on:click={() => dispatch("downloadRow", item.newFileUuid)}>
                                        <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                                            <path fill="currentColor" d={mdiMicrosoftExcel} />
                                        </Icon>
                                    </IconButton>
                                {/if}
                            </Cell>
                        </Row>
                    {/each}
                {/if}
            </Body>
            <Pagination slot="paginate">
                <svelte:fragment slot="rowsPerPage">
                    <Label>{$_("rows_per_page")}</Label>
                    <Select variant="outlined" bind:value={rowsPerPage} noLabel>
                        <Option value={10}>10</Option>
                        <Option value={25}>25</Option>
                        <Option value={100}>100</Option>
                    </Select>
                </svelte:fragment>
                <svelte:fragment slot="total">
                    {start + 1}-{end} of {itemHistoryData.length}
                </svelte:fragment>

                <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
                <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
                <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton
                >
                <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
                    >last_page</IconButton
                >
            </Pagination>
        </DataTable>
    </Content>
    <Actions class="pr-4 gap-1 space-around">
        <Button action="close" class="tescoblue white-text" on:click={() => dispatch("closeHistoryDialog")}>
            <Label>{$_("close")}</Label>
        </Button>
    </Actions>
</Dialog>
