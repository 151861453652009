<script lang="ts">
    import Select, { Option } from "@smui/select";
    import { classMap } from "@smui/common/internal";
    import { _ } from "svelte-i18n";
    import { countriesAll } from "../../../stores/AppConfig";
    import { Flags } from "../../../services/Images";

    export let value = "";
    export let editable = true;
    export let allFlag = false;
    export let title = $_("filter");
    export let noLabel = true;
    export let variant: "outlined" | "standard" | "filled" = "outlined";
    export let presentCountries = [];
    export let insideDataTable = false;

    // Define classes using classMap
    export let classe = classMap({
        white: true,
        "tescoblue-text": true,
        small: false,
        "custom-icon": true,
        "with-parameter": true,
        "w-100": true,
    });
    let list = [];

    // Set menu width according to parent element
    let edEl;
    let menuWidth;
    let menuBottom;

    // Reactive statement to filter countries
    $: {
        let excludedCountries = new Set(Array.from(presentCountries).map((item) => item.toUpperCase()));
        //       console.log("excludedCountries", excludedCountries);
        list = $countriesAll.filter((country) => !excludedCountries.has(country.id.toUpperCase()) && (allFlag || country.id !== "ALL"));

        // Automatically select the country if list contains just one country
        if (list.length === 1 && list[0].id !== value) {
            value = list[0].id;
        }
    }
</script>

<Select
    {variant}
    label={title}
    {noLabel}
    bind:value
    bind:this={edEl}
    class={classe}
    data-qa="dt-country-select-cell-all"
    style="--mdc-menu-max-width: {menuWidth}px; --mdc-menu-top: 240px"
    disabled={!editable}
    on:SMUISelect:change
    menu$class={insideDataTable ? "mdc-menu-surface--fixed with-parameter__menu" : ""}
    on:focusin={() => {
        menuWidth = edEl?.getElement()?.clientWidth;
        menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
        console.log("In Focus", { menuWidth }, { menuBottom });
    }}
    on:click={() => {
        console.log("On Click", { edEl });
        menuWidth = edEl?.getElement()?.clientWidth;
        console.log("On Click menuWidth", { menuWidth });
    }}
    menuBottom="edEl?.getElement()?.getBoundingClientRect()?.bottom;"
>
    <svelte:fragment slot="leadingIcon">
        {#if value && value.length > 0}
            {@const country = list.filter((x) => x.id == value).at(0)}
            {#if country}
                <div class="pl-4 pr-2">
                    <img src={Flags[country.flag]} alt={country.id} width="20px" />
                </div>
            {:else}
                <div class="pl-4" />
                2
            {/if}
        {/if}
    </svelte:fragment>
    {#if list && list.length > 0}
        {#key value}
            {#each list as country}
                <Option value={country.id}><img src={Flags[country.flag]} alt={country.id} width="20px" /><span class="pl-4">{country.id}</span></Option>
            {/each}
        {/key}
    {/if}
</Select>
