<script>
    import Dialog, { Title as DTitle, Content } from "@smui/dialog";
    import LinearProgress from "@smui/linear-progress";
    import Button, { Label } from "@smui/button";
    import Loader from "../../elementary/Loader.svelte";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    export let filesUploadDialogOpen;
    export let filesUploadState;
    export let uploadFinishedCount;
    export let filesUploadCount;
    export let filesStatus;
    export let validationFinishedCount;
    export let items;
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();
</script>

<Dialog bind:open={filesUploadDialogOpen} sheet scrimClickAction="" escapeKeyAction="" aria-describedby="processing" surface$class="mnh-50pct mnw-600 mxh-90dvh">
    <DTitle id="file-process-title" class="tescoblue white-text">{$_("upload_excel_files")}</DTitle>
    <Content id="file-process-sheet-content" class="w-100 bs-bb pl-4 pr-4 ma-auto {filesUploadState == 'cancel-request' ? '' : 'flex-1'}">
        {#if filesUploadState == "cancel-request"}
            <Loader size="big" text={$_("discarting")} />
        {:else}
            <section style="display: flex; flex-direction: column; align-items: start">
                <div class="pt-4 w-100 bs-bb">
                    <div>{$_("upload")} {uploadFinishedCount}/{filesUploadCount}</div>
                    {#if uploadFinishedCount < filesUploadCount}
                        <LinearProgress indeterminate />
                    {/if}
                    <div style="max-height: 33dvh; overflow: auto; mergin-top: .5rem; margin-inline: .5rem;">
                        {#each filesStatus as row}
                            <div class="fs-08rem tescoblue-text"><span class="text-bold green-text">{$_(row.uploadStatus)}</span> - {row.fileName}</div>
                        {/each}
                    </div>
                </div>
                <div class="pt-4 w-100 bs-bb">
                    <div>{$_("validation")} {validationFinishedCount}/{filesUploadCount}</div>
                    {#if validationFinishedCount < filesUploadCount}
                        <LinearProgress indeterminate />
                    {/if}
                    <div style="max-height: 33dvh; overflow: auto; margin-top: .5rem; margin-inline: .5rem;">
                        {#each filesStatus as row}
                            <div class="fs-08rem tescoblue-text">
                                <span class="text-bold {['error', 'rejected'].includes(row.validationStatus) ? 'red-text' : 'green-text'}">{$_(row.validationStatus)}</span> - {row.fileName}
                                {#if row.errorMessage}
                                    - <span class="red-text yellow lighten-4">{$_(row.errorMessage)}</span>
                                {/if}
                            </div>
                        {/each}
                    </div>
                </div>
            </section>
        {/if}
    </Content>
    <Flex direction="row" aling="right" class="pr-4 ma-4 gap-1">
        {#if filesUploadState != "cancel-request"}
            <Button
                class="tescored-text white-tescored-outlined"
                data-qa="upload-cancel-button"
                on:click={() => {
                    dispatch("clearAllData");
                    if (filesUploadState == "started") {
                        filesUploadState = "cancel-request";
                    } else {
                        filesUploadDialogOpen = false;
                    }
                }}
            >
                <Label>{filesUploadState == "finished" ? $_("discard_all") : $_("cancel")}</Label>
            </Button>
        {/if}
        {#if items.length > 0}
            <Button
                class="{validationFinishedCount < filesUploadCount ? 'grey' : 'tescoblue'} white-text"
                data-qa="upload-continue-button"
                on:click={() => {
                    filesUploadDialogOpen = false;
                }}
                disabled={validationFinishedCount < filesUploadCount}
            >
                <Label>{$_("continue")}</Label>
            </Button>
        {/if}
    </Flex>
</Dialog>
